import { Button, Col, Form, Row, Spin } from "antd";
import styles from "./styles.module.scss";
import EnglishInput from "features/common/components/Forms/formItems/stringInputs/EnglishInput";
import EmailInput from "features/common/components/Forms/formItems/stringInputs/EmailInput";
import PasswordInput from "features/common/components/Forms/formItems/stringInputs/PasswordInput";
import { useAddConsoleUserApi } from "features/users/apis/useAddConsoleUserApi";
import { useFetchConsolePermissionsApi } from "features/users/apis/useFetchConsolePermissionsApi";
import PermissionSection from "../Permissions/PermissionsSection";
import { useEffect, useState } from "react";
import { UsersSliceActions } from "features/users/redux/slices/usersSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "features/common/hooks/useReduxHooks";
import { useShowConsoleUserApi } from "features/users/apis/useShowConsoleUserApi";
import { useParams } from "react-router-dom";
import { useUpdateConsoleUserApi } from "features/users/apis/useUpdateConsoleUserApi";

const EditConsoleUserSection = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { permissions } = useAppSelector((state) => state.users.edit.console);
  const [permissionsData, setPermissionsData] = useState<any>([]);
  const { isLoading, mutateAsync } = useUpdateConsoleUserApi(id ? +id : 0);
  const {
    data,
    isLoading: Loading,
    isRefetching,
  } = useShowConsoleUserApi(id ? +id : 0);
  const onFinish = (values: any) => {
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("email", values.email);
    if (values.newPassword) {
      formData.append("password", values.newPassword);
    }
    permissions.forEach((permission, index) => {
      formData.append(`permissions[${index}]`, `${permission}`);
    });
    mutateAsync(formData);
  };
  useEffect(() => {
    if (data) {
      form.resetFields();
      const temp: string[] = [];
      setPermissionsData(
        data?.sub_features?.map((feat) => {
          feat?.permissions?.forEach((permission) => {
            if (permission.is_checked) {
              temp.push(permission?.id);
            }
          });
          return {
            feat: feat?.key,
            permissions: feat?.permissions,
          };
        })
      );
      dispatch(
        UsersSliceActions.SetPermissionsAction(["edit", "console", temp])
      );
    }
  }, [data]);
  return (
    <>
      <Spin
        spinning={isLoading || Loading || isRefetching}
        wrapperClassName={styles.formContainer}
      >
        <Col span={24}>
          <Form
            form={form}
            onFinish={onFinish}
            labelCol={{ span: 24 }}
            className={styles.form}
            initialValues={{
              name: data?.name,
              email: data?.email,
            }}
          >
            <Row gutter={32}>
              <Col className={styles.col} xs={8}>
                <EnglishInput
                  name={"name"}
                  label={<div className="label">User Name</div>}
                />
              </Col>
              <Col className={styles.col} xs={8}>
                <EmailInput
                  name={"email"}
                  label={<div className="label">Email</div>}
                />
              </Col>
              <Col className={styles.col} xs={8}>
                <PasswordInput
                  name={"newPassword"}
                  label={<div className="label">New Password</div>}
                  required={false}
                  rules={undefined}
                />
              </Col>
              <Col className={styles.col} span={24}>
                <PermissionSection
                  permissionsData={permissionsData}
                  selectedPermissions={permissions}
                  onChange={(value) => {
                    dispatch(
                      UsersSliceActions.SetPermissionsAction([
                        "edit",
                        "console",
                        value,
                      ])
                    );
                  }}
                />
              </Col>
            </Row>
          </Form>
        </Col>
        <Col
          span={4}
          className={styles.col}
          style={{ marginInlineStart: "auto" }}
        >
          <Button
            size="large"
            type="primary"
            shape="round"
            block
            onClick={() => form.submit()}
            loading={isLoading}
          >
            Save
          </Button>
        </Col>
      </Spin>
    </>
  );
};

export default EditConsoleUserSection;
