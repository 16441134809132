import { useAppSelector } from "features/common/hooks/useReduxHooks";
import { ShopResponse } from "features/common/interfaces/apis/responses/shops/ShopResponse.d";
import { ConsoleUserResponse } from "features/common/interfaces/apis/responses/users/UserResponse.d";
import { request } from "libs/axios";
import { useQuery } from "react-query";

export const useShowConsoleUserApi = (id: number | null) => {
  const { currAppToken } = useAppSelector((state) => state.appsAuth);

  return useQuery(
    [`ShowConsoleUser`, id],
    () => {
      return request<ConsoleUserResponse>({
        url: `/auth/console-users/${id}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${currAppToken}`,
        },
      });
    },
    {
      select: (data) => {
        return data.data.data;
      },
      refetchOnWindowFocus: false,
    }
  );
};
