import { useAppSelector } from "features/common/hooks/useReduxHooks";
import { ShopResponse } from "features/common/interfaces/apis/responses/shops/ShopResponse.d";
import { request } from "libs/axios";
import { useQuery } from "react-query";

export const useShowShopApi = (id: number | null) => {
  const { currAppToken } = useAppSelector((state) => state.appsAuth);

  return useQuery(
    [`ShowShop`, id],
    () => {
      return request<ShopResponse>({
        url: `/shops/${id}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${currAppToken}`,
        },
      });
    },
    {
      select: (data) => {
        return data.data?.data;
      },
      refetchOnWindowFocus: false,
    }
  );
};
