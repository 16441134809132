import { useAppSelector } from "features/common/hooks/useReduxHooks";
import { Navigate, Outlet, useLocation } from "react-router-dom";

/**
 * @description a middleware for check if the user have auth token
 */

export const UserAuthenticatedRoutes = () => {
  const { authToken } = useAppSelector((state) => state.userAuth);
  const location = useLocation();
  return authToken ? (
    <Outlet />
  ) : (
    <Navigate to="/auth/login" state={{ from: location }} replace />
  );
};
