import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import HeaderLoading from "features/common/components/Loading/HeaderLoading/Loading";
import { CustomTableProps } from "features/common/interfaces/CustomTablePropsInterface.d";

/**
 * @description an ant design table with custom props and pagenation logic and many other props
 *  that make the table full customizable and have a header loading that fires on refetching the api
 * @param {any[]} data -the data source
 * @param {ColumnsType<any>} columns - the table columns
 * @param {boolean} isLoading
 * @param {boolean} isRefetching
 * @param {number} page
 * @param {number} perPage
 * @param {any} meta -the api mate (other info)
 * @param {Function} onRow -an event that fires when click on row
 * @param {string} name -the records name default 'Rows'
 * @param {number[]} pageSizeOptions - default [10, 25, 50, 100]
 */
const CustomTable = ({
  data,
  columns,
  isLoading,
  isRefetching,
  SetPage,
  SetPerPage,
  meta,
  page,
  perPage,
  onRow,
  name = "Rows",
  pageSizeOptions = [10, 25, 50, 100],
  ...props
}: CustomTableProps) => {
  return (
    <div className="antTableHeaderStyles">
      <HeaderLoading top={-40} loading={isRefetching} />
      <Table
        scroll={{ y: 490, x: 1100 }}
        size="middle"
        loading={isLoading}
        style={{ marginTop: 10 }}
        columns={columns}
        dataSource={data}
        pagination={{
          style: { marginTop: 30, marginBottom: 0 },
          position: ["bottomCenter"],
          size: "small",
          current: page,
          pageSize: perPage,
          total: meta?.total,
          showSizeChanger: true,
          onChange: (page, pageSize) => {
            SetPage(page);
            SetPerPage(pageSize);
          },
          showTotal: (number: any) => {
            return (
              <>
                Total {name} : {number}
              </>
            );
          },
          pageSizeOptions: pageSizeOptions,
        }}
        onRow={onRow}
        {...props}
      />
    </div>
  );
};

export default CustomTable;
