import decryptData from "features/common/helpers/decryptData";
import encryptData from "features/common/helpers/encryptData";
import isRetryOnStatus from "features/common/helpers/isRetryOnStatus";
import { useErrorHandler } from "features/common/hooks/useErrorHandler";
import { QueryClient, QueryClientProvider } from "react-query";
import { createWebStoragePersistor } from "react-query/createWebStoragePersistor-experimental";
import { persistQueryClient } from "react-query/persistQueryClient-experimental";
import { ReactQueryDevtools } from "react-query/devtools";
import { ReactNode } from "react";
import { useAppDispatch } from "features/common/hooks/useReduxHooks";
import { UiSliceActions } from "features/common/redux/slices/uiSlice";
import { ERR } from "data/Errors";

/**
 * @namespace ReactQueryProvider
 */
/**
 * @description have a default options and defalut onError handler
 * encrypt the query data and store it in the local storge for better preformance
 * @param {ReactNode} children - wrapped components
 */

interface ReactQueryProviderProps {
  children: ReactNode;
}
const ReactQueryProvider = ({ children }: ReactQueryProviderProps) => {
  const dispatch = useAppDispatch();
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        /**
         * 24 hours cache time
         */
        cacheTime: 1000 * 60 * 60 * 24,
        retry(failureCount, error: any) {
          const RetryCount = 3;
          if (
            failureCount < RetryCount &&
            isRetryOnStatus(error.response?.status)
          )
            return true;
          else return false;
        },
        onError(err: any) {
          dispatch(
            UiSliceActions.SetError({
              message: err.response?.data?.message ?? err.message,
              code: err.response?.status ?? err.code,
              navigate:
                err.response?.config?.method === "get" &&
                err.response?.status !== ERR.UNAUTHENTICATED,
            })
          );
        },
      },
      mutations: {
        onMutate() {
          dispatch(UiSliceActions.SetError(null));
        },
        onError(err: any, variables, context) {
          dispatch(
            UiSliceActions.SetError({
              message: err.response?.data?.message ?? err.message,
              code: err.response?.status ?? err.code,
              navigate: err.response?.config?.method === "get",
            })
          );
        },
        retry: 0,
      },
    },
  });

  const sessionStoragePersistor = createWebStoragePersistor({
    storage: window.sessionStorage,
    deserialize: decryptData,
    serialize: encryptData,
  });

  persistQueryClient({
    queryClient,
    persistor: sessionStoragePersistor,
  });

  return (
    <>
      <QueryClientProvider client={queryClient}>
        {children}
        <ReactQueryDevtools initialIsOpen={false} position={"bottom-right"} />
      </QueryClientProvider>
    </>
  );
};

export default ReactQueryProvider;
