import { useAppSelector } from "features/common/hooks/useReduxHooks";
import FourOThreePage from "features/common/errors/403/403";
import { Outlet } from "react-router-dom";
import { featuresTypes } from "features/auth/types/featureTypes.d";
import { getFeaturesKeys } from "features/auth/helpers/getFeaturesKeys";
import { permissionsTypes } from "features/common/interfaces/FeaturePermission.d";
import { getPermissionsKeys } from "features/auth/helpers/getPermissionsKeys";

interface AppAuthorizedRoutesProps {
  features: featuresTypes[];
  permissions?: permissionsTypes[];
  method?: "OR" | "AND";
}
/**
 * @description a middleware for check if the current user have access to the wrapped feature route or not
 * @param {featuresTypes[]} features - features name that we want to check
 * @param {"OR" | "AND"} method - the method we want to check
 */
export const AppAuthorizedRoutes = ({
  features,
  permissions,
  method = "AND",
}: AppAuthorizedRoutesProps) => {
  const { currAppFeatures } = useAppSelector((state) => state.appsAuth);
  const featuresKeys = getFeaturesKeys(currAppFeatures);
  let permissionsKeys: string[] = [];
  features.forEach((feature) => {
    permissionsKeys = permissionsKeys
      .concat(getPermissionsKeys(currAppFeatures, feature))
      .filter((key) => key !== undefined);
  });
  console.log(permissionsKeys)
  const isAuthorized: boolean =
    method === "AND"
      ? features.every((feature) => featuresKeys.includes(feature)) &&
        (permissions
          ? permissions.every((permission) =>
              permissionsKeys.includes(permission)
            )
          : true)
      : method === "OR" &&
        features.some((feature) => featuresKeys.includes(feature)) &&
        (permissions
          ? permissions.some((permission) =>
              permissionsKeys.includes(permission)
            )
          : true);
  return isAuthorized ? <Outlet /> : <FourOThreePage />;
};
