import { Button, Image } from "antd";
import { ButtonProps } from "antd/lib/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import styles from "./styles.module.scss";
interface AppButtonProps extends ButtonProps {
  id: string;
  name: string;
  icon: string;
  primaryColor: string;
}
/**
 * A custom styled button used to use the primary color for each app and its icon 
 * @param {AppButtonProps} AppButtonProps 
 */
function AppButton({ icon, id, name, primaryColor, ...props }: AppButtonProps) {
  return (
    <Button
      className={styles.appButton}
      key={id}
      style={{
        background: primaryColor,
      }}
      icon={
        <Image
          rootClassName={styles.icon}
          src={icon}
          preview={false}
          fallback="ic"
          placeholder={<FontAwesomeIcon icon={faCircleExclamation} />}
        />
      }
      {...props}
    >
      <span className={styles.appName}>{name}</span>
    </Button>
  );
}

export default AppButton;
