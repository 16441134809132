import { Form, FormItemProps, Select, Spin } from "antd";
import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { useQuery, UseQueryResult } from "react-query";
import { useSearchDelay } from "features/common/hooks/useSearchDelay";
import { request } from "libs/axios";

interface SingleApiSelectInputProps extends FormItemProps {
  name: string | any[];
  url: string;
  initialValue?: { name: string; id: string | number };
  labelInValue?: boolean;
  placeholder?: string;
  // props : {...props}
  disabled?: boolean;
  optionalKey?: any;
  nestedObject?: any;
  onSelect?: any;
}
/**
 * @description a form item with a single select input that takes an api url and make a request on that url and store the
 * incoming data in the select with a online serach feature (not locally)
 * @param {any} name - form item name
 * @param {string} url  - the url to reqeust from
 * @param {any} initialValue - if have initial value
 * @param {boolean} labelInValue - option for having the label in the result value from the select usually used for the show purpses
 * @param {boolean} disabeld - disable the form item
 * @param {any} optionalKey - if we want to add a key to the react query key
 * @param {FormItemProps} restProps  - the rest form item interface props
 */
const SingleApiSelectInput = ({
  name,
  url,
  initialValue,
  // for showing the init value in the select input
  labelInValue = false,
  placeholder,
  disabled = false,
  optionalKey = null,
  onSelect,
  nestedObject = null,
  ...props
}: SingleApiSelectInputProps) => {
  const [searchContent, setSearchTerm] = useSearchDelay();
  const [data, setData] = useState<any[] | undefined>(undefined);

  const items: UseQueryResult<AxiosResponse, Error> = useQuery(
    [`get${name}`, searchContent, optionalKey],
    () => {
      return request({
        url,
        method: "GET",

        params: {
          items_per_page: 1000,
          filter: { search: searchContent },
        },
      });
    }
  );
  useEffect(() => {
    items.refetch();
  }, [searchContent]);
  useEffect(() => {
    setData(
      items?.data?.data?.data.map((item: any) => {
        if (nestedObject) {
          return {
            label: item[nestedObject].name,
            value: item[nestedObject].id
              ? item[nestedObject].id
              : item[nestedObject].name,
          };
        }
        return { label: item.name, value: item.id ? item.id : item.name };
      })
    );
  }, [items?.isFetchedAfterMount, items?.isRefetching]);

  return (
    <>
      <Form.Item
        initialValue={
          initialValue
            ? {
                value: initialValue?.id,
                label: initialValue?.name
                  ? initialValue?.name
                  : initialValue?.id,
              }
            : undefined
        }
        name={name}
        {...props}
      >
        <Select
          filterOption={false}
          labelInValue={labelInValue}
          loading={items.isRefetching || items.isLoading}
          showSearch
          allowClear
          placeholder={placeholder}
          onSelect={onSelect}
          onSearch={(value) => {
            setSearchTerm(value);
          }}
          disabled={disabled}
          defaultValue={initialValue?.name}
          style={{ width: "100%" }}
          notFoundContent={
            items.isFetching ? (
              <Spin
                style={{ display: "flex", justifyContent: "center" }}
                size="small"
              />
            ) : null
          }
          options={data}
        />
      </Form.Item>
    </>
  );
};

export default SingleApiSelectInput;
