import { message } from "antd";
import { useAppSelector } from "features/common/hooks/useReduxHooks";
import { request } from "libs/axios";
import { useMutation } from "react-query";
import { gamesTypes } from "../types/gamesTypes";

export interface mutateFunctionInterface {
  data: FormData;
  token: string | null;
  resource: gamesTypes;
}

const mutateFunction = async ({
  data,
  token,
  resource,
}: mutateFunctionInterface) => {
  return request({
    url:
      resource === "scratch_ticket"
        ? "/scratch-tickets"
        : resource === "scratch_grid"
        ? "/scratch-grids"
        : resource === "number_guess"
        ? "/number-guesses"
        : "",
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const useAddGameApi = (resource: gamesTypes) => {
  const { currAppToken } = useAppSelector((state) => state.appsAuth);

  return useMutation(
    "scratch-ticket/add",
    (data: FormData) => {
      return mutateFunction({ data, resource, token: currAppToken });
    },
    {
      onSuccess: async (response) => {
        message.success(response.data.message);
      },
    }
  );
};
