import { useAppSelector } from "features/common/hooks/useReduxHooks";
import { request } from "libs/axios";
import { useQuery } from "react-query";
import { gamesTypes } from "../types/gamesTypes";
import {
  StatisticsGameOthers,
  AttemptResponse,
  VisitorsResponse,
} from "features/common/interfaces/apis/responses/games/StatisticsResponse.d";

export const useVisitorsApi = (
  id: number | null,
  resource: gamesTypes,
  table: "attempts" | "visitors"
) => {
  const { currAppToken } = useAppSelector((state) => state.appsAuth);
  const {
    pagnation: { page, perPage },
    search,
  } = useAppSelector((state) => state.games[resource].statisticsPage.visitors);

  return useQuery(
    [`Visitors-${resource}`, id, search],
    () => {
      return request<VisitorsResponse[], StatisticsGameOthers>({
        url:
          resource === "scratch_ticket"
            ? `/scratch-tickets/${id}/statistics`
            : resource === "scratch_grid"
            ? `/scratch-grids/${id}/statistics`
            : resource === "number_guess"
            ? `/number-guesses/${id}/statistics`
            : "",
        method: "GET",
        headers: {
          Authorization: `Bearer ${currAppToken}`,
        },
        params: {
          filter: {
            search: search,
          },
          items_per_page: perPage,
          page: page,
        },
      });
    },
    {
      select: (data) => {
        return data.data;
      },
      enabled: table === "visitors",
    }
  );
};
