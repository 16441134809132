import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { baseURL } from "../config/baseURL";
import store from "store/store";
import { ApiResponse } from "features/common/interfaces/apis/ApiInterface.d";

const interceptor = axios.create({ baseURL });

/**
 * @description the interceptor for the axios lib
 * with this way of integration we can change the axios lib to any another lib any time we wnat
 */
export const request = async <R = any, O = any, D = ApiResponse<R, O>>({
  ...options
}: AxiosRequestConfig): Promise<AxiosResponse<D>> => {
  interceptor.defaults.headers.common.Authorization = `Bearer ${
    store.getState().userAuth.authToken
  }`; //from local storage or cookies
  const onSuccess = (response: AxiosResponse<D>) => response;
  const onError = (error: unknown) => {
    // optionaly catch errors and add additional logging here
    throw error;
  };

  try {
    const response = await interceptor(options);
    return onSuccess(response);
  } catch (error) {
    return onError(error);
  }
};
