import { getFeaturesKeys } from "features/auth/helpers/getFeaturesKeys";
import { getPermissionsKeys } from "features/auth/helpers/getPermissionsKeys";
import { useAppSelector } from "features/common/hooks/useReduxHooks";
import { permissionsTypes } from "features/common/interfaces/FeaturePermission.d";
import React from "react";

interface ComponentGuardProps {
  permission: permissionsTypes;
  children?: React.ReactNode;
}
export const usePermissionGuard = () => {
  const { currAppFeatures } = useAppSelector((state) => state.appsAuth);
  const permissionGuard = (permission: permissionsTypes) => {
    const featuresKeys = getFeaturesKeys(currAppFeatures);
    let permissionsKeys: string[] = [];
    featuresKeys.forEach((feature) => {
      permissionsKeys = permissionsKeys.concat(
        getPermissionsKeys(currAppFeatures, feature)
      );
    });
    if (permissionsKeys.includes(permission))
      return true;
    return false;
  };
  return { permissionGuard };
};
const ComponentGuard = ({ permission, children }: ComponentGuardProps) => {
  const { permissionGuard } = usePermissionGuard();
  if (permissionGuard(permission)) return <>{children}</>;
  return <></>;
};

export default ComponentGuard;
