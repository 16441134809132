import { useForm } from "antd/es/form/Form";
import GiftsSwiper from "features/games/components/GiftsSwiper/GiftsSwiper";
import { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import {
  Button,
  Col,
  Divider,
  Form,
  Row,
  Space,
  Spin,
  Switch,
  Input,
  InputNumber,
} from "antd";
import EnglishInput from "features/common/components/Forms/formItems/stringInputs/EnglishInput";
import ArabicInput from "features/common/components/Forms/formItems/stringInputs/ArabicInput";
import SingleApiSelectInput from "features/common/components/Forms/formItems/selectInputs/SingleApiSelectInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLeftLong } from "@fortawesome/free-solid-svg-icons";
import ImagesUploder from "features/common/components/Upload/UploadWithSession/ImagesUploader/ImagesUplader";
import {
  useAppDispatch,
  useAppSelector,
} from "features/common/hooks/useReduxHooks";
import { GamesSliceActions } from "features/games/redux/sllice/gamesSlice";
import CustomDateRangeInput from "features/common/components/Forms/formItems/Date/DatePicker";
import { changeDateToString } from "features/common/helpers/changeDateToString";
import { UploadFileStatus } from "antd/es/upload/interface";
import { useNavigate, useParams } from "react-router-dom";
import { gamesTypes } from "features/games/types/gamesTypes";
import { useShowGameApi } from "features/games/apis/useShowGameApi";
import dayjs from "dayjs";
import { useUpdateGameApi } from "features/games/apis/useUpdateGameApi";
import { I_Gift } from "features/games/interfaces/I.Gift";
import FilesUploder from "features/common/components/Upload/UploadWithSession/FileUploader/FileUplader";
import { NumberGuessFormInterface } from "features/games/interfaces/forms/GamesForm.d";

function NumberGuessEditPage() {
  const resource: gamesTypes = "number_guess";
  const { id } = useParams();
  const [isUploading, setIsUploading] = useState<UploadFileStatus>();
  const [backgroundImageFlag, setBackgroundImageFlag] = useState(false);
  const [backgroundImageARFlag, setBackgroundImageARFlag] = useState(false);
  const [form] = useForm();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { sessionID, addedGifts } = useAppSelector(
    (state) => state.games[resource].editPage
  );
  const { primaryColor } = useAppSelector((state) => state.ui);

  const { data, isLoading } = useShowGameApi(id ? +id : 0, resource);
  const { mutateAsync, isLoading: mutateLoading } = useUpdateGameApi(
    id ? +id : 0,
    resource
  );

  useEffect(() => {
    if (data) {
      form.resetFields();
      const gifts: I_Gift[] = [];
      data?.gifts?.forEach((gift) => {
        gifts.push({
          id: gift?.id,
          chance: gift.win_chance,
          img: gift.images.map((img, index) => ({
            ar: gift.ar.images[index] || null,
            en: gift.en.images[index] || null,
          })),
          quantity: gift.quantity,
          remains: gift.remains,
          type: gift.type,
          title_ar: gift.ar.name,
          title_en: gift.en.name,
          description_ar: gift.ar.description,
          description_en: gift.en.description,
        });
      });
      dispatch(
        GamesSliceActions.EditPageAddGiftAction({
          resource,
          value: gifts,
        })
      );
    }
  }, [data]);

  useEffect(() => {
    return () => {
      dispatch(GamesSliceActions.ResetEdit(resource));
    };
  }, []);

  const onFinish = (values: NumberGuessFormInterface) => {
    const formData = new FormData();
    if (sessionID) formData.append("session_id", sessionID);
    formData.append("title[en]", values.ENname);
    formData.append("title[ar]", values.ARname);
    formData.append("qty_switch", `${Number(values.qtySwitch)}`);
    formData.append("description[en]", values.ENdescription);
    formData.append("description[ar]", values.ARdescription);
    formData.append("secret_number", values.secret);
    if (values.winnersLink) formData.append("winners_link", values.winnersLink);
    // if (values.timeSwitch) {
    formData.append(
      "starts_at",
      dayjs(values.date[0]).format("YYYY-MM-DD HH:mm")
    );
    formData.append(
      "expires_at",
      dayjs(values.date[1]).format("YYYY-MM-DD HH:mm")
    );
    // }
    mutateAsync(formData).then(() => {
      dispatch(GamesSliceActions.ResetEdit(resource));
      navigate("/games/number-guess");
    });
  };
  return (
    <>
      <Spin spinning={isLoading}></Spin>
      <Row>
        <Col span={24}>
          <Row>
            <Col span={1} className={styles.col}>
              <Button
                onClick={() => {
                  navigate("/games/number-guess");
                }}
                type="text"
                icon={
                  <FontAwesomeIcon
                    icon={faLeftLong}
                    style={{ color: primaryColor ?? "", fontSize: 25 }}
                  />
                }
              ></Button>
            </Col>
            <Col span={19}>
              <div className={styles.title}>
                Number Guess - <span className={styles.txt}>Edit</span>
              </div>
            </Col>
            <Col span={4} className={styles.col}>
              <Button
                size="large"
                type="primary"
                shape="round"
                block
                onClick={() => form.submit()}
                disabled={isUploading === "uploading"}
                loading={mutateLoading}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <Col offset={1}></Col>
            <Col span={19} className={styles.gameTitle}>
              {data?.title}
            </Col>
          </Row>
        </Col>
        <Col span={24} className={styles.formContainer}>
          <Form
            form={form}
            onFinish={onFinish}
            labelCol={{ span: 24 }}
            className={styles.form}
            initialValues={{
              ENname: data?.en?.title,
              ARname: data?.ar?.title,
              shop: data?.shop?.id ?? "1",
              date: [
                dayjs(data?.starts_at, "DD-MM-YYYY HH:mm"),
                dayjs(data?.expires_at, "DD-MM-YYYY HH:mm"),
              ],
              // timeSwitch: data?.time_switch ?? true,
              qtySwitch: data?.qty_switch ?? true,
              secret: data?.secret_number,
              ARdescription: data?.ar?.description,
              ENdescription: data?.en?.description,
              winnersLink: data?.winners_link,
            }}
          >
            <Row gutter={32}>
              <Col className={styles.col} xs={8}>
                <EnglishInput
                  name={"ENname"}
                  label={<div className="label">En - Name</div>}
                />
              </Col>
              <Col className={styles.col} xs={8}>
                <ArabicInput
                  name={"ARname"}
                  label={<div className="label">Ar - Name</div>}
                />
              </Col>
              <Col className={styles.col} xs={8}>
                <SingleApiSelectInput
                  disabled
                  label={<div className="label">Shop</div>}
                  name={"shop"}
                  url="/shops"
                  nestedObject={"shop"}
                  rules={[
                    {
                      required: true,
                      message: "please select shop",
                    },
                  ]}
                />
              </Col>
              <Col className={styles.col} xs={12}>
                <EnglishInput
                  name={"ENdescription"}
                  label={<div className="label">En - Description</div>}
                />
              </Col>
              <Col className={styles.col} xs={12}>
                <ArabicInput
                  name={"ARdescription"}
                  label={<div className="label">Ar - Description</div>}
                />
              </Col>

              <Col className={styles.col} span={24}>
                <Row justify={"space-evenly"} align={"top"}>
                  <Col
                    span={7}
                    className={styles.imageUplodar}
                    style={{ background: primaryColor ?? "" }}
                  >
                    <div className={styles.imageTitle}>
                      English Background Image
                    </div>
                    <ImagesUploder
                      preview={!backgroundImageFlag}
                      disabled={isUploading === "uploading"}
                      height="150px"
                      maxCount={1}
                      imagesUploaded={
                        data?.en.bg_image ? [data?.en.bg_image] : []
                      }
                      seesionId={sessionID}
                      multiple
                      sessionKey="bg_image_en"
                      setIsUploading={setIsUploading}
                      setSessionId={(value) =>
                        dispatch(
                          GamesSliceActions.EditPageSetSessionIdAction({
                            resource,
                            value: value,
                          })
                        )
                      }
                      onSuccess={() => {
                        setBackgroundImageFlag(true);
                      }}
                    />
                  </Col>
                  <Col
                    span={7}
                    className={styles.imageUplodar}
                    style={{ background: primaryColor ?? "" }}
                  >
                    <div className={styles.imageTitle}>
                      Arabic Background Image
                    </div>
                    <ImagesUploder
                      preview={!backgroundImageARFlag}
                      disabled={isUploading === "uploading"}
                      height="150px"
                      maxCount={1}
                      imagesUploaded={
                        data?.ar.bg_image ? [data?.ar.bg_image] : []
                      }
                      seesionId={sessionID}
                      multiple
                      sessionKey="bg_image_ar"
                      setIsUploading={setIsUploading}
                      setSessionId={(value) =>
                        dispatch(
                          GamesSliceActions.EditPageSetSessionIdAction({
                            resource,
                            value: value,
                          })
                        )
                      }
                      onSuccess={() => {
                        setBackgroundImageARFlag(true);
                      }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col className={styles.col} xs={14}>
                <Form.Item
                  noStyle
                  // shouldUpdate={(prevValues, currValues) => {
                  //   return prevValues.timeSwitch !== currValues.timeSwitch;
                  // }}
                >
                  {/* {({ getFieldValue, setFieldValue }) => {
                      return ( */}
                  <CustomDateRangeInput
                    rules={[
                      {
                        // required: !getFieldValue("timeSwitch") === false,
                        required: true,
                        message: "please enter range date",
                      },
                    ]}
                    name={"date"}
                    // showTime={false}
                    // disabeld={getFieldValue("timeSwitch") === false}
                  />
                  {/* );
                    }} */}
                </Form.Item>
              </Col>
              {/* <Col className={styles.col} xs={5}>
                  <Space>
                    <Divider type="vertical" />
                    <div className={styles.switchtxt}>Time Expiration</div>
                    <Form.Item
                      name={"timeSwitch"}
                      style={{ marginBottom: 0 }}
                      valuePropName="checked"
                      initialValue={false}
                    >
                      <Switch />
                    </Form.Item>
                  </Space>
                </Col> */}
              <Col className={styles.col} xs={5}>
                <Space>
                  <Divider type="vertical" />
                  <div className={styles.switchtxt}>Quantity Expiration</div>
                  <Form.Item
                    name={"qtySwitch"}
                    style={{ marginBottom: 0 }}
                    valuePropName="checked"
                    initialValue={false}
                  >
                    <Switch />
                  </Form.Item>
                </Space>
              </Col>
              <Col span={24}>
                <FilesUploder
                  disabeld
                  filesUploaded={["file"]}
                  accept=".csv"
                  editable
                  maxCount={1}
                  seesionId={sessionID}
                  sessionKey={`secret_numbers`}
                  setSessionId={(value: string) =>
                    dispatch(
                      GamesSliceActions.AddPageSetSessionIdAction({
                        resource,
                        value: value,
                      })
                    )
                  }
                  url="/upload-files"
                  setIsUploading={setIsUploading}
                  label={"Secret Numbers"}
                />
              </Col>
              <Col className={styles.col} xs={24}>
                <Form.Item
                  name={"winnersLink"}
                  label={<div className="label">Winners Link</div>}
                  // required
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "please enter winners facebook link",
                  //   },
                  // ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24}>
                <GiftsSwiper
                  gifts={addedGifts}
                  addability={false}
                  areEditable={false}
                  sessionId={sessionID}
                  setSessionId={(id: string) => {
                    dispatch(
                      GamesSliceActions.EditPageSetSessionIdAction({
                        resource: "scratch_ticket",
                        value: id,
                      })
                    );
                  }}
                  // AddGiftFunction={(gift: I_Gift) => {
                  //   dispatch(
                  //     GamesSliceActions.EditPageAddGiftAction({
                  //       resource: "scratch_ticket",
                  //       value: gift,
                  //     })
                  //   );
                  // }}
                  // EditGiftFunction={(gift: I_Gift) => {
                  //   dispatch(
                  //     GamesSliceActions.EditGiftAction({
                  //       resource: "scratch_ticket",
                  //       value: gift,
                  //     })
                  //   );
                  // }}
                  // DeleteGiftFunction={(id: string) => {
                  //   dispatch(
                  //     GamesSliceActions.DeleteGiftAction({
                  //       resource: "scratch_ticket",
                  //       value: id,
                  //     })
                  //   );
                  // }}
                />
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  );
}

export default NumberGuessEditPage;
