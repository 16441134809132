import { Form, FormItemProps, Select } from "antd";

interface SingleLocalSelectInputProps extends FormItemProps {
  name: string | any[];
  data: any[];
  initialValue?: string;
  placeholder?: string;
  labelInValue?: boolean;
  disabled?: boolean;
  loading?: boolean;
}
/**
 * @description a form item with a single select input that takes the data source from params
 * @param {any} name - form item name
 * @param {any[]} data  - the data source
 * @param {string} initialValue  - the data source
 * @param {boolean} labelInValue
 * @param {boolean} disabled
 * @param {boolean} loading
 * @param {FormItemProps} restProps  - the rest form item interface props
 */
const SingleLocalSelectInput = ({
  name,
  data,
  initialValue,
  required,
  placeholder,
  // for showing the init value in the select input
  labelInValue = false,
  disabled = false,
  loading = false,
  ...props
}: SingleLocalSelectInputProps) => {
  return (
    <>
      <Form.Item initialValue={initialValue} name={name} {...props}>
        <Select
          loading={loading}
          disabled={disabled}
          showSearch
          labelInValue={labelInValue}
          defaultValue={initialValue}
          placeholder={placeholder}
          style={{ width: "100%" }}
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label?.toLowerCase() ?? "").includes(input?.toLowerCase())
          }
          options={data?.map((item: any) => {
            if (item.value !== undefined) {
              return { label: item.name, value: item.value };
            } else {
              return { label: item, value: item };
            }
          })}
        />
      </Form.Item>
    </>
  );
};

export default SingleLocalSelectInput;
