import { HashRouter } from "react-router-dom";
import Main from "Main";
import "styles/global/App.scss";


function App() {
  return (
    <HashRouter>
      <Main />
    </HashRouter>
  );
}

export default App;
