import { PayloadAction } from "@reduxjs/toolkit";
import { WritableDraft } from "immer/dist/internal";

/**
 * a global redux dispatch actions that will used in many places
 * @module globalActions
 */

/**
 */
export const pageAction = (
  state: WritableDraft<any>,
  action: PayloadAction<number>
) => {
  state.pagnation.page = action.payload;
};
/**
 */
export const perPageAction = (
  state: WritableDraft<any>,
  action: PayloadAction<number>
) => {
  state.pagnation.perPage = action.payload;
};
/**
 */
export const filtersAction = (
  state: WritableDraft<any>,
  action: PayloadAction<any | null>
) => {
  state.filters = action.payload;
};
/**
 */
export const sorterAction = (
  state: WritableDraft<any>,
  action: PayloadAction<any>
) => {
  state.sorter = action.payload;
};
/**
 */
export const searchAction = (
  state: WritableDraft<any>,
  action: PayloadAction<string>
) => {
  state.search = action.payload;
};
