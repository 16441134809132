import { Form, FormItemProps, Input } from "antd";
import { NamePath } from "antd/es/form/interface";

interface EnglishInputProps extends FormItemProps {
  name: NamePath;
  initialValue?: string;
  placeholder?: string;
  disabled?: boolean;
}
/**
 * @description a form item with text input that have english langauge rgx for warning user if enter another langauge
 * @param {string}  name
 * @param {string}  initialValue
 * @param {string}  placeholder
 * @param {boolean}  disabled
 * @param {FormItemProps} restProps  - the rest form item interface props
 * 
 */
const EnglishInput = ({
  name,
  initialValue,
  placeholder,
  disabled = false,
  ...restProps
}: EnglishInputProps) => {
  return (
    <>
      <Form.Item
        initialValue={initialValue}
        name={name}
        rules={[
          {
            required: true,
            message: "Enter english name please",
            type: "string",
          },
          {
            pattern: /^[a-zA-Z0-9`~!@#$%^&*()\-=_+[\]{}|;:'",.<>/?\\ ]*$/g,
            message: "Enter english name please",
            warningOnly: true,
          },
          { whitespace: true, message: "No whitespaces in the name" },
          { min: 1, max: 100, message: "Name must be between 1 & 100" },
        ]}
        {...restProps}
      >
        <Input
          // style={{ margin: 5 }}
          placeholder={placeholder}
          disabled={disabled}
        />
      </Form.Item>
    </>
  );
};

export default EnglishInput;
