import { Button, Modal } from "antd";
import MapWrapper from "features/common/components/MapWrapper/MapWrapper";
import { useState } from "react";

interface LocationModalProps {
  lat: number;
  lon: number;
}
function LocationModal({ lat, lon }: LocationModalProps) {
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => {
    setIsOpen(false);
  };
  const handleOpen = () => {
    setIsOpen(true);
  };
  return (
    <>
      <Button onClick={handleOpen} type="link">
        click Here to show location
      </Button>
      <Modal
        key={"MapModal"}
        open={isOpen}
        closeIcon={<span style={{ display: "none" }} />}
        cancelButtonProps={{ style: { display: "none" } }}
        onCancel={handleClose}
        onOk={handleClose}
      >
        <MapWrapper initialMarker={{ lat: lat, lng: lon }} />
      </Modal>
    </>
  );
}

export default LocationModal;
