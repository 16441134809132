import { Button, message, Upload } from "antd";
import ImgCrop from "antd-img-crop";
import { RcFile, UploadFile, UploadProps } from "antd/lib/upload/interface";
import { useEffect, useState } from "react";
import styles from "./styles.module.scss";
// import { ReactComponent as ImageLogo } from "assets/svg/generalSvgs/image_icon.svg";
import { baseURL } from "config/baseURL";
import { useTranslation } from "react-i18next";
import { ImagesUploderProps } from "features/common/interfaces/ImagesUploaderPropsInterface.d";

/**
 * @description a component that used to upload images to server session
 * using the ant design Upload.Dragger and image crop components and
 * session and setSession state to keep the session id that we uploaded on
 * @param {ImagesUploderProps} params -
 */
const TranslateImageUploader = ({
  maxCount,
  seesionId,
  sessionKey,
  setSessionId,
  imagesUploaded,
  setImagesUploaded,
  setIsUploading,
  disabled = false,
  onSuccess = () => {},
  preview = true,
  title,
}: ImagesUploderProps) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const { t } = useTranslation();
  useEffect(() => {
    console.log(imagesUploaded);
    if (imagesUploaded && imagesUploaded.length > 0 && fileList.length === 0) {
      const newArr: UploadFile[] = [];
      imagesUploaded.forEach((img, index) => {
        if (img.length > 0) {
          newArr.push({
            name: `image${index}`,
            uid: `${index}`,
            thumbUrl: img,
          });
        }
      });
      setFileList([...newArr]);
    }
  }, [imagesUploaded]);
  const authHeader = {
    Authorization: `Bearer ${"token"}`,
  };

  const beforeUpload = (file: File) => {
    console.log(file.type);
    const isValidIImage =
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/jpeg" ||
      file.type === "image/jfif" ||
      file.type === "image/svg+xml" ||
      file.type === "image/webp";
    if (!isValidIImage) {
      message.error(`${file.name} is not a valid image file`);
    }
    return isValidIImage || Upload.LIST_IGNORE;
  };
  const onChange: UploadProps["onChange"] = async (info) => {
    const { status } = info.file;
    setIsUploading?.(status);
    setFileList(info.fileList);
    if (status === "done") {
      // console.log(info.fileList);
      message.success(`${info.file.response.message}`);
      onSuccess?.(info);
      let src = info.file.url as string;
      if (!src) {
        src = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(info.file.originFileObj as RcFile);
          reader.onload = () => resolve(reader.result as string);
        });
      }
      setImagesUploaded?.({ src, key: info.file.uid });
      setSessionId(info.file.response.other.session_id);
    } else if (status === "error") {
      setFileList([]);
      console.log(`${info.file.name} file upload failed.`);
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };
  return (
    <div>
      <div>
        <div className={styles.title}>{title}</div>
        <ImgCrop rotationSlider showReset fillColor="transparent">
          <Upload
            accept="image/*"
            disabled={disabled}
            name="image"
            maxCount={1}
            action={`${baseURL}/upload-images`}
            data={(file) => ({
              session_id: seesionId,
              key: file.uid,
            })}
            headers={authHeader}
            listType="picture-card"
            fileList={fileList}
            showUploadList={{
              removeIcon: false,
              showRemoveIcon: false,
            }}
            onChange={onChange}
            onRemove={(info) => {
              console.log(info);
            }}
            onPreview={onPreview}
            beforeUpload={beforeUpload}
            className={styles.uploadContanier}
          >
            {fileList.length < 1 && (
              <div className={styles.upload}>
                <div className={styles.uploadText}>Click</div>
                <div className={styles.uploadText}>or</div>
                <div className={styles.uploadText}>Drag and drop an image</div>
              </div>
            )}
          </Upload>
        </ImgCrop>
      </div>
    </div>
  );
};

export default TranslateImageUploader;
