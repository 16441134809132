import { message } from "antd";
import { useAppSelector } from "features/common/hooks/useReduxHooks";
import { request } from "libs/axios";
import { useMutation } from "react-query";

export interface mutateFunctionInterface {
  data: FormData;
  token: string | null;
}

const mutateFunction = async ({ data, token }: mutateFunctionInterface) => {
  return request({
    url: "/shops",
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const useAddShopApi = () => {
  const { currAppToken } = useAppSelector((state) => state.appsAuth);

  return useMutation(
    "shops/add",
    (data: FormData) => {
      return mutateFunction({ data, token: currAppToken });
    },
    {
      onSuccess: async (response) => {
        message.success(response.data.message);
      },
    }
  );
};
