import { Dispatch, SetStateAction, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import TranslateImageUploader from "features/common/components/Upload/UploadWithSession/TranslateImageUploader/TranslateImageUploader";
import { Button } from "antd";
import { UploadFileStatus } from "antd/es/upload/interface";
import { CloseOutlined } from "@ant-design/icons";
interface ImageGiftListUploderProps {
  seesionId: string | null;
  setImagesUploaded: any;
  setImageSessionKey: Dispatch<
    SetStateAction<
      {
        ar: string | null;
        en: string | null;
      }[]
    >
  >;
  imagesUploaded?: Array<{ ar: string | null; en: string | null }>;
  imageSessionKey?: Array<{ ar: string | null; en: string | null }>;
  setSessionId: any;
  setIsUploading: any;
  isUploading?: UploadFileStatus;
  onSuccess?: any;
  giftsLength?: number;
}
const ImageGiftListUploder = ({
  onSuccess,
  seesionId,
  setImagesUploaded,
  setIsUploading,
  setSessionId,
  isUploading,
  imagesUploaded,
  giftsLength,
  setImageSessionKey,
  imageSessionKey,
}: ImageGiftListUploderProps) => {
  const [imgPair, setImgPair] = useState<
    Array<{ ar: string | null; en: string | null }>
  >([{ ar: null, en: null }]);
  const [keys, setKeys] = useState<
    Array<{ ar: string | null; en: string | null }>
  >([{ ar: null, en: null }]);
  useEffect(() => {
    if (
      imagesUploaded &&
      imagesUploaded.length > 0 &&
      imgPair[0].ar === null &&
      imgPair[0].en == null
    ) {
      setImgPair([...imagesUploaded]);
    }
  }, [imagesUploaded]);
  useEffect(() => {
    if (
      imageSessionKey &&
      imageSessionKey.length > 0 &&
      keys[0].ar === null &&
      keys[0].en == null
    ) {
      setKeys([...imageSessionKey]);
    }
  }, [imageSessionKey]);

  const handelRemovePair = (index: number) => {
    const newArr = imgPair;
    newArr.splice(index, 1);
    setImgPair([...newArr]);
    setImagesUploaded([...newArr]);

    const arr = keys;
    arr.splice(index, 1);
    setKeys([...arr]);
    setImageSessionKey([...arr]);
  };

  return (
    <div className={styles.imgList}>
      <div className={styles.giftsWrapper}>
        {imgPair.map((pair, index) => {
          return (
            <div className={styles.group}>
              {imgPair.length > 1 && (
                <Button
                  className={styles.close}
                  type="text"
                  danger
                  icon={<CloseOutlined />}
                  onClick={() => handelRemovePair(index)}
                />
              )}
              <TranslateImageUploader
                key={keys[index].en ?? undefined}
                title={"English Image"}
                seesionId={seesionId}
                setImagesUploaded={(e: any) => {
                  const newArr = imgPair;
                  newArr[index].en = e.src;
                  setImgPair([...newArr]);
                  setImagesUploaded([...newArr]);

                  const arr = keys;
                  arr[index].en = e.key;
                  setKeys([...arr]);
                  setImageSessionKey([...arr]);
                }}
                imagesUploaded={pair.en ? [pair.en] : [""]}
                setSessionId={setSessionId}
                setIsUploading={setIsUploading}
                disabled={isUploading === "uploading"}
                onSuccess={onSuccess}
              />
              <TranslateImageUploader
                key={keys[index].ar ?? undefined}
                title={"Arabic Image"}
                seesionId={seesionId}
                setImagesUploaded={(e: any) => {
                  const newArr = imgPair;
                  newArr[index].ar = e.src;
                  setImgPair([...newArr]);
                  setImagesUploaded([...newArr]);

                  const arr = keys;
                  arr[index].ar = e.key;
                  setKeys([...arr]);
                  setImageSessionKey([...arr]);
                }}
                imagesUploaded={pair.ar ? [pair.ar] : [""]}
                setSessionId={setSessionId}
                setIsUploading={setIsUploading}
                disabled={isUploading === "uploading"}
                onSuccess={onSuccess}
              />
            </div>
          );
        })}
      </div>
      <div className={styles.button}>
        <Button
          type="primary"
          disabled={
            isUploading === "uploading" ||
            !imgPair[imgPair.length - 1].ar ||
            !imgPair[imgPair.length - 1].en
          }
          onClick={() => {
            setImgPair((pre) => [...pre, { ar: null, en: null }]);
            setKeys((pre) => [...pre, { ar: null, en: null }]);
          }}
        >
          + Add More Images
        </Button>
      </div>
    </div>
  );
};
export default ImageGiftListUploder;
