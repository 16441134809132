import { Button, Result } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styles from "../styles.module.scss";
/**
 * @description a component that used to show an ui error intereface for 404 errors
 */
const FourOFourPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className={styles.errorPage}>
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Button type="primary" onClick={() => navigate("/")}>
            Back Home
          </Button>
        }
      />
    </div>
  );
};

export default FourOFourPage;
