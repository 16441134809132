import { Col, Form, Modal, Row } from "antd";
import { useForm } from "antd/es/form/Form";
import { useState } from "react";
import ImagesUploder from "features/common/components/Upload/UploadWithSession/ImagesUploader/ImagesUplader";
import { UploadFileStatus } from "antd/es/upload/interface";
import EnglishInput from "features/common/components/Forms/formItems/stringInputs/EnglishInput";
import ArabicInput from "features/common/components/Forms/formItems/stringInputs/ArabicInput";
import { useAddShopApi } from "features/shops/apis/useAddShopApi";
import LocationInput from "features/common/components/Forms/formItems/LocationInput/LocationInput";
import ContactsFormList from "features/common/components/Forms/formLists/ContactsFormList/ContactsFormList";
import { ShopFormInterface } from "features/shops/interfaces/forms/ShopForm.d";
import PasswordInput from "features/common/components/Forms/formItems/stringInputs/PasswordInput";
import EmailInput from "features/common/components/Forms/formItems/stringInputs/EmailInput";
type AddShopModalProps = {
  isOpen: boolean;
  setIsOpen: any;
  onAddSucces?: any;
  setFieldAfterAdd?: (value: any) => any;
  // formToInsert?: FormInstance;
};
const AddShopModal = ({
  isOpen,
  setIsOpen,
  onAddSucces = () => {},
  setFieldAfterAdd,
}: AddShopModalProps) => {
  const [form] = useForm();
  const [sessionId, setSessionId] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState<UploadFileStatus>();
  const { mutateAsync, isSuccess, isLoading } = useAddShopApi();
  const onAddItems = async (values: ShopFormInterface) => {
    console.log(values);
    const formData = new FormData();
    console.log(values);
    formData.append("session_id", sessionId || "");
    formData.append("user_name", values.OWNname);
    formData.append("user_password", values.password);
    formData.append("user_email", values.email);
    formData.append("shop_name[en]", values.ENname);
    formData.append("shop_name[ar]", values.ARname);
    // formData.append("shop_address", values.AddENname);
    formData.append("shop_address[en]", values.AddENname);
    formData.append("shop_address[ar]", values.AddARname);
    if (values.lon !== undefined && values.lat !== undefined) {
      formData.append("longitude", `${values.lon}`);
      formData.append("latitude", `${values.lat}`);
    }
    formData.append("contacts", JSON.stringify(values.contacts));
    await mutateAsync(formData).then((res) => {
      console.log(res);
      onAddSucces();
      setIsOpen(false);
      form.resetFields();
      setFieldAfterAdd?.(res.data.data.shop.id);
    });
  };

  return (
    <>
      <Modal
        style={{ top: 20 }}
        bodyStyle={{ maxHeight: 600, overflowY: "scroll", padding: 10 }}
        destroyOnClose
        title="Create New Shop"
        open={isOpen}
        okText="Create"
        onOk={() => {
          form.submit();
        }}
        okButtonProps={{
          disabled: isUploading === "uploading" || sessionId === null,
          type: "text",
          htmlType: "submit",
        }}
        cancelButtonProps={{
          disabled: isUploading === "uploading",
          danger: true,
          type: "text",
        }}
        confirmLoading={isLoading}
        onCancel={() => {
          setIsOpen(false);
          form.resetFields();
        }}
        width={800}
      >
        <Form
          id={"addItem"}
          labelCol={{ span: 24 }}
          autoComplete="off"
          form={form}
          onFinish={onAddItems}
        >
          <ImagesUploder
            maxCount={1}
            seesionId={sessionId}
            setSessionId={setSessionId}
            height="100px"
            setIsUploading={setIsUploading}
            aspectSlider={true}
          />
          <Row gutter={16}>
            <Col span={9}>
              <EnglishInput
                label={<div className="label">Owner Name</div>}
                name="OWNname"
              />
            </Col>
            <Col span={9}>
              <EmailInput
                name={"email"}
                rules={[
                  {
                    required: true,
                    message: `please enter your email`,
                  },
                  {
                    type: "email",
                    message: `please enter valid email`,
                  },
                ]}
                label={<div className="label">Email</div>}
              />
            </Col>
            <Col span={6}>
              <PasswordInput
                label={<div className="label">Password</div>}
                name={"password"}
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                  {
                    whitespace: true,
                    message: "No whitespaces in the password",
                  },
                  {
                    min: 8,
                    max: 100,
                    message: "password must be at least 8 chars ",
                  },
                ]}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <EnglishInput
                label={<div className="label">Name - En</div>}
                name="ENname"
              />
            </Col>
            <Col span={12}>
              <ArabicInput
                name="ARname"
                label={<div className="label">Name - Ar</div>}
              />
            </Col>
            <Col span={12}>
              <EnglishInput
                label={<div className="label">Address - En</div>}
                name="AddENname"
              />
            </Col>
            <Col span={12}>
              <ArabicInput
                name="AddARname"
                label={<div className="label">Address - Ar</div>}
              />
            </Col>
            <Col span={24}>
              <LocationInput
                name="location"
                label={<div className="label">Location</div>}
                labelCol={{ span: 24 }}
                form={form}
                editable
                // required
              />
            </Col>
            <Col span={24}>
              <ContactsFormList name={"contacts"} />
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default AddShopModal;
