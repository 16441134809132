import { createSlice } from "@reduxjs/toolkit";
import {
  filtersAction,
  pageAction,
  perPageAction,
  searchAction,
  sorterAction,
} from "features/common/redux/actions/globalActions";
import { UserAuthSliceActions } from "features/auth/redux/slice/userAuthSlice";
import { InitialStateShopsSliceInterface } from "features/shops/interfaces/InitialStateShopsSliceInterface";
import { AppsAuthSliceActions } from "features/auth/redux/slice/appsAuthSlice";

const initialStateForShopsSlice: InitialStateShopsSliceInterface = {
  pagnation: { page: 1, perPage: 10 },
  sorter: { type: "", name: null },
  search: "",
  filters: null,
};

const shopsSlice = createSlice({
  name: "shops",
  initialState: initialStateForShopsSlice,
  reducers: {
    Reset: () => ({ ...initialStateForShopsSlice }),
    PageAction: pageAction,
    PerPageAction: perPageAction,
    SorterAction: sorterAction,
    SearchAction: searchAction,
    FiltersAction: filtersAction,
  },
  extraReducers(builder) {
    builder.addCase(UserAuthSliceActions.Logout, () => ({
      ...initialStateForShopsSlice,
    }));
    builder.addCase(
      AppsAuthSliceActions.SetCurrentAppAction,
      () => ({
        ...initialStateForShopsSlice,
      })
    );
  },
});

export const ShopsSliceReducer = shopsSlice.reducer;
export const ShopsSliceActions = shopsSlice.actions;
