import { Col, Row } from "antd";
import styles from "./styles.module.scss";
import formatKey from "features/common/helpers/formatKey";
import { GamesStatisticModel } from "features/common/interfaces/Models/Game.d";

interface DashboardCardProps {
  title: string;
  statistics?: GamesStatisticModel;
}
const DashboardCard = ({ title, statistics }: DashboardCardProps) => {
  return (
    <div className={styles.dashCard}>
      <div className={styles.title}>{formatKey(title)}</div>
      <Row gutter={[20, 20]}>
        {statistics &&
          Object.keys(statistics).map((key: string) => {
            return (
              <Col span={12}>
                <div className={styles.card}>
                  <div>{formatKey(key)}</div>
                  <div className={styles.value}>
                    {statistics?.[key as keyof GamesStatisticModel] ?? 0}
                  </div>
                </div>
              </Col>
            );
          })}
      </Row>
    </div>
  );
};

export default DashboardCard;
