import { createSlice } from "@reduxjs/toolkit";
import { IAppsAuthSliceInitState } from "features/auth/interfaces/I.AppsAuthSliceIinitState";
import { setCurrentAppAction } from "../actions/appsAuthActions";
import { UserAuthSliceActions } from "./userAuthSlice";

const initialState: IAppsAuthSliceInitState = {
  currAppId: null,
  currAppIcon: null,
  currAppName: null,
  currAppToken: null,
  currAppFeatures: [],
};
/**
 * @description the app auth slice control the authentication and the authorization
 *  systems in the app and it has the current app (id,icon,name,token and feautres)
 */
const appsAuthSlice = createSlice({
  name: "appAuth",
  initialState,
  reducers: {
    Reset: () => ({ ...initialState }),
    SetCurrentAppAction: setCurrentAppAction,
  },
  extraReducers(builder) {
    builder.addCase(UserAuthSliceActions.Logout, (state, action) => ({
      ...initialState,
    }));
  },
});
export const appsAuthReducer = appsAuthSlice.reducer;
export const AppsAuthSliceActions = appsAuthSlice.actions;
