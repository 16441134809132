import { Spin } from "antd";
import styles from "./styles.module.scss";

interface HeaderLoadingProps {
  loading?: boolean;
  line?: boolean;
  top?: number;
}
/**
 *
 * @description a custom loader that usually used while an api is refetching 
 * and it is have two shapes line and icon loader
 * @param {boolean} loading
 * @param {boolean} line - true ? line : icon 
 * @param {boolean} top - absolute position
 */
const HeaderLoading = ({ loading, line, top = 0 }: HeaderLoadingProps) => {
  return (
    <div className={styles.container}>
      {loading ? (
        line ? (
          <div className={styles.line}></div>
        ) : (
          <div style={{ top: top }} className={styles.loader}>
            <Spin />
          </div>
        )
      ) : (
        <></>
      )}
    </div>
  );
};

export default HeaderLoading;
