import { useErrorHandler } from "features/common/hooks/useErrorHandler";
import {
  useAppDispatch,
  useAppSelector,
} from "features/common/hooks/useReduxHooks";
import { request } from "libs/axios";
import { useCookies } from "react-cookie";
import { useMutation } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { UserAuthSliceActions } from "../redux/slice/userAuthSlice";
import img from "assets/images/download.png";
import { useEncrypData } from "../hooks/useEncryptData";
import { UserModel } from "features/common/interfaces/Models/User.d";
import { message } from "antd";

interface loginMutationFunctionProps {
  data: FormData;
  rememberMe: boolean;
}
const loginMutationFunction = async ({
  data,
  rememberMe,
}: loginMutationFunctionProps) => {
  return {
    rememberMe,
    response: request<UserModel>({
      url: "/auth/login",
      method: "POST",
      data,
    }),
  };
};

export const useLogin = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [_, setCookie] = useCookies();
  const location = useLocation();
  const state = location.state as any;
  const from = state?.from?.pathname || "/auth/apps";
  const { FCMtoken } = useAppSelector((state) => state.ui);
  const setEncryptUserData = useEncrypData();
  const { t } = useTranslation();

  return useMutation("auth/login", loginMutationFunction, {
    onSuccess: async ({ response, rememberMe }) => {
      console.log((await response).data);
      const { token, data: userInfo } = (await response).data;
      if (userInfo.apps?.length > 0) {
        const dataEncrypted = setEncryptUserData({
          ...userInfo,
          authToken: token,
          rememberMe,
        });
        if (dataEncrypted) {
          sessionStorage.setItem("user", dataEncrypted);
          if (rememberMe) {
            setCookie("user", dataEncrypted, {
              path: "/",
              maxAge: 1000 * 60 * 60 * 24 * 30, // One Month
            });
          }
        }
        dispatch(
          UserAuthSliceActions.Login({
            ...userInfo,
            authToken: (await response).data?.token,
            rememberMe,
          })
        );
        navigate(from);
      } else {
        message.error(`You don't have apps in our system!`);
      }
    },
    // onError: (err) => {
    //   console.log(err);
    // },
  });
};
