import { notification } from "antd";
import { ReactNode, useEffect, useState } from "react";
import { WifiOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

/**
 *
 * @namespace NetworkProvider
 */
/** responsible for offline mode and show notifications when the user is offline
 * @param {ReactNode} children - wrapped components
 */
interface NetworkProviderProps {
  children: ReactNode;
}
const NetworkProvider = ({children}: NetworkProviderProps) => {
  const [isOffline, setIsOffline] = useState(false);
  const { t } = useTranslation();

  const key = "network_status";

  useEffect(() => {
    if (navigator.onLine) {
      setIsOffline(false);
    } else {
      setIsOffline(true);
    }
  }, [navigator.onLine]);

  window.addEventListener("online", () => {
    setIsOffline(false);
  });
  window.addEventListener("offline", () => {
    setIsOffline(true);
  });

  useEffect(() => {
    if (isOffline) {
      notification.info({
        key,
        message: `${t("You_are_currently_offline")}`,
        description: `${t(
          "Please_check_your_internet_connection_and_try_again"
        )}`,
        placement: "bottomRight",
        icon: <WifiOutlined style={{ color: "#f81d22" }} />,
        duration: 0,
      });
    } else {
      notification.destroy(key);
    }
  }, [isOffline]);

  return <>{children}</>;
};
export default NetworkProvider;
