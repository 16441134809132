import { useTranslation } from "react-i18next";
import styles from "../styles.module.scss";
import { useNavigate } from "react-router-dom";
import { Button, Result, Space } from "antd";
/**
 * @description a component that used to show an ui error intereface for 500 errors or any runtime error
 */
const FiveOOPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className={styles.errorPage}>
      <Result
        status="500"
        title="500"
        subTitle="Sorry, something went wrong."
        extra={
          <Space.Compact className={styles.actions}>
            <Button
              type="default"
              shape="round"
              onClick={() => window.location.reload()}
            >
              Reload
            </Button>
            {/* <div>If there is no response </div> */}
            <Button
              type="primary"
              shape="round"
              onClick={() => {
                navigate("/");
                window.location.reload();
                // resetErrorBoundary();
              }}
            >
              Go Home
            </Button>
          </Space.Compact>
        }
      />
    </div>
  );
};

export default FiveOOPage;
