import { Wrapper, Status } from "@googlemaps/react-wrapper";
import Loading from "../Loading/PageLoading/Loading";
import React, { useEffect, useRef, useState } from "react";
import MarkerIcon from "assets/svgs/marker_icon.svg";
import { useAppSelector } from "features/common/hooks/useReduxHooks";

const render = (status: Status) => {
  switch (status) {
    case Status.LOADING:
      return (<Loading />) as any;
    case Status.FAILURE:
      return <div>Faild</div>;
    default:
      return true;
  }
};

const icon: google.maps.ReadonlyMarkerOptions["icon"] = MarkerIcon;

interface MapComponentProps extends google.maps.MapOptions {
  center?: google.maps.LatLngLiteral;
  zoom?: number;
  style?: React.CSSProperties;
  onClick?: (e: google.maps.MapMouseEvent) => void;
  onIdle?: (map: google.maps.Map) => void;
  initialMarker?: google.maps.LatLngLiteral;
}

let marker: google.maps.Marker;
const setMarker = (e: google.maps.Marker) => {
  marker = e;
};
/**
 *
 */
const MapComponent = ({
  center,
  zoom,
  style,
  onClick,
  onIdle,
  initialMarker,
  ...props
}: MapComponentProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();
  // const [marker, setMarker] = useState<google.maps.Marker>();

  useEffect(() => {
    if (ref.current && !map) {
      setMap(
        new window.google.maps.Map(ref.current, {
          center,
          zoom,
          gestureHandling: "cooperative",
        })
      );
    }
  }, [ref, map]);

  useEffect(() => {
    if (map) {
      ["click", "idle"].forEach((eventName) =>
        google.maps.event.clearListeners(map, eventName)
      );
      if (onClick) {
        map.addListener("click", (e) => {
          if (e && onClick) {
            onClick(e);
          }

          if (marker && marker.setMap) {
            marker.setMap(null);
          }
          setMarker(
            new google.maps.Marker({
              position: e.latLng,
              map,
              icon,
            })
          );
        });
      }

      if (onIdle) {
        map.addListener("idle", () => onIdle(map));
      }
    }
  }, [map, onClick, onIdle]);

  useEffect(() => {
    if (initialMarker?.lat !== undefined && initialMarker?.lng !== undefined) {
      const position = new google.maps.LatLng(
        initialMarker.lat,
        initialMarker.lng
      );
      if (marker) marker.setMap(null);
      setMarker(
        new google.maps.Marker({
          position,
          map,
          icon,
        })
      );
    }
  }, [map]);
  return <div ref={ref} id="map" {...props} style={style} />;
};

interface MapWrapperProps extends MapComponentProps {}
const MapWrapper = ({
  center = { lat: 33.523698, lng: 36.316379 },
  zoom = 13,
  style,
  onClick,
  ...props
}: MapWrapperProps) => {
  const { theme } = useAppSelector((state) => state.ui);
  return (
    <Wrapper
      apiKey={process.env.REACT_APP_GOOGLEMAPS_JAVASCRIPT_API_KEY as string}
      render={render}
    >
      <MapComponent
        center={
          props.initialMarker?.lat !== undefined &&
          props.initialMarker.lng !== undefined
            ? props.initialMarker
            : center
        }
        zoom={zoom}
        style={
          style ?? {
            width: "100%",
            minHeight: "50vh",
            borderRadius: "5px",
          }
        }
        onClick={onClick}
        {...props}
      />
    </Wrapper>
  );
};

export default MapWrapper;
