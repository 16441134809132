import { useAppSelector } from "features/common/hooks/useReduxHooks";
import { request } from "libs/axios";
import { useQuery } from "react-query";
import { GameResponse } from "../../common/interfaces/apis/responses/games/GameResponse.d";
import { gamesTypes } from "../types/gamesTypes";

export const useShowGameApi = (id: number | null, resource: gamesTypes) => {
  const { currAppToken } = useAppSelector((state) => state.appsAuth);

  return useQuery(
    [`ShowGame-${resource}`, id],
    () => {
      return request<GameResponse>({
        url:
          resource === "scratch_ticket"
            ? `/scratch-tickets/${id}`
            : resource === "scratch_grid"
            ? `/scratch-grids/${id}`
            : resource === "number_guess"
            ? `/number-guesses/${id}`
            : "",
        method: "GET",
        headers: {
          Authorization: `Bearer ${currAppToken}`,
        },
      });
    },
    {
      select: (data) => {
        return data.data?.data;
      },
    }
  );
};
