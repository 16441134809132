import { useAppDispatch } from "features/common/hooks/useReduxHooks";
import { request } from "libs/axios";
import { useCookies } from "react-cookie";
import { useMutation } from "react-query";
import { UserAuthSliceActions } from "../redux/slice/userAuthSlice";

export const useLogout = () => {
  const dispatch = useAppDispatch();
  const [userCookies, _user, removeUserCookie] = useCookies(["user"]);
  const [appCookies, _app, removeAppCookie] = useCookies(["app"]);

  return useMutation(
    "auth/logout",
    () =>
      request({
        url: "/auth/logout",
        method: "GET",
      }),
    {
      onSuccess: async () => {
        dispatch(UserAuthSliceActions.Logout());
        sessionStorage.removeItem("user");
        sessionStorage.removeItem("app");
        removeUserCookie("user");
        removeAppCookie("app");
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );
};
