import { message } from "antd";
import { useAppSelector } from "features/common/hooks/useReduxHooks";
import { request } from "libs/axios";
import { useMutation, useQueryClient } from "react-query";

export interface RejectVideoInterface {
  token: string | null;
  id: number | string;
}

const mutateFunction = async ({ id, token }: RejectVideoInterface) => {
  return request({
    url: `/shops/${id}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const useDeleteShopApi = () => {
  const { currAppToken } = useAppSelector((state) => state.appsAuth);
  const queryClient = useQueryClient();

  return useMutation(
    "shops/delete",
    (id: string) => {
      return mutateFunction({ id, token: currAppToken });
    },
    {
      onSuccess: async (response) => {
        message.success(response.data.message);
        queryClient.invalidateQueries([`getAllShops`]);
      },
    }
  );
};
