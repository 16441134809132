import { Spin } from "antd";
import Loading from "features/common/components/Loading/PageLoading/Loading";
import { useAppSelector } from "features/common/hooks/useReduxHooks";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import { Suspense } from "react";

interface LoadingProps {
  children: React.ReactNode;
}
/**
 * @description Custom React Suspence with some addetional functionallty
 * @param {LoadingProps} props
 */
const CustomSuspense = ({ children }: LoadingProps) => {
  const { t } = useTranslation();
  const { loading } = useAppSelector((state) => state.ui);
  return (
    <>
      <Suspense fallback={<Loading />}>
        {loading ? (
          <>
            <div className={styles.loading_page}>
              <Spin
                spinning
                size={"large"}
                tip={t(`loading...`)}
                indicator={<Loading />}
              />
            </div>
          </>
        ) : (
          children
        )}
      </Suspense>
    </>
  );
};
export default CustomSuspense;
