import { Button, Col, Form, Row, Spin } from "antd";
import styles from "./styles.module.scss";
import EnglishInput from "features/common/components/Forms/formItems/stringInputs/EnglishInput";
import EmailInput from "features/common/components/Forms/formItems/stringInputs/EmailInput";
import PasswordInput from "features/common/components/Forms/formItems/stringInputs/PasswordInput";
import { useAddConsoleUserApi } from "features/users/apis/useAddConsoleUserApi";
import { useFetchConsolePermissionsApi } from "features/users/apis/useFetchConsolePermissionsApi";
import PermissionSection from "../Permissions/PermissionsSection";
import { useEffect, useState } from "react";
import { UsersSliceActions } from "features/users/redux/slices/usersSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "features/common/hooks/useReduxHooks";

const AddConsoleUserSection = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { permissions } = useAppSelector((state) => state.users.add.console);
  const [permissionsData, setPermissionsData] = useState<any>([]);
  const { isLoading, mutateAsync } = useAddConsoleUserApi();
  const { data, isLoading: permissionsLoading } =
    useFetchConsolePermissionsApi();
  const onFinish = (values: any) => {
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("email", values.email);
    formData.append("password", values.password);
    permissions.forEach((permission, index) => {
      formData.append(`permissions[${index}]`, `${permission}`);
    });
    mutateAsync(formData);
  };
  useEffect(() => {
    if (data) {
      console.log(data.sub_features);
      setPermissionsData(
        data?.sub_features?.map((feat: any) => {
          return {
            feat: feat?.key,
            permissions: feat?.permissions,
          };
        })
      );
    }
  }, [data]);
  return (
    <>
      <Spin
        spinning={isLoading || permissionsLoading}
        wrapperClassName={styles.formContainer}
      >
        {" "}
        <Col span={24} className={styles.formContainer}>
          <Form
            form={form}
            onFinish={onFinish}
            labelCol={{ span: 24 }}
            className={styles.form}
          >
            <Row gutter={32}>
              <Col className={styles.col} xs={8}>
                <EnglishInput
                  name={"name"}
                  label={<div className="label">User Name</div>}
                />
              </Col>
              <Col className={styles.col} xs={8}>
                <EmailInput
                  name={"email"}
                  label={<div className="label">Email</div>}
                />
              </Col>
              <Col className={styles.col} xs={8}>
                <PasswordInput
                  name={"password"}
                  label={<div className="label">Password</div>}
                />
              </Col>
              <Col className={styles.col} span={24}>
                <PermissionSection
                  permissionsData={permissionsData}
                  selectedPermissions={permissions}
                  onChange={(value) => {
                    dispatch(
                      UsersSliceActions.SetPermissionsAction([
                        "add",
                        "console",
                        value,
                      ])
                    );
                  }}
                />
              </Col>
            </Row>
          </Form>
        </Col>
        <Col
          span={4}
          className={styles.col}
          style={{ marginInlineStart: "auto" }}
        >
          <Button
            size="large"
            type="primary"
            shape="round"
            block
            onClick={() => form.submit()}
            loading={isLoading}
          >
            Save
          </Button>
        </Col>
      </Spin>
    </>
  );
};

export default AddConsoleUserSection;
