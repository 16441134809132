import { Button, Form, FormItemProps, Input, Modal } from "antd";
import MapWrapper from "features/common/components/MapWrapper/MapWrapper";
import React from "react";
import { FormInstance } from "antd/lib/form/Form";
import { ReactComponent as MarkerIcon } from "assets/svgs/marker_icon.svg";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { InfoCircleOutlined } from "@ant-design/icons";

interface LocationInputProps extends FormItemProps {
  initialValue?: google.maps.LatLngLiteral;
  form: FormInstance;
  editable?: boolean;
}
/**
 * @description a component used to pick a locatioon from goole maps
 * @param {google.maps.LatLngLiteral} initialValue
 * @param {FormInstance} from
 */
const LocationInput = ({
  initialValue,
  form,
  editable = false,
  ...props
}: LocationInputProps) => {
  const { t } = useTranslation();
  const regex = /^(-?\d+(\.\d+)?)\s*,\s*(-?\d+(\.\d+)?)$/;
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [value, setValue] = React.useState<string>();
  const [isEdit, setIsEdit] = React.useState<boolean>(false);
  const [isValid, setIsValid] = React.useState<boolean>(true);
  const [InitialLocation, setInitialLocation] =
    React.useState<google.maps.LatLngLiteral>();
  const handleClose = () => {
    setIsOpen(false);
  };
  const handleOpen = () => {
    setIsOpen(true);
  };
  const handleEdit = () => {
    setIsEdit(true);
  };
  const handleFinish = () => {
    if (value) {
      const match = value.match(regex);
      if (match) {
        const lat = parseFloat(match[1].replaceAll(" ", "")); // Extract latitude value
        const lng = parseFloat(match[3].replaceAll(" ", "")); // Extract longitude value
        // console.log(`Latitude: ${lat}, Longitude: ${lng}`);
        setLocation({ lat, lng });
        setIsEdit(false);
        return;
      }
    } else {
      setLocation(undefined);
    }
    setIsEdit(false);
    // console.log(InitialLocation);
  };
  const setLoc = (e: google.maps.MapMouseEvent) => {
    setInitialLocation({
      lat: e.latLng?.lat()!,
      lng: e.latLng?.lng()!,
    });
    form.setFieldValue("lat", e.latLng?.lat());
    form.setFieldValue("lon", e.latLng?.lng());
    form.resetFields(["location"]);
    setValue(`${e.latLng?.lat()} , ${e.latLng?.lng()}`);
  };

  const setLocation = (e?: google.maps.LatLngLiteral) => {
    setInitialLocation(
      e
        ? {
            lat: e.lat,
            lng: e.lng,
          }
        : undefined
    );
    form.setFieldValue("lat", e ? e.lat : undefined);
    form.setFieldValue("lon", e ? e.lng : undefined);
    form.resetFields(["location"]);
    setValue(e ? `${e.lat} , ${e.lng}` : undefined);
  };

  React.useEffect(() => {
    if (
      initialValue?.lat !== undefined &&
      initialValue.lng !== undefined &&
      initialValue?.lat !== null &&
      initialValue.lng !== null &&
      InitialLocation === undefined
    ) {
      setInitialLocation({
        lat: initialValue.lat,
        lng: initialValue.lng,
      });
      form.setFieldValue("lat", initialValue.lat);
      form.setFieldValue("lon", initialValue.lng);
      form.resetFields(["location"]);
      setValue(`${initialValue.lat} , ${initialValue.lng}`);
    } else if (
      form.getFieldValue("lat") !== undefined &&
      form.getFieldValue("lon") !== undefined &&
      form.getFieldValue("lat") !== null &&
      form.getFieldValue("lon") !== null &&
      InitialLocation === undefined
    ) {
      setInitialLocation({
        lat: form.getFieldValue("lat"),
        lng: form.getFieldValue("lon"),
      });
      setValue(`${form.getFieldValue("lat")} , ${form.getFieldValue("lon")}`);
    }
  }, [initialValue]);

  return (
    <>
      <Form.Item
        name={"location"}
        required={props.required}
        onMetaChange={(e) => {
          // console.log(e);
        }}
        tooltip={
          editable
            ? {
                title: "You can add location manually like (0 , 0)",
                icon: <InfoCircleOutlined />,
              }
            : undefined
        }
        rules={[
          {
            validator() {
              if (props.required) {
                if (
                  !(
                    InitialLocation?.lat !== undefined &&
                    InitialLocation.lng !== undefined
                  )
                )
                  return Promise.reject(new Error("Please Enter ${label}!"));
              }
              if (isEdit) {
                if (value) {
                  const match = value.match(regex);
                  if (match) {
                    setIsValid(true);
                    return Promise.resolve();
                  }
                  setIsValid(false);
                  return Promise.reject(new Error("This value is invalid!"));
                }
                setIsValid(true);
                return Promise.resolve();
              }
              return Promise.resolve();
            },
          },
        ]}
        label={props.label}
        {...props}
      >
        <Form.Item hidden name={"lat"} />
        <Form.Item hidden name={"lon"} />
        <Input
          readOnly={!isEdit}
          placeholder={"Click To Set Location"}
          value={value}
          onClick={editable ? undefined : handleOpen}
          onBlur={isValid ? handleFinish : undefined}
          style={editable ? { cursor: "text" } : { cursor: "pointer" }}
          onChange={(e) => {
            setValue(e.target.value);
            form.validateFields(["location"]);
          }}
          prefix={
            editable ? (
              <>
                <Button
                  type="text"
                  icon={
                    <FontAwesomeIcon
                      icon={faEdit}
                      className={`${styles.marker} ${
                        isEdit ? `${styles.active}` : ""
                      }`}
                    />
                  }
                  onClick={
                    isEdit ? (isValid ? handleFinish : undefined) : handleEdit
                  }
                />
                <Button
                  type="text"
                  icon={
                    <MarkerIcon
                      className={`${styles.marker} ${
                        InitialLocation?.lat !== undefined &&
                        InitialLocation?.lng !== undefined &&
                        InitialLocation?.lat !== null &&
                        InitialLocation?.lng !== null
                          ? `${styles.active}`
                          : ""
                      }`}
                    />
                  }
                  onClick={isValid ? handleOpen : undefined}
                />
              </>
            ) : undefined
          }
        />
      </Form.Item>
      <Modal
        key={"MapModal"}
        open={isOpen}
        closeIcon={<span style={{ display: "none" }} />}
        cancelButtonProps={{ style: { display: "none" } }}
        onCancel={handleClose}
        onOk={handleClose}
        destroyOnClose
      >
        <MapWrapper initialMarker={InitialLocation} onClick={setLoc} />
      </Modal>
    </>
  );
};

export default LocationInput;
