import { lazy } from "react";
import { Route } from "react-router-dom";
import AllShopsPage from "../pages/AllShops/AllShopsPage";
import EditShopPage from "../pages/UpdateShop/UpdateShopPage";
import { AppAuthorizedRoutes } from "router/middlewares/AppAuthorizationRoutes";

// const AllShopsPage = lazy(
//   () => import("features/shops/pages/AllShops/AllShopsPage")
// );
// const EditShopPage = lazy(
//   () => import("features/shops/pages/UpdateShop/UpdateShopPage")
// );

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  <Route path="shop-management">
    <Route index element={<AllShopsPage />} />
    <Route
      element={
        <AppAuthorizedRoutes
          features={["SHOP_MANAGEMENT"]}
          permissions={["edit_shops"]}
        />
      }
    >
      <Route path=":id" element={<EditShopPage />} />
    </Route>
  </Route>,
];
