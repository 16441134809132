import { IAppFeatures } from "../interfaces/I.AppFeatures";
/**
 * a helper fuction use to get the feautres keys in array of strings
 * @param {IAppFeatures} features
 * @returns {string[]}
 */
export const getFeaturesKeys = (features: IAppFeatures[]) => {
  return features.map((feature) => {
    return feature.key;
  });
};
