import React, { ReactNode } from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import styles from "./styles.module.scss";
import FiveOO from "../500/500";
import { useErrorHandler } from "features/common/hooks/useErrorHandler";

interface CustomErrorBoundaryProps {
  children: React.ReactNode;
}
/**
 * @description a custom react ErrorBoundary with a FallBackComponents
 * @param {ReactNode} children - the entire app usually
 */
function CustomErrorBoundary({ children }: CustomErrorBoundaryProps) {
  useErrorHandler()
  const FallBackComponents = ({ error, resetErrorBoundary }: FallbackProps) => {
    return (
      <div className={styles.container}>
        <FiveOO />
      </div>
    );
  };
  return (
    <ErrorBoundary FallbackComponent={FallBackComponents}>
      {children}
    </ErrorBoundary>
  );
}

export default CustomErrorBoundary;
