import { FloatButton, Layout } from "antd";
import { Content } from "antd/es/layout/layout";
import { Outlet } from "react-router-dom";
import Header from "../Header/Header";
import styles from "./styles.module.scss";
import Sidebar from "../Sidebar/Sidebar";

/**
 * the main controlled component for the entire app layout t
 */
const MainLayout = () => {
  return (
    <Layout>
      <Header />
      <Layout>
        <Sidebar />
        <Content className={styles.mainLayout}>
          <Outlet />
          <FloatButton.BackTop />
        </Content>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
