import { useForm } from "antd/es/form/Form";
import GiftsSwiper from "features/games/components/GiftsSwiper/GiftsSwiper";
import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import {
  Button,
  Col,
  Divider,
  Form,
  Row,
  Space,
  Switch,
  message,
  Input,
} from "antd";
import EnglishInput from "features/common/components/Forms/formItems/stringInputs/EnglishInput";
import ArabicInput from "features/common/components/Forms/formItems/stringInputs/ArabicInput";
import SingleApiSelectInput from "features/common/components/Forms/formItems/selectInputs/SingleApiSelectInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLeftLong, faPlus } from "@fortawesome/free-solid-svg-icons";
import ImagesUploder from "features/common/components/Upload/UploadWithSession/ImagesUploader/ImagesUplader";
import {
  useAppDispatch,
  useAppSelector,
} from "features/common/hooks/useReduxHooks";
import { GamesSliceActions } from "features/games/redux/sllice/gamesSlice";
import CustomDateRangeInput from "features/common/components/Forms/formItems/Date/DatePicker";
import AddShopModal from "features/shops/components/AddShopModal/AddShopModal";
import { useQueryClient } from "react-query";
import { changeDateToString } from "features/common/helpers/changeDateToString";
import { UploadFileStatus } from "antd/es/upload/interface";
import { I_Gift } from "features/games/interfaces/I.Gift";
import { useAddGameApi } from "features/games/apis/useAddGameApi";
import { useNavigate } from "react-router-dom";
import { gamesTypes } from "features/games/types/gamesTypes";
import dayjs from "dayjs";
import { ScratchTicketFormInterface } from "features/games/interfaces/forms/GamesForm.d";

function ScratchTicketAddPage() {
  const resource: gamesTypes = "scratch_ticket";
  const [isUploading, setIsUploading] = useState<UploadFileStatus>();
  const [wonImageFlag, setWonImageFlag] = useState(false);
  const [wonImageARFlag, setWonImageARFlag] = useState(false);
  const [backgroundImageFlag, setBackgroundImageFlag] = useState(false);
  const [backgroundImageARFlag, setBackgroundImageARFlag] = useState(false);
  const [isAddNewShopModalOpen, setIsAddNewShopModalOpen] = useState(false);
  const [form] = useForm();
  const dispatch = useAppDispatch();
  const { sessionID, addedGifts } = useAppSelector(
    (state) => state.games[resource].addPage
  );
  const navigate = useNavigate();
  const { primaryColor } = useAppSelector((state) => state.ui);
  const queryClient = useQueryClient();
  const { isLoading, mutateAsync } = useAddGameApi(resource);

  const onAddNewShop = () => {
    queryClient.invalidateQueries(["getshop"]);
  };

  const onFinish = (values: ScratchTicketFormInterface) => {
    if (
      !(
        wonImageFlag &&
        backgroundImageFlag &&
        wonImageARFlag &&
        backgroundImageARFlag
      )
    ) {
      message.error("you have to add all images to this game");
      return;
    }
    if (addedGifts.length === 0) {
      message.error("you have to add at least one gift to this game");
      return;
    }
    const formData = new FormData();
    formData.append("session_id", sessionID as string);
    formData.append("title[en]", values.ENname);
    formData.append("title[ar]", values.ARname);
    formData.append("description[en]", values.ENdescription);
    formData.append("description[ar]", values.ARdescription);
    if (values.winnersLink) formData.append("winners_link", values.winnersLink);
    formData.append("shop_id", values.shop);
    // if (values.timeSwitch) {
    formData.append(
      "starts_at",
      dayjs(values.date[0]).format("YYYY-MM-DD HH:mm")
    );
    formData.append(
      "expires_at",
      dayjs(values.date[1]).format("YYYY-MM-DD HH:mm")
    );
    // }
    formData.append("qty_switch", `${Number(values.qtySwitch)}`);
    addedGifts.map((gift, index) => {
      formData.append(`gifts[${index}][type]`, gift.type);
      formData.append(`gifts[${index}][win_chance]`, `${gift.chance}`);
      formData.append(`gifts[${index}][quantity]`, `${gift.quantity}`);
      formData.append(`gifts[${index}][en][name]`, gift.title_en);
      formData.append(`gifts[${index}][ar][name]`, gift.title_ar);
      formData.append(`gifts[${index}][en][description]`, gift.description_en);
      formData.append(`gifts[${index}][ar][description]`, gift.description_ar);
      gift.imageSessionKey?.forEach((key, i) => {
        formData.append(`gifts[${index}][en_images][${i}]`, key.en as string);
        formData.append(`gifts[${index}][ar_images][${i}]`, key.ar as string);
      });
      if (gift.type === "CODES")
        formData.append(
          `gifts[${index}][file_key]`,
          gift.fileSessionKey as string
        );
    });
    mutateAsync(formData).then((res) => {
      dispatch(GamesSliceActions.ResetAdd(resource));
      navigate("/games/scratch-ticket");
    });
  };

  useEffect(() => {
    return () => {
      dispatch(GamesSliceActions.ResetAdd(resource));
    };
  }, []);
  return (
    <>
      <AddShopModal
        isOpen={isAddNewShopModalOpen}
        setIsOpen={setIsAddNewShopModalOpen}
        onAddSucces={onAddNewShop}
        setFieldAfterAdd={(value: any) => {
          form.setFieldValue("shop", value);
        }}
      />
      <Row>
        <Col span={24}>
          <Row>
            <Col span={1} className={styles.col}>
              <Button
                onClick={() => {
                  navigate("/games/scratch-ticket");
                }}
                type="text"
                icon={
                  <FontAwesomeIcon
                    icon={faLeftLong}
                    style={{ color: primaryColor ?? "", fontSize: 25 }}
                  />
                }
              />
            </Col>
            <Col span={19}>
              <div className={styles.title}>
                Scratch ticket - <span className={styles.txt}>Add</span>
              </div>
            </Col>
            <Col span={4} className={styles.col}>
              <Button
                size="large"
                type="primary"
                shape="round"
                block
                onClick={() => form.submit()}
                disabled={isUploading === "uploading"}
                loading={isLoading}
              >
                Add
              </Button>
            </Col>
          </Row>
        </Col>
        <Col span={24} className={styles.formContainer}>
          <Form
            form={form}
            onFinish={onFinish}
            labelCol={{ span: 24 }}
            className={styles.form}
          >
            <Row gutter={32}>
              <Col className={styles.col} xs={12}>
                <EnglishInput
                  name={"ENname"}
                  label={<div className="label">En - Name</div>}
                />
              </Col>
              <Col className={styles.col} xs={12}>
                <ArabicInput
                  name={"ARname"}
                  label={<div className="label">Ar - Name</div>}
                />
              </Col>
              <Col className={styles.col} xs={12}>
                <EnglishInput
                  name={"ENdescription"}
                  label={<div className="label">En - Description</div>}
                />
              </Col>
              <Col className={styles.col} xs={12}>
                <ArabicInput
                  name={"ARdescription"}
                  label={<div className="label">Ar - Description</div>}
                />
              </Col>
              <Col className={styles.col} xs={20}>
                <SingleApiSelectInput
                  label={<div className="label">Shop</div>}
                  name={"shop"}
                  url="/shops"
                  nestedObject={"shop"}
                  rules={[
                    {
                      required: true,
                      message: "please select shop",
                    },
                  ]}
                />
              </Col>
              <Col className={styles.col} xs={4}>
                <Button
                  block
                  type="primary"
                  shape="round"
                  icon={<FontAwesomeIcon icon={faPlus} />}
                  onClick={() => {
                    setIsAddNewShopModalOpen(true);
                  }}
                >
                  Create New Shop
                </Button>
              </Col>
              <Col className={styles.col} span={24}>
                <Row justify={"space-evenly"} align={"top"}>
                  <Col
                    span={7}
                    className={styles.imageUplodar}
                    style={{ background: primaryColor ?? "" }}
                  >
                    <div className={styles.imageTitle}>
                      English Background Image
                    </div>
                    <ImagesUploder
                      disabled={isUploading === "uploading"}
                      height="100px"
                      maxCount={1}
                      seesionId={sessionID}
                      multiple
                      sessionKey="bg_image_en"
                      setIsUploading={setIsUploading}
                      setSessionId={(value) =>
                        dispatch(
                          GamesSliceActions.AddPageSetSessionIdAction({
                            resource,
                            value: value,
                          })
                        )
                      }
                      onSuccess={() => {
                        setBackgroundImageFlag(true);
                      }}
                    />
                  </Col>
                  <Col
                    span={7}
                    className={styles.imageUplodar}
                    style={{ background: primaryColor ?? "" }}
                  >
                    <div className={styles.imageTitle}>English Won Image</div>
                    <ImagesUploder
                      disabled={isUploading === "uploading"}
                      height="100px"
                      maxCount={1}
                      multiple
                      seesionId={sessionID}
                      sessionKey="win_image_en"
                      setIsUploading={setIsUploading}
                      setSessionId={(value) =>
                        dispatch(
                          GamesSliceActions.AddPageSetSessionIdAction({
                            resource,
                            value: value,
                          })
                        )
                      }
                      onSuccess={() => {
                        setWonImageFlag(true);
                      }}
                    />
                  </Col>
                </Row>
              </Col>

              <Col className={styles.col} style={{ marginTop: 10 }} span={24}>
                <Row justify={"space-evenly"} align={"top"}>
                  <Col
                    span={7}
                    className={styles.imageUplodar}
                    style={{ background: primaryColor ?? "" }}
                  >
                    <div className={styles.imageTitle}>
                      Arabic Background Image
                    </div>
                    <ImagesUploder
                      disabled={isUploading === "uploading"}
                      height="100px"
                      maxCount={1}
                      seesionId={sessionID}
                      multiple
                      sessionKey="bg_image_ar"
                      setIsUploading={setIsUploading}
                      setSessionId={(value) =>
                        dispatch(
                          GamesSliceActions.AddPageSetSessionIdAction({
                            resource,
                            value: value,
                          })
                        )
                      }
                      onSuccess={() => {
                        setBackgroundImageARFlag(true);
                      }}
                    />
                  </Col>
                  <Col
                    span={7}
                    className={styles.imageUplodar}
                    style={{ background: primaryColor ?? "" }}
                  >
                    <div className={styles.imageTitle}>Arabic Won Image</div>
                    <ImagesUploder
                      disabled={isUploading === "uploading"}
                      height="100px"
                      maxCount={1}
                      multiple
                      seesionId={sessionID}
                      sessionKey="win_image_ar"
                      setIsUploading={setIsUploading}
                      setSessionId={(value) =>
                        dispatch(
                          GamesSliceActions.AddPageSetSessionIdAction({
                            resource,
                            value: value,
                          })
                        )
                      }
                      onSuccess={() => {
                        setWonImageARFlag(true);
                      }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col className={styles.col} xs={14}>
                <Form.Item
                  noStyle
                  // shouldUpdate={(prevValues, currValues) => {
                  //   return prevValues.timeSwitch !== currValues.timeSwitch;
                  // }}
                >
                  {/* {({ getFieldValue, setFieldValue }) => {
                    return ( */}
                  <CustomDateRangeInput
                    rules={[
                      {
                        // required: !getFieldValue("timeSwitch") === false,
                        required: true,
                        message: "please enter range date",
                      },
                    ]}
                    name={"date"}
                    // showTime={false}
                    // disabeld={getFieldValue("timeSwitch") === false}
                  />
                  {/* );
                  }} */}
                </Form.Item>
              </Col>
              {/* <Col className={styles.col} xs={5}>
                <Space>
                  <Divider type="vertical" />
                  <div className={styles.switchtxt}>Time Expiration</div>
                  <Form.Item
                    name={"timeSwitch"}
                    style={{ marginBottom: 0 }}
                    valuePropName="checked"
                    initialValue={false}
                  >
                    <Switch />
                  </Form.Item>
                </Space>
              </Col> */}
              <Col className={styles.col} xs={5}>
                <Space>
                  <Divider type="vertical" />
                  <div className={styles.switchtxt}>Quantity Expiration</div>
                  <Form.Item
                    name={"qtySwitch"}
                    style={{ marginBottom: 0 }}
                    valuePropName="checked"
                    initialValue={0}
                  >
                    <Switch />
                  </Form.Item>
                </Space>
              </Col>
              <Col className={styles.col} xs={24}>
                <Form.Item
                  name={"winnersLink"}
                  label={<div className="label">Winners Link</div>}
                  // required
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "please enter winners facebook link",
                  //   },
                  // ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24}>
                <GiftsSwiper
                  maxCount={50}
                  addability={isUploading !== "uploading"}
                  sessionId={sessionID}
                  setSessionId={(id: string) => {
                    dispatch(
                      GamesSliceActions.AddPageSetSessionIdAction({
                        resource,
                        value: id,
                      })
                    );
                  }}
                  areEditable={isUploading !== "uploading"}
                  gifts={addedGifts}
                  AddGiftFunction={(gift: I_Gift) => {
                    dispatch(
                      GamesSliceActions.AddPageAddGiftAction({
                        resource,
                        value: gift,
                      })
                    );
                  }}
                  EditGiftFunction={(gift: I_Gift) => {
                    dispatch(
                      GamesSliceActions.EditGiftAction({
                        resource,
                        value: gift,
                      })
                    );
                  }}
                  DeleteGiftFunction={(id: string) => {
                    dispatch(
                      GamesSliceActions.DeleteGiftAction({
                        resource,
                        value: id,
                      })
                    );
                  }}
                />
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  );
}

export default ScratchTicketAddPage;
