import { Route } from "react-router-dom";
import AllUsersPage from "../pages/AllUsers/AllUsersPage";
import UserAddPage from "../pages/AddUser/UserAddPage";
import UserEditPage from "../pages/EditUser/UserEditPage";
import { AppAuthorizedRoutes } from "router/middlewares/AppAuthorizationRoutes";

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  <Route path="user-management">
    <Route index element={<AllUsersPage />} />
    <Route
      element={
        <AppAuthorizedRoutes
          features={["USER_MANAGEMENT"]}
          //FIXME - add permission key
          permissions={["edit_users"]}
        />
      }
    >
      <Route path=":id" element={<UserEditPage />} />
    </Route>
    <Route
      element={
        <AppAuthorizedRoutes
          features={["USER_MANAGEMENT"]}
          //FIXME - add permission key
          permissions={["add_users"]}
        />
      }
    >
      <Route path="add" element={<UserAddPage />} />
    </Route>
  </Route>,
];
