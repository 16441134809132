import { message } from "antd";
import {
  useAppDispatch,
  useAppSelector,
} from "features/common/hooks/useReduxHooks";
import { request } from "libs/axios";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { UsersSliceActions } from "../redux/slices/usersSlice";

export interface mutateFunctionInterface {
  data: FormData;
  token: string | null;
}

const mutateFunction = async ({ data, token }: mutateFunctionInterface) => {
  return request({
    url: "/auth/console-users",
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const useAddConsoleUserApi = () => {
  const { currAppToken } = useAppSelector((state) => state.appsAuth);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  return useMutation(
    "users/add",
    (data: FormData) => {
      return mutateFunction({ data, token: currAppToken });
    },
    {
      onSuccess: async (response) => {
        dispatch(UsersSliceActions.ResetAdd());
        navigate("/user-management");
        message.success(response.data.message);
      },
    }
  );
};
