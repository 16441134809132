import { Button, Col, Row, Tag } from "antd";
import { ColumnsType } from "antd/es/table";
import Filter from "features/common/components/Filter/filter";
import {
  useAppDispatch,
  useAppSelector,
} from "features/common/hooks/useReduxHooks";
import { useSearchDelay } from "features/common/hooks/useSearchDelay";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ShopsSliceActions } from "features/shops/redux/slices/shopsSlice";
import { SorterInterface } from "features/common/interfaces/SorterInterface.d";
import { FilterTypes } from "features/common/types/FilterTypes";
import SearchInput from "features/common/components/Inputs/SearchInput/SearchInput";
import Sorter from "features/common/components/Sorter/Sorter";
import CustomTable from "features/common/components/Tables/CustomTable";
import styles from "./styles.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTh, faTrash } from "@fortawesome/free-solid-svg-icons";
import DeletePopConfirm from "features/common/components/PopConfirms/DeletePopConfirm/DeletePopConfirm";
import { useFetchAllGamesApi } from "features/games/apis/useFetchAllGamesApi";
import { gamesTypes } from "features/games/types/gamesTypes";
import { useDeleteGameApi } from "features/games/apis/useDeleteGameApi";
import { GamesSliceActions } from "features/games/redux/sllice/gamesSlice";
import SingleLocalSelectInput from "features/common/components/Forms/formItems/selectInputs/SingleLocalSelectInput";
import { GameResponse } from "features/common/interfaces/apis/responses/games/GameResponse.d";
import ComponentGuard from "features/common/components/ComponentGuard/ComponentGuard";

const NumberGuessIndexPage = () => {
  const resource: gamesTypes = "number_guess";
  const {
    SetFilterAciton,
    SetPageAction,
    SetPerPageAction,
    SetSearchActoin,
    SetSorterAction,
  } = GamesSliceActions;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchContent, setSearchTerm] = useSearchDelay();
  const [dataSource, setDataSource] = useState<any>();
  const {
    filters,
    sorter,
    search,
    pagnation: { page, perPage },
  } = useAppSelector((state) => state.games[resource].indexPage);
  const { data, isLoading, isRefetching } = useFetchAllGamesApi(resource);
  const { mutateAsync } = useDeleteGameApi(resource);
  useEffect(() => {
    if (data) {
      setDataSource(
        data?.map((game) => {
          return {
            id: game.id,
            game_id: game.game_id,
            title: game.title,
            shop: game.shop.name,
            expires: game.expires_at,
            status: `${game.status}`,
          };
        })
      );
    }
  }, [data]);
  useEffect(() => {
    if (searchContent !== undefined) {
      dispatch(SetSearchActoin({ resource, value: searchContent }));
      dispatch(SetPageAction({ resource, value: 1 }));
    }
  }, [searchContent]);

  const setSorter = useCallback(
    (payload: SorterInterface) => {
      dispatch(SetSorterAction({ resource, value: payload }));
    },
    [dispatch]
  );
  const setFilters = useCallback(
    (payload: FilterTypes | null) => {
      dispatch(SetFilterAciton({ resource, value: payload }));
      dispatch(SetPageAction({ resource, value: 1 }));
    },
    [dispatch]
  );
  const setPerPage = useCallback(
    (payload: number) =>
      dispatch(SetPerPageAction({ resource, value: payload })),
    [dispatch]
  );
  const setPage = useCallback(
    (payload: number) => dispatch(SetPageAction({ resource, value: payload })),
    [dispatch]
  );
  const columns: ColumnsType<GameResponse> = [
    {
      title: "",
      dataIndex: "game_id",
      align: "center",
      width: 50,
      render(value, record, index) {
        return <h4>{`#` + record.game_id}</h4>;
      },
    },
    {
      title: "Title",
      dataIndex: "title",
      align: "center",
      // width: 130,
    },
    {
      title: "Shop",
      dataIndex: "shop",
      align: "center",
    },
    {
      title: "Expires At",
      dataIndex: "expires",
      align: "center",
    },
    {
      title: "Status",
      align: "center",
      render(value, record, index) {
        console.log(record.status);
        return record.status === ("true" as any) ? (
          <Tag color="green"> Active</Tag>
        ) : (
          <Tag color="red">inActive</Tag>
        );
      },
    },
    {
      title: "Actions",
      align: "center",
      width: 150,
      render(value, record, index) {
        return (
          <>
            <ComponentGuard permission="edit_number_guesses">
              <Button
                onClick={() => {
                  navigate(`./${record.id}`);
                }}
                type="text"
                icon={<FontAwesomeIcon icon={faEdit} />}
              />
            </ComponentGuard>
            <ComponentGuard permission="delete_number_guesses">
              <DeletePopConfirm
                onSubmit={() => {
                  return mutateAsync(record.id);
                }}
              >
                <Button
                  type="text"
                  danger
                  icon={<FontAwesomeIcon icon={faTrash} />}
                />
              </DeletePopConfirm>
            </ComponentGuard>
            <Button
              onClick={() => {
                navigate(`./${record.id}/statistics`);
              }}
              type="text"
              icon={<FontAwesomeIcon icon={faTh} />}
            />
          </>
        );
      },
    },
  ];
  return (
    <>
      <Row className={styles.row} gutter={[8, 8]}>
        <Col span={24}>
          <div className={styles.maintitle}>Number Guess</div>
        </Col>
        <ComponentGuard permission="add_number_guesses">
          <Col xs={12} md={8} lg={4} xl={3}>
            <Button
              block
              shape="round"
              type={"primary"}
              className={styles.addButton}
              onClick={() => {
                navigate("./add");
              }}
            >
              Add New Game
            </Button>
          </Col>
        </ComponentGuard>
        <Col
          className={styles.searchCol}
          xs={{
            span: 24,
            order: 10,
          }}
          md={{
            span: 8,
            order: 0,
          }}
          lg={6}
        >
          <SearchInput
            isLoading={false}
            setSearchTerm={setSearchTerm}
            defaultValue={search}
          />
        </Col>
        <Col className={styles.col}>
          <Sorter
            sortersName={["title", "created_at"]}
            sorter={sorter}
            setSorter={setSorter}
          />
        </Col>
        <Col style={{ paddingLeft: "5px" }}>
          <Filter filters={filters} setFilters={setFilters}>
            <SingleLocalSelectInput
              name={"status"}
              data={["active", "non-active"]}
            />
          </Filter>
        </Col>
      </Row>
      <div>
        <CustomTable
          bordered
          scroll={{ y: 470, x: 800 }}
          isLoading={isLoading}
          isRefetching={isRefetching}
          meta={[]}
          page={page}
          perPage={perPage}
          name="shops"
          data={dataSource}
          columns={columns}
          SetPage={setPage}
          SetPerPage={setPerPage}
        />
      </div>
    </>
  );
};

export default NumberGuessIndexPage;
