// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { EffectCoverflow } from "swiper";
import styles from "./styels.module.scss";
import { Col, Row } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import GiftCard from "../GiftCard/GiftCard";
import { I_Gift } from "features/games/interfaces/I.Gift";
import { useState } from "react";
import UpsertGiftModal from "../AddGiftModals/AddGiftModalLocal";
import { useAppSelector } from "features/common/hooks/useReduxHooks";
import { gamesTypes } from "features/games/types/gamesTypes";
import AddGiftModalLocal from "../AddGiftModals/AddGiftModalLocal";

interface GiftsSwiperProps {
  gifts: I_Gift[] | [];
  sessionId: string | null;
  setSessionId: any;
  areEditable: boolean;
  addability: boolean;
  AddGiftFunction?: any;
  DeleteGiftFunction?: any;
  EditGiftFunction?: any;
  maxCount?: number;
}
const GiftsSwiper = ({
  addability,
  areEditable,
  gifts,
  AddGiftFunction,
  DeleteGiftFunction,
  EditGiftFunction,
  sessionId,
  setSessionId,
  maxCount = Infinity,
}: GiftsSwiperProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { primaryColor } = useAppSelector((state) => state.ui);

  return (
    <>
      <AddGiftModalLocal
        giftsLength={gifts.length}
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        sessionId={sessionId}
        setSessionId={setSessionId}
        AddGiftFunction={AddGiftFunction}
      />
      <Row className={styles.GiftsSwiper}>
        <Col span={24}>
          <div className={styles.giftsTitle}>Gifts</div>
        </Col>
        {addability && gifts.length < maxCount && (
          <Col span={4}>
            <div
              className={styles.addGiftFrame}
              style={{ borderColor: primaryColor ?? "" }}
              onClick={() => {
                setIsModalOpen(true);
              }}
            >
              <FontAwesomeIcon
                className={styles.plusIcon}
                icon={faPlus}
                style={{ color: primaryColor ?? "" }}
              />
            </div>
          </Col>
        )}
        <Col
          span={addability ? (gifts.length >= 4 ? 20 : gifts.length * 5) : 24}
        >
          <Swiper
            effect={"coverflow"}
            grabCursor={true}
            initialSlide={gifts.length}
            centeredSlides={true}
            slidesPerView={"auto"}
            coverflowEffect={{
              rotate: 17,
              depth: 200,
              stretch: 0,
              modifier: 1,
              slideShadows: true,
            }}
            modules={[EffectCoverflow]}
          >
            {gifts.map((gift) => {
              return (
                <SwiperSlide>
                  <GiftCard
                    isEditable={areEditable}
                    gift={gift}
                    DeleteGiftFunction={DeleteGiftFunction}
                    EditGiftFunction={EditGiftFunction}
                    sessionId={sessionId}
                    setSessionId={setSessionId}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </Col>
      </Row>
    </>
  );
};

export default GiftsSwiper;
