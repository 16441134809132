import { Col, Row } from "antd";
import DashboardCard from "features/games/components/DashboardCard/DashboardCard";
import styles from "./styles.module.scss";
import { useGamesDashboardApi } from "features/games/apis/useGamesDashboardApi";
import { GamesDashboardResponse } from "features/common/interfaces/apis/responses/games/DashboardResponse.d";
import HeaderLoading from "features/common/components/Loading/HeaderLoading/Loading";
const GamesDashboardPage = () => {
  const { data, isLoading, isRefetching } = useGamesDashboardApi();
  return (
    <>
      <div className={styles.container}>
        <Row className={styles.row} gutter={[8, 8]}>
          <Col span={24}>
            <div className={styles.maintitle}>Games Dashboard</div>
          </Col>
        </Row>
        <HeaderLoading top={-40} loading={isLoading || isRefetching} />
        <Row gutter={[40, 20]} className={styles.tet} justify={'center'}>
          {data?.data &&
            Object.keys(data.data).map((game: string) => {
              if (data.data[game as keyof GamesDashboardResponse]) {
                return (
                  <Col span={12}>
                    <DashboardCard
                      title={game}
                      statistics={
                        data.data[game as keyof GamesDashboardResponse]
                      }
                    />
                  </Col>
                );
              }
              return <></>;
            })}
        </Row>
      </div>
    </>
  );
};

export default GamesDashboardPage;
