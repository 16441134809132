import { AES } from "crypto-js";
import { IAppsAuthSliceInitState } from "../interfaces/I.AppsAuthSliceIinitState";
import { IUserAuthSliceInitState } from "../interfaces/I.UserAuthSliceIinitState";

export const useEncrypData = () => {
  const setEncryptData = (
    data:
      | IUserAuthSliceInitState
      | (IAppsAuthSliceInitState & { color: string })
  ) => {
    try {
      const cipherText = AES.encrypt(
        JSON.stringify(data),
        process.env.REACT_APP_SECRET_CODE as string
      );
      return cipherText.toString();
    } catch {
      return false;
    }
  };
  return setEncryptData;
};
