import { Modal } from "antd";
import { ColumnsType } from "antd/es/table";
import CustomTable from "features/common/components/Tables/CustomTable";
import {
  useAppDispatch,
  useAppSelector,
} from "features/common/hooks/useReduxHooks";
import { useVisitorAttemptsApi } from "features/games/apis/useVisitorAttemptsApi";
import { GamesSliceActions } from "features/games/redux/sllice/gamesSlice";
import { gamesTypes } from "features/games/types/gamesTypes";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styles from "./styles.module.scss";
interface ModalContentProps {
  resource: gamesTypes;
  record: number;
}
function ModalContent({ resource, record }: ModalContentProps) {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { SetUserAttemptsPageAction, SetUserAttemptsPerPageAction } =
    GamesSliceActions;
  const [dataSource, setDataSource] = useState<any>([]);
  const {
    pagnation: { page, perPage },
  } = useAppSelector(
    (state) => state.games[resource].statisticsPage.visitors.userAttempts
  );
  const { data, isLoading, isRefetching } = useVisitorAttemptsApi(
    id ? +id : 0,
    record,
    resource
  );
  useEffect(() => {
    if (data) {
      setDataSource(
        data?.data?.map((attempt) => {
          return {
            id: attempt.id,
            result: attempt.result,
            status: attempt.status,
            gift_name: attempt.gift?.name,
            game_id: `#${attempt.game.id}`,
            qr_code: attempt.qr_code,
            date_time: attempt.created_at,
            code: attempt.code_front  ,
            visitor_data: attempt.visitor.information,
          };
        })
      );
    }
  }, [data]);
  const setPerPage = useCallback(
    (payload: number) =>
      dispatch(
        SetUserAttemptsPerPageAction({
          resource,
          value: payload,
        })
      ),
    [dispatch]
  );
  const setPage = useCallback(
    (payload: number) =>
      dispatch(SetUserAttemptsPageAction({ resource, value: payload })),
    [dispatch]
  );
  const columns: ColumnsType<any> = [
    {
      title: "Result",
      dataIndex: "result",
      align: "center",
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
    },
    {
      title: "Game id",
      dataIndex: "game_id",
      align: "center",
    },
    {
      title: "Gift name",
      dataIndex: "gift_name",
      align: "center",
    },
    {
      title: "QR Code",
      dataIndex: "qr_code",
      align: "center",
    },
    {
      title: "Date time",
      dataIndex: "date_time",
      align: "center",
    },
    {
      title: "Code",
      dataIndex: "code",
      align: "center",
    },
    {
      title: "Visitor Name",
      dataIndex: "visitor_data",
      align: "center",
      render(value) {
        const visitorData = JSON.parse(value);
        return visitorData?.name;
      },
    },
  ];
  return (
    <CustomTable
      SetPage={setPage}
      SetPerPage={setPerPage}
      columns={columns}
      data={dataSource}
      isLoading={isLoading}
      isRefetching={isRefetching}
      meta={[]}
      page={page}
      perPage={perPage}
      bordered
    />
  );
}
interface VisitorAttemptsModalProps {
  resource: gamesTypes;
  openRecord: number;
  setOpenRecord: any;
}
const VisitorAttemptsModal = ({
  resource,
  openRecord,
  setOpenRecord,
}: VisitorAttemptsModalProps) => {
  return (
    <Modal
      footer={null}
      open={!!openRecord}
      centered
      width={900}
      style={{ maxWidth: "95%" }}
      onCancel={() => setOpenRecord(0)}
    >
      <ModalContent resource={resource} record={openRecord} />
    </Modal>
  );
};
export default VisitorAttemptsModal;
