import { Col, Row } from "antd";
import styles from "./styles.module.scss";
interface StatisticsCardProps {
  statistics?: {
    number_of_users?: number;
    number_of_attempts?: number;
    number_of_win_attempts?: number;
    number_of_win_users?: number;
  };
}
const StatisticsCard = ({ statistics }: StatisticsCardProps) => {
  return (
    <Row gutter={20}>
      <Col span={6}>
        <div className={styles.card}>
          <div className={styles.title}>Number Of Users</div>
          <div className={styles.value}>{statistics?.number_of_users ?? 0}</div>
        </div>
      </Col>
      <Col span={6}>
        <div className={styles.card}>
          <div className={styles.title}>Number Of Attempts</div>
          <div className={styles.value}>
            {statistics?.number_of_attempts ?? 0}
          </div>
        </div>
      </Col>
      <Col span={6}>
        <div className={styles.card}>
          <div className={styles.title}>Number Of Win Attempts</div>
          <div className={styles.value}>
            {statistics?.number_of_win_attempts ?? 0}
          </div>
        </div>
      </Col>
      <Col span={6}>
        <div className={styles.card}>
          <div className={styles.title}>Number Of Win Users</div>
          <div className={styles.value}>
            {statistics?.number_of_win_users ?? 0}
          </div>
        </div>
      </Col>
    </Row>
  );
};
export default StatisticsCard;
