import { Button, Col, Image, Row, Space } from "antd";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import {
  useAppDispatch,
  useAppSelector,
} from "features/common/hooks/useReduxHooks";
import CustomButton from "features/common/components/Buttons/CustomButton/CustomButton";
import { AppleFilled } from "@ant-design/icons";
import { useEnterApp } from "features/auth/apis/useEnterApp";
import { AppsAuthSliceActions } from "features/auth/redux/slice/appsAuthSlice";
import { IAppInfo } from "features/auth/interfaces/I.AppInfo";
import { UserAuthSliceActions } from "features/auth/redux/slice/userAuthSlice";
import { UiSliceActions } from "features/common/redux/slices/uiSlice";
import AppButton from "features/common/components/Buttons/AppButton/AppButton";
import { useNavigate } from "react-router-dom";
import { useEncrypData } from "features/auth/hooks/useEncryptData";
import { useCookies } from "react-cookie";
function AppsPage() {
  const dispatch = useAppDispatch();
  const { apps, rememberMe } = useAppSelector((state) => state.userAuth);
  const { mutateAsync, isLoading } = useEnterApp();
  const navigate = useNavigate();
  const setEncryptAppData = useEncrypData();
  const [cookie, setCookie, removeCookie] = useCookies();
  const onAppClick = (app: IAppInfo) => {
    mutateAsync({ id: app.id }).then((response) => {
      const currApp = {
        currAppId: app.id,
        currAppIcon: app.logo,
        currAppName: app.name,
        currAppToken: response.data?.token,
        currAppFeatures: app.sub_features,
      };
      const dataEncrypted = setEncryptAppData({
        ...currApp,
        color: app.color
      });
      if (dataEncrypted) {
        sessionStorage.setItem("app", dataEncrypted);
        if (rememberMe) {
          setCookie("app", dataEncrypted, {
            path: "/",
            maxAge: 1000 * 60 * 60 * 24 * 30, // One Month
          });
        }
      }
      dispatch(
        AppsAuthSliceActions.SetCurrentAppAction({
          ...currApp,
        })
      );
      dispatch(UiSliceActions.SetPrimaryColor(app.color));
      navigate("/games/scratch-ticket");
    });
  };
  const { primaryColor } = useAppSelector((state) => state.ui);

  return (
    <>
      <Row className={styles.container} 
              style={{
                background: primaryColor ?? "#6c9bcf",
              }}
      >
        <Col span={18} className={styles.apps}>
          <div className={styles.title}>Apps that you have access to</div>
          <div className={styles.subtitle}>
            choose app to enter the management console
          </div>
          <Space
            direction="vertical"
            className={styles.appsButtons}
            size={100 / apps.length}
          >
            {apps?.map((app) => {
              return (
                <AppButton
                  loading={isLoading}
                  id={app.id as string}
                  icon={app.logo as string}
                  name={app.name as string}
                  primaryColor={app.color}
                  size="large"
                  type="ghost"
                  block
                  onClick={() => {
                    onAppClick(app);
                  }}
                />
              );
            })}
          </Space>
        </Col>
      </Row>
    </>
  );
}

export default AppsPage;
