import { IAppFeatures } from "features/auth/interfaces/I.AppFeatures";
import { useAppDispatch, useAppSelector } from "features/common/hooks/useReduxHooks";
import { UiSliceActions } from "features/common/redux/slices/uiSlice";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";


interface ControlThemeProviderProps {
  children: any;
}
/**
 * @description this provider is to dispatch action on location change
 * to set the current feature background image as the theme
 */
const ControlThemeProvider = ({ children }: ControlThemeProviderProps) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { currAppFeatures } = useAppSelector((state) => state.appsAuth);
  useEffect(() => {
    let feature: IAppFeatures | null = null;
    // check the matching feature in the location route
    const matchingFeatPath = [
      ...location.pathname
        .split("/")
        .map((l) => l.toLowerCase().replaceAll("-", "_")),
    ].filter((item) =>
      currAppFeatures.map((feat) => feat.key.toLowerCase()).includes(item)
    );
    if (matchingFeatPath.length > 0) {
      // get the current route feature object
      const currentFeature = currAppFeatures.filter(
        (feat) => feat.key === matchingFeatPath[0]?.toUpperCase()
      );
      if (currentFeature.length > 0) {
        feature = currentFeature[0];
      }
    }
    dispatch(UiSliceActions.SetThemeBackground(feature?.image ?? ""));
  }, [location]);
  return <>{children}</>;
};

export default ControlThemeProvider;
