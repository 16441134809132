import { Button, Form, Input, InputNumber } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { NamePath } from "antd/es/form/interface";
import { FormItemProps, FormListProps } from "antd/es/form";
interface ContactsListProps extends FormItemProps {
  name: NamePath;
  placeholder?: string;
  disabled?: boolean;
}
/**
 * @description a form item with email input that have email  rgx for warning user if enter invaild email
 * @param {string}  name
 * @param {string}  initialValue
 * @param {string}  placeholder
 * @param {boolean}  disabled
 * @param {FormItemProps} restProps  - the rest form item interface props
 */
function ContactsFormList({
  name = "contacts",
  disabled = false,
  placeholder,
}: ContactsListProps) {
  return (
    <Form.List name={name} initialValue={[null]}>
      {(fields, { add, remove }, { errors }) => (
        <>
          {fields.map((field, index) => (
            <Form.Item
              label={index === 0 ? "Contacts" : ""}
              // required={true}
              key={field.key}
            >
              <Form.Item
                {...field}
                validateTrigger={["onChange", "onBlur"]}
                rules={[
                  // {
                  //   required: true,
                  //   message: "Please input contacts or delete this field.",
                  // },
                  {
                    pattern: /^[0-9\+]+$/g,
                    message: "This contact is invalid",
                  },
                ]}
                noStyle
              >
                <InputNumber
                  placeholder={placeholder}
                  disabled={disabled}
                  controls={false}
                  type="number"
                  style={
                    fields.length === 1 ? { width: "100%" } : { width: "95%" }
                  }
                />
              </Form.Item>
              {fields.length > 1 ? (
                <Button
                  onClick={() => remove(field.name)}
                  danger
                  type="link"
                  icon={
                    <MinusCircleOutlined className="dynamic-delete-button" />
                  }
                ></Button>
              ) : null}
            </Form.Item>
          ))}
          {fields.length < 1 && (
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              />
              <Form.ErrorList errors={errors} />
            </Form.Item>
          )}
        </>
      )}
    </Form.List>
  );
}

export default ContactsFormList;
