import { useAppSelector } from "features/common/hooks/useReduxHooks";
import { ShopResponse } from "features/common/interfaces/apis/responses/shops/ShopResponse.d";
import { request } from "libs/axios";
import { useQuery } from "react-query";



export const useFetchAllShopsApi = () => {
  const {
    search,
    sorter,
    filters,
    pagnation: { page, perPage },
  } = useAppSelector((state) => state.shops);
  const { authToken } = useAppSelector((state) => state.userAuth);

  return useQuery(
    [`getAllShops`, page, perPage, filters, sorter, search],
    () => {
      return request<ShopResponse[]>({
        url: "/shops",
        method: "GET",
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        params: {
          filter: {
          ...filters,
          search: search,
          },
          sort: sorter.name ? sorter.type + sorter.name : null,
          items_per_page: perPage,
          page: page,
        },
      });
    },
    {
      select: (data) => {
        console.log(data?.data);

        return data?.data?.data;
      },
    }
  );
};
