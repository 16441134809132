import { Form, FormItemProps, Input } from "antd";

interface EnglishInputProps extends FormItemProps {
  name: string;
  initialValue?: string;
  placeholder?: string;
  disabled?: boolean;
}
/**
 * @description a form item with text input that have arabic langauge rgx for warning user if enter another langauge
 * @param {string}  name
 * @param {string}  initialValue
 * @param {string}  placeholder
 * @param {boolean}  disabled
 * @param {FormItemProps} restProps  - the rest form item interface props
 */
const ArabicInput = ({
  name,
  initialValue,
  placeholder,
  disabled = false,
  ...restProps
}: EnglishInputProps) => {
  return (
    <>
      <Form.Item
        label={<div className="label">Arabic Name</div>}
        name={name}
        initialValue={initialValue}
        rules={[
          {
            required: true,
            message: "Enter Arabic name please",
            type: "string",
          },
          {
            pattern: /^[\u0621-\u064A0-9`~!@#$%^&*()\-=_+[\]{}|;:'",.<>/?\\ ]*$/g,
            message: "Enter Arabic name please",
            warningOnly: true,
          },
          { whitespace: true, message: "No whitespaces in the name" },
          { min: 1, max: 100, message: "Name must be between 1 & 100" },
        ]}
        {...restProps}
      >
        <Input
          // style={{ margin: 5 }}
          placeholder={placeholder}
          disabled={disabled}
        />
      </Form.Item>
    </>
  );
};

export default ArabicInput;
