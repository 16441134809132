import CustomErrorBoundary from "features/common/errors/CustomErrorBoundary/CustomErrorBoundary";
import CustomSuspense from "features/common/layouts/CustomSuspence/CustomSuspence";
import MainLayout from "features/common/layouts/MainLayout/MainLayout";
import { Route, Routes } from "react-router-dom";
import AuthRoutes from "features/auth/routes/AuthRoutes";
import { UserAuthenticatedRoutes } from "./middlewares/UserAuthenticatedRoutes";
import { AppAuthenticatedRoutes } from "./middlewares/AppAuthenticatedRoutes";
import FourOFourPage from "features/common/errors/404/404";
import ShopsRoutes from "features/shops/routes/ShopsRoutes";
import { AppAuthorizedRoutes } from "./middlewares/AppAuthorizationRoutes";
import GamesRoutes from "features/games/routes/GamesRoutes";
import { ErrorRoutes } from "./middlewares/ErrorRoutes";
import UsersRoutes from "features/users/routes/UsersRoutes";

/**
 * @description The Main Router
 */
function Router() {
  return (
    <CustomErrorBoundary>
      <CustomSuspense>
        <Routes>
          {AuthRoutes}
          <Route element={<UserAuthenticatedRoutes />}>
            <Route element={<AppAuthenticatedRoutes />}>
              <Route path="/" element={<MainLayout />}>
                <Route element={<ErrorRoutes />}>
                  <Route
                    element={
                      <AppAuthorizedRoutes
                        features={["USER_MANAGEMENT"]}
                        //FIXME - add permission key
                        permissions={["view_users"]}
                      />
                    }
                  >
                    {UsersRoutes}
                  </Route>
                  <Route
                    element={
                      <AppAuthorizedRoutes
                        features={["SHOP_MANAGEMENT"]}
                        permissions={["view_shops"]}
                      />
                    }
                  >
                    {ShopsRoutes}
                  </Route>
                  {GamesRoutes}
                </Route>
              </Route>
            </Route>
          </Route>
          <Route path="*" element={<FourOFourPage />} />
        </Routes>
      </CustomSuspense>
    </CustomErrorBoundary>
  );
}

export default Router;
