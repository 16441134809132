import { DatePicker as Date, Form, FormItemProps } from "antd";
import { NamePath } from "antd/es/form/interface";

const { RangePicker } = Date;

interface DateRangeInputProps extends FormItemProps {
  initialValue?: [any, any];
  showTime?: boolean;
  disabeld?: boolean;
  name: NamePath;
}
/**
 * @description a form item with RangePicker date component
 * @param {DateRangeInputProps} DateRangeInputProps
 */
const CustomDateRangeInput = ({
  name,
  showTime = true,
  disabeld = false,
  initialValue,
  ...props
}: DateRangeInputProps) => {
  return (
    <Form.Item
      name={name}
      label={<div className="label">Range Date</div>}
      initialValue={initialValue}
      {...props}
    >
      <RangePicker
        disabled={disabeld}
        style={{ width: "100%" }}
        allowClear
        defaultValue={initialValue}
        hideDisabledOptions={true}
        // showTime={true}
        showTime={showTime ? { format: "HH:mm" } : false}
        format={showTime ? "YYYY-MM-DD HH:mm" : "YYYY-MM-DD"}
      />
    </Form.Item>
  );
};

export default CustomDateRangeInput;
