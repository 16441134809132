import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { Button, Col, Radio, Row } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLeftLong } from "@fortawesome/free-solid-svg-icons";
import {
  useAppDispatch,
  useAppSelector,
} from "features/common/hooks/useReduxHooks";
import { useLocation, useNavigate } from "react-router-dom";
import { UsersSliceActions } from "features/users/redux/slices/usersSlice";
import EditConsoleUserSection from "features/users/components/Sections/EditConsoleUser/EditConsoleUserSection";
import EditApiUserSection from "features/users/components/Sections/EditApiUser/EditApiUserSection";
import { IUser } from "features/users/interfaces/I.User";

function UserEditPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [type, setType] = useState<IUser["type"]>(
    state?.type ?? sessionStorage.getItem("account_type")
  );
  const { ResetEdit } = UsersSliceActions;
  const { primaryColor } = useAppSelector((state) => state.ui);
  onbeforeunload = (e) => {
    sessionStorage.setItem("account_type", type);
  };
  useEffect(() => {
    return () => {
      sessionStorage.removeItem("account_type");
      dispatch(ResetEdit());
    };
  }, []);

  return (
    <>
      <Row>
        <Col span={24}>
          <Row>
            <Col span={1} className={styles.col}>
              <Button
                onClick={() => {
                  navigate("/user-management");
                }}
                type="text"
                icon={
                  <FontAwesomeIcon
                    icon={faLeftLong}
                    style={{ color: primaryColor ?? "", fontSize: 25 }}
                  />
                }
              />
            </Col>
            <Col span={19}>
              <div className={styles.title}>
                Users Management - <span className={styles.txt}>Edit</span>
              </div>
            </Col>
            <Col span={24}>
              <Radio.Group
                optionType="button"
                buttonStyle="solid"
                style={{ display: "flex", justifyContent: "center" }}
                value={type}
              >
                <Radio
                  value={"Console User"}
                  style={{ padding: "10px 20px", height: "auto" }}
                  disabled={type !== "Console User"}
                >
                  Console User
                </Radio>
                <Radio
                  value={"API User"}
                  style={{ padding: "10px 20px", height: "auto" }}
                  disabled={type !== "API User"}
                >
                  Api User
                </Radio>
              </Radio.Group>
            </Col>
          </Row>
        </Col>
        {type === "Console User" ? (
          <EditConsoleUserSection />
        ) : type === "API User" ? (
          <EditApiUserSection />
        ) : null}
      </Row>
    </>
  );
}

export default UserEditPage;
