import React from "react";
import styles from "./styles.module.scss";
import { Button, Space } from "antd";

/**
 *
 * the content inside the login page
 */
function LoginContent() {
  return (
    <>
      <div className={styles.title}>Login</div>
      <div className={styles.text}>Log into your popsicle console account</div>
      <div className={styles.qustion}>Are you a client ?</div>
      <div className={styles.text}>
        this site is for the admins to manage your great exp,
      </div>
      <div className={styles.text2}>
        Head off to one of our amazing apps to access our features :
      </div>
      <div className={styles.apps}>
        <Space.Compact className={styles.compact}>
          <Button
            block
            type="primary"
            size="large"
            href="https://play.google.com/store/apps/details?id=com.innovation.dinari"
            target="_blank"
            rel="noreferrer"
          >
            Dinari
          </Button>
        </Space.Compact>
      </div>
    </>
  );
}

export default LoginContent;
