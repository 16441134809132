import React, { ReactElement, useState } from "react";
import { Popconfirm, PopconfirmProps, message } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { UseMutateAsyncFunction } from "react-query";
import { AxiosResponse } from "axios";

interface DeletePopConfirmProps extends Omit<PopconfirmProps, "title"> {
  title?: string;
  onSubmit: () => Promise<any>;
  //   onSubmit: Promise<any>;
  children: ReactElement;
}

const DeletePopConfirm: React.FC<DeletePopConfirmProps> = ({
  title = "Warning",
  children,
  onSubmit,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const handleOk = () => {
    setConfirmLoading(true);
    if (onSubmit instanceof Promise) {
      onSubmit().then(() => {
        setOpen(false);
        setConfirmLoading(false);
      });
    } else {
      onSubmit();
      setOpen(false);
      setConfirmLoading(false);
    }
  };
  const handleCancel = () => {
    setOpen(false);
  };
  const wrappedChildren = React.cloneElement(children, {
    onClick: () => {
      setOpen(true);
    },
  });

  return (
    <Popconfirm
      title={title}
      description="Are You sure you want to delete this item"
      open={open}
      onConfirm={handleOk}
      okButtonProps={{ loading: confirmLoading, danger: true }}
      onCancel={handleCancel}
      icon={<QuestionCircleOutlined style={{ color: "red" }} />}
      {...props}
    >
      {wrappedChildren}
    </Popconfirm>
  );
};

export default DeletePopConfirm;
