import { Col, Form, Input, InputNumber, message, Modal, Row } from "antd";
import { useForm } from "antd/es/form/Form";
import { useEffect, useState } from "react";
import ImagesUploder from "features/common/components/Upload/UploadWithSession/ImagesUploader/ImagesUplader";
import { UploadFileStatus } from "antd/es/upload/interface";
import EnglishInput from "features/common/components/Forms/formItems/stringInputs/EnglishInput";
import ArabicInput from "features/common/components/Forms/formItems/stringInputs/ArabicInput";

import SingleLocalSelectInput from "features/common/components/Forms/formItems/selectInputs/SingleLocalSelectInput";
import FilesUploder from "features/common/components/Upload/UploadWithSession/FileUploader/FileUplader";
import Papa from "papaparse";
import { I_Gift } from "features/games/interfaces/I.Gift";
import { GiftFormInterface } from "features/games/interfaces/forms/GiftForm.d";
import TranslateImageUploader from "features/common/components/Upload/UploadWithSession/TranslateImageUploader/TranslateImageUploader";
import ImageGiftListUploder from "../ImageGiftListUploder/ImageGiftListUploder";

type EditGiftModalLocalProps = {
  gift: I_Gift;
  isOpen: boolean;
  setIsOpen: any;
  sessionId: string | null;
  setSessionId: any;
  EditGiftFunction: any;
};
const EditGiftModalLocal = ({
  gift,
  isOpen,
  setIsOpen,
  sessionId,
  setSessionId,
  EditGiftFunction,
}: EditGiftModalLocalProps) => {
  const [form] = useForm();
  const [uploadedImageUrl, setUploadedImageUrl] = useState<any>(gift.img);
  const [imageSessionKey, setImageSessionKey] = useState<any>(
    gift.imageSessionKey
  );
  const [uploadFlagForCSV, setuploadFlagForCSV] = useState<boolean>(
    !!gift.fileSessionKey
  );
  const [uploadFlagFornewCSV, setuploadFlagFornewCSV] =
    useState<boolean>(false);
  const [requireCSV, setRequireCSV] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<UploadFileStatus>();
  const onAddItems = (values: GiftFormInterface) => {
    console.log(values);
    console.log(uploadedImageUrl);
    if (
      uploadedImageUrl[uploadedImageUrl.length - 1].ar === null ||
      uploadedImageUrl[uploadedImageUrl.length - 1].en === null
    ) {
      message.error("you have to add all images to this gift");
      return;
    }
    EditGiftFunction({
      id: gift.id,
      title_en: values.ENname,
      title_ar: values.ARname,
      description_en: values.ENdescription,
      description_ar: values.ARdescription,
      chance: values.chance,
      type: values.type,
      img: uploadedImageUrl ?? gift.img,
      quantity: values.quantity,
      remains: values.quantity,
      imageSessionKey: imageSessionKey ?? gift.imageSessionKey,
      fileSessionKey: gift.fileSessionKey,
    });
    setIsOpen(false);
    form.resetFields();
  };
  const onCancel = () => {
    setIsOpen(false);
    form.resetFields();
  };

  return (
    <>
      <Modal
        closable={false}
        bodyStyle={{ maxHeight: 600, overflowY: "scroll", padding: 10 }}
        destroyOnClose
        title="Create New Gift"
        open={isOpen}
        okText="Save"
        onOk={() => {
          form.submit();
        }}
        okButtonProps={{
          disabled:
            isUploading === "uploading" ||
            (requireCSV === true && uploadFlagForCSV === false),
          type: "text",
          htmlType: "submit",
        }}
        cancelButtonProps={{
          disabled: isUploading === "uploading",
          danger: true,
          type: "text",
          onClick: onCancel,
        }}
        width={800}
      >
        <Form
          id={"addItem"}
          labelCol={{ span: 24 }}
          autoComplete="off"
          form={form}
          onFinish={onAddItems}
          initialValues={{
            ENname: gift.title_en,
            ARname: gift.title_ar,
            ENdescription: gift.description_en,
            ARdescription: gift.description_ar,
            type: gift.type,
            chance: gift.chance,
            quantity: gift.quantity,
          }}
        >
          <Row gutter={16}>
            <Col span={24}>
              <ImageGiftListUploder
                seesionId={sessionId}
                setImagesUploaded={setUploadedImageUrl}
                setImageSessionKey={setImageSessionKey}
                setSessionId={setSessionId}
                setIsUploading={setIsUploading}
                isUploading={isUploading}
                imagesUploaded={gift.img}
                imageSessionKey={gift.imageSessionKey}
                giftsLength={+gift.id - 1}
              />
            </Col>
            <Col span={12}>
              <EnglishInput
                name={"ENname"}
                label={<div className="label">En - Name</div>}
              />
            </Col>
            <Col span={12}>
              <ArabicInput
                name={"ARname"}
                label={<div className="label">Ar - Name</div>}
              />
            </Col>
            <Col span={12}>
              <EnglishInput
                name={"ENdescription"}
                label={<div className="label">En - Description</div>}
              />
            </Col>
            <Col span={12}>
              <ArabicInput
                name={"ARdescription"}
                label={<div className="label">Ar - Description</div>}
              />
            </Col>
            <Col span={8}>
              <SingleLocalSelectInput
                disabled={uploadFlagForCSV === true}
                name={"type"}
                data={["CODES", "MANUAL"]}
                label={<div className="label">Type</div>}
                initialValue="MANUAL"
              />
            </Col>
            <Col span={8}>
              <Form.Item
                label={<div className="label">Chance</div>}
                name={"chance"}
                rules={[
                  {
                    required: true,
                    message: "Enter chance of winning please",
                  },
                ]}
              >
                <InputNumber
                  addonAfter="%"
                  max={100}
                  min={1}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => {
                  if (prevValues.type !== currentValues.type) {
                    form.setFieldValue("quantity", undefined);
                  }
                  return prevValues.type !== currentValues.type;
                }}
              >
                {({ getFieldValue, setFieldValue }) => {
                  return (
                    <Form.Item
                      label={<div className="label">Quantity</div>}
                      name={"quantity"}
                      rules={[
                        {
                          required: true,
                          message: "Enter gift quantity please",
                        },
                      ]}
                    >
                      <InputNumber
                        disabled={getFieldValue("type") === "CODES"}
                        max={1000}
                        min={1}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => {
                  if (currentValues.type === "CODES") {
                    setRequireCSV(true);
                  } else {
                    setRequireCSV(false);
                  }
                  return prevValues.type !== currentValues.type;
                }}
              >
                {({ getFieldValue, setFieldValue }) => {
                  return (
                    <FilesUploder
                      disabeld={
                        getFieldValue("type") === "MANUAL" ||
                        isUploading === "uploading"
                      }
                      accept=".csv"
                      editable
                      maxCount={1}
                      seesionId={sessionId}
                      filesUploaded={
                        uploadFlagForCSV && !uploadFlagFornewCSV
                          ? ["file"]
                          : undefined
                      }
                      sessionKey={gift.fileSessionKey}
                      setSessionId={setSessionId}
                      url="/upload-files"
                      setIsUploading={setIsUploading}
                      label={"CSV file"}
                      onSuccess={(file) => {
                        Papa.parse(file as any, {
                          header: true,
                          skipEmptyLines: true,
                          complete: function (results) {
                            console.log(results);
                            setuploadFlagForCSV(true);
                            setuploadFlagFornewCSV(true);
                            form.setFieldValue("quantity", results.data.length);
                          },
                        });
                      }}
                      onDelete={() => {
                        setuploadFlagForCSV(false);
                        form.setFieldValue("quantity", undefined);
                      }}
                    />
                  );
                }}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default EditGiftModalLocal;
