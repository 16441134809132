import { Form, FormItemProps, Input } from "antd";
import { NamePath } from "antd/es/form/interface";

interface EmailInputProps extends FormItemProps {
  name: NamePath;
  initialValue?: string;
  placeholder?: string;
  disabled?: boolean;
}
/**
 * @description a form item with email input that have email  rgx for warning user if enter invaild email
 * @param {string}  name
 * @param {string}  initialValue
 * @param {string}  placeholder
 * @param {boolean}  disabled
 * @param {FormItemProps} restProps  - the rest form item interface props
 */
const EmailInput = ({
  name,
  initialValue,
  placeholder,
  disabled = false,
  ...restProps
}: EmailInputProps) => {
  return (
    <>
      <Form.Item
        initialValue={initialValue}
        name={name}
        rules={[
          {
            required: true,
            message: `please enter your email`,
          },
          {
            type: "email",
            message: `please enter valid email`,
          },
        ]}
        {...restProps}
      >
        <Input type="email" placeholder={placeholder} disabled={disabled} />
      </Form.Item>
    </>
  );
};
export default EmailInput;
