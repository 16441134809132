import { faFileExcel, faLeftLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Popover,
  Radio,
  Row,
  Space,
  Spin,
} from "antd";
import { ColumnsType } from "antd/es/table";
import Link from "antd/es/typography/Link";
import DownloadButton from "features/common/components/Buttons/Download/DownloadButton";
import SearchInput from "features/common/components/Inputs/SearchInput/SearchInput";
import CustomTable from "features/common/components/Tables/CustomTable";
import {
  useAppDispatch,
  useAppSelector,
} from "features/common/hooks/useReduxHooks";
import { useSearchDelay } from "features/common/hooks/useSearchDelay";
import StatisticsCard from "features/games/components/StatisticsCard/StatisticsCard";
import { GamesSliceActions } from "features/games/redux/sllice/gamesSlice";
import { gamesTypes } from "features/games/types/gamesTypes";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./styles.module.scss";
import { useVisitorsApi } from "features/games/apis/useVisitorsApi";
import { useAttemptsApi } from "features/games/apis/useAttemptsApi";
import VisitorAttemptsModal from "features/games/components/VisitorAttemptsModal/VisitorAttemptsModal";
import UserInfoModal from "features/games/components/UserInfoModal/UserInfoModal";
import dayjs from "dayjs";
import { useForm } from "antd/es/form/Form";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { useUpdateCodeCell } from "features/games/apis/useUpdateCodeCell";
interface GameStatisticsProps {
  resource: gamesTypes;
}
const GameStatistics = ({ resource }: GameStatisticsProps) => {
  const { id } = useParams();
  const {
    SetStatisticsPageAction,
    SetStatisticsSearchActoin,
    SetStatisticsPerPageAction,
    ChangeStatisticsTable,
  } = GamesSliceActions;
  const [searchContent, setSearchTerm] = useSearchDelay();
  const [visitorsDataSource, setVisitorsDataSource] = useState<any>();
  const [attemptsDataSource, setAttemptsDataSource] = useState<any>();
  const [editCodeCell, setEditCodeCell] = useState(null);
  const [openRecord, setOpenRecord] = useState<number>(0);
  const [userRecord, setUserRecord] = useState<any>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { primaryColor } = useAppSelector((state) => state.ui);
  const { table } = useAppSelector(
    (state) => state.games[resource].statisticsPage
  );
  const {
    search,
    pagnation: { page, perPage },
  } = useAppSelector((state) => state.games[resource].statisticsPage[table]);
  const {
    data: visitorsData,
    isLoading: visitorsIsLoading,
    isRefetching: visitorsIsRefetching,
  } = useVisitorsApi(id ? +id : 0, resource, table);
  const {
    data: attemptsData,
    isLoading: attemptsIsLoading,
    isRefetching: attemptsIsRefetching,
  } = useAttemptsApi(id ? +id : 0, resource, table);
  useEffect(() => {
    if (attemptsData) {
      setAttemptsDataSource(
        attemptsData?.data?.map((attempt) => {
          return {
            key: attempt.id,
            id: attempt.id,
            result: attempt.result,
            status: attempt.status,
            gift_name: attempt.gift?.name,
            qr_code: attempt.qr_code,
            date_time: attempt.created_at,
            code: attempt.code_front,
            visitor_data: attempt.visitor.information,
          };
        })
      );
    }
  }, [attemptsData]);
  useEffect(() => {
    if (visitorsData) {
      setVisitorsDataSource(
        visitorsData?.data?.map((statistic) => {
          const info = JSON.parse(statistic.information);
          return {
            id: statistic.id,
            name: info.name ?? null,
            phone_number: info.phone_number,
            birth_date: info.birth_date,
            total_attempts: statistic.total_attempts,
            win_attempts: statistic.win_attempts,
            facebook_link: info.facebook_link,
          };
        })
      );
    }
  }, [visitorsData]);
  useEffect(() => {
    if (searchContent !== undefined) {
      dispatch(
        SetStatisticsSearchActoin({
          resource,
          value: { table, value: searchContent },
        })
      );
      dispatch(
        SetStatisticsPageAction({ resource, value: { table, value: 1 } })
      );
    }
  }, [searchContent]);
  const setPerPage = useCallback(
    (payload: number) =>
      dispatch(
        SetStatisticsPerPageAction({
          resource,
          value: { table, value: payload },
        })
      ),
    [dispatch]
  );
  const setPage = useCallback(
    (payload: number) =>
      dispatch(
        SetStatisticsPageAction({ resource, value: { table, value: payload } })
      ),
    [dispatch]
  );
  const changeTable = useCallback(
    (payload: "attempts" | "visitors") => {
      dispatch(ChangeStatisticsTable({ resource, value: payload }));
      dispatch(
        SetStatisticsSearchActoin({
          resource,
          value: { table, value: "" },
        })
      );
      dispatch(
        SetStatisticsPageAction({ resource, value: { table, value: 1 } })
      );
    },
    [dispatch]
  );
  const visitorsColumns: ColumnsType<any> = [
    {
      title: "Name",
      dataIndex: "name",
      align: "center",
    },
    {
      title: "Phone Number",
      dataIndex: "phone_number",
      align: "center",
    },
    {
      title: "Birth Date",
      dataIndex: "birth_date",
      align: "center",
    },
    {
      title: "Facebook account",
      dataIndex: "facebook_link",
      align: "center",
      render(value) {
        return value ? (
          <div>
            <Link href={value}>Open</Link> OR{" "}
            <Link
              onClick={() => {
                navigator.clipboard.writeText(value).then(() => {
                  message.info("link copied to clipboard");
                });
              }}
            >
              Copy
            </Link>
          </div>
        ) : (
          "-"
        );
      },
    },
    {
      title: "Win Attempts",
      dataIndex: "win_attempts",
      align: "center",
    },
    {
      title: "Total Attempts",
      dataIndex: "total_attempts",
      align: "center",
      render(value, record) {
        return (
          <Button type="link" onClick={() => setOpenRecord(record.id)}>
            {value}
          </Button>
        );
      },
    },
  ];
  const [form] = useForm();
  const { mutateAsync, isLoading } = useUpdateCodeCell(id ? +id : 0);
  const onFinish = (values: any) => {
    const formData = new FormData();
    formData.append("code", values.code);
    mutateAsync(formData).then(() => {
      form.setFieldValue("code", values.code);
      setEditCodeCell(null);
    });
  };
  const attemptsColumns: ColumnsType<any> = [
    {
      title: "Result",
      dataIndex: "result",
      align: "center",
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
    },
    {
      title: "Gift name",
      dataIndex: "gift_name",
      align: "center",
    },
    {
      title: "QR Code",
      dataIndex: "qr_code",
      align: "center",
    },
    {
      title: "Date time",
      dataIndex: "date_time",
      align: "center",
      render(value) {
        return dayjs(value).format("DD-MM-YYYY");
      },
    },
    {
      title: "Code",
      dataIndex: "code",
      align: "center",
      width: 250,
      onCell(record) {
        return {
          style: {
            cursor: "pointer",
          },
          onClick: () => {
            if (editCodeCell !== record.key) {
              setEditCodeCell(record.key);
              form.setFieldValue("code", record.code);
            }
          },
          // onBlur: () => {
          //   console.log(record.key);
          //   setEditCodeCell(null);
          //   form.setFieldValue("code", undefined);
          // },
        };
      },
      render(value, record, index) {
        if (editCodeCell === record.key && record.code) {
          return (
            <Spin spinning={isLoading}>
              <div
                className={styles.flex}
                // onBlur={() => {
                //   console.log(record.key);
                //   setEditCodeCell(null);
                //   form.setFieldValue("code", undefined);
                // }}
              >
                <Form.Item
                  name={"code"}
                  rules={[
                    {
                      required: true,
                      message: "this field required",
                    },
                  ]}
                >
                  <Input autoFocus />
                </Form.Item>
                <div>
                  <CloseCircleOutlined
                    className={styles.x}
                    onClick={() => {
                      setEditCodeCell(null);
                      form.setFieldValue("code", undefined);
                    }}
                  />
                  <CheckCircleOutlined
                    className={styles.icon}
                    onClick={() => {
                      form.submit();
                    }}
                  />
                </div>
              </div>
            </Spin>
          );
        }
        return (
          <span
            style={{
              cursor: "pointer",
            }}
          >
            {record.code}
          </span>
        );
      },
    },
    {
      title: "Visitor Data",
      dataIndex: "visitor_data",
      align: "center",
      render(value) {
        const user = JSON.parse(value);
        return (
          <>
            <Button type="link" onClick={() => setUserRecord(user)}>
              {user?.name}
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Row className={styles.row} gutter={[8, 8]}>
        <Col span={2}>
          <Button
            onClick={() => {
              navigate("/games/scratch-ticket");
            }}
            type="text"
            icon={
              <FontAwesomeIcon
                icon={faLeftLong}
                style={{ color: primaryColor ?? "", fontSize: 25 }}
              />
            }
          />
        </Col>
        <Col span={22}>
          <div className={styles.maintitle}>Scratch Ticket</div>
        </Col>
        <Col span={24}>
          <StatisticsCard
            statistics={visitorsData?.other ?? attemptsData?.other}
          />
        </Col>
        <Col span={24}>
          <Radio.Group
            optionType="button"
            buttonStyle="solid"
            style={{ display: "flex", justifyContent: "center" }}
            value={table}
            onChange={(e) => {
              changeTable(e.target.value);
            }}
          >
            <Radio
              value={"visitors"}
              style={{ padding: "10px 20px", height: "auto" }}
            >
              Visitors Table
            </Radio>
            <Radio
              value={"attempts"}
              style={{ padding: "10px 20px", height: "auto" }}
            >
              Attempts Table
            </Radio>
          </Radio.Group>
        </Col>
        <Col className={styles.searchCol} xs={17} md={10} lg={8}>
          <SearchInput
            isLoading={false}
            setSearchTerm={setSearchTerm}
            defaultValue={search}
          />
        </Col>
        <Col>
          <DownloadButton
            icon={<FontAwesomeIcon icon={faFileExcel} />}
            url={((): string => {
              const base =
                resource === "scratch_ticket"
                  ? `scratch-tickets`
                  : resource === "scratch_grid"
                  ? `scratch-grids`
                  : resource === "number_guess"
                  ? `number-guesses`
                  : null;
              const tableurl =
                table === "attempts"
                  ? "export-game-attempts"
                  : table === "visitors"
                  ? "export-statistics"
                  : null;
              return base && table ? `/${base}/${id}/${tableurl}` : "";
            })()}
          >
            Export to Excel
          </DownloadButton>
        </Col>
      </Row>
      <div>
        <Form form={form} onFinish={onFinish}>
          <CustomTable
            bordered
            scroll={{ y: 470, x: 800 }}
            isLoading={attemptsIsLoading || visitorsIsLoading}
            isRefetching={attemptsIsRefetching || visitorsIsRefetching}
            meta={[]}
            page={page}
            perPage={perPage}
            name="statistics"
            data={
              table === "attempts"
                ? attemptsDataSource
                : table === "visitors"
                ? visitorsDataSource
                : []
            }
            columns={
              table === "attempts"
                ? attemptsColumns
                : table === "visitors"
                ? visitorsColumns
                : []
            }
            SetPage={setPage}
            SetPerPage={setPerPage}
          />
        </Form>
        <VisitorAttemptsModal
          resource={resource}
          openRecord={openRecord}
          setOpenRecord={setOpenRecord}
        />
        <UserInfoModal userRecord={userRecord} setUserRecord={setUserRecord} />
      </div>
    </>
  );
};

export default GameStatistics;
