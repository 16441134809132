import { Button, Col, Row } from "antd";
import { ColumnsType } from "antd/es/table";
import {
  useAppDispatch,
  useAppSelector,
} from "features/common/hooks/useReduxHooks";
import { useSearchDelay } from "features/common/hooks/useSearchDelay";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import SearchInput from "features/common/components/Inputs/SearchInput/SearchInput";
import CustomTable from "features/common/components/Tables/CustomTable";
import styles from "./styles.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import DeletePopConfirm from "features/common/components/PopConfirms/DeletePopConfirm/DeletePopConfirm";
import { IUser } from "features/users/interfaces/I.User";
import { UsersSliceActions } from "features/users/redux/slices/usersSlice";
import { useFetchAllUsersApi } from "features/users/apis/useFetchAllUsersApi";
import { useDeleteUserApi } from "features/users/apis/useDeleteUserApi";
import ComponentGuard from "features/common/components/ComponentGuard/ComponentGuard";

const AllUsersPage = () => {
  const { PageAction, PerPageAction, SearchAction } = UsersSliceActions;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchContent, setSearchTerm] = useSearchDelay();
  const [dataSource, setDataSource] = useState<any>();
  const {
    search,
    pagnation: { page, perPage },
  } = useAppSelector((state) => state.users);
  const { data, isLoading, isRefetching } = useFetchAllUsersApi();
  const { mutateAsync } = useDeleteUserApi();
  useEffect(() => {
    if (data) {
      setDataSource(
        data?.map((object) => {
          return {
            id: object.id,
            name: object.name,
            email: object.email ?? "-",
            type: object.account_type,
          };
        })
      );
    }
  }, [data]);
  useEffect(() => {
    if (searchContent !== undefined) {
      dispatch(SearchAction(searchContent));
      dispatch(PageAction(1));
    }
  }, [searchContent]);

  const setPerPage = useCallback(
    (payload: number) => dispatch(PerPageAction(payload)),
    [dispatch]
  );
  const setPage = useCallback(
    (payload: number) => dispatch(PageAction(payload)),
    [dispatch]
  );
  const columns: ColumnsType<IUser> = [
    {
      title: "Name",
      dataIndex: "name",
      align: "center",
    },
    {
      title: "Email",
      dataIndex: "email",
      align: "center",
    },
    {
      title: "Account Type",
      dataIndex: "type",
      align: "center",
    },
    {
      title: "Actions",
      align: "center",
      width: 100,
      render(value, record, index) {
        return (
          <>
            <ComponentGuard permission="edit_users">
              <Button
                onClick={() => {
                  navigate(`./${record?.id}`, {
                    state: { type: record?.type },
                  });
                }}
                type="text"
                icon={<FontAwesomeIcon icon={faEdit} />}
              />
            </ComponentGuard>
            <ComponentGuard permission="delete_users">
              <DeletePopConfirm
                onSubmit={() => {
                  return mutateAsync(record.id);
                }}
              >
                <Button
                  type="text"
                  danger
                  icon={<FontAwesomeIcon icon={faTrash} />}
                />
              </DeletePopConfirm>
            </ComponentGuard>
          </>
        );
      },
    },
  ];
  return (
    <>
      <Row className={styles.row} gutter={[8, 8]}>
        <Col span={24}>
          <div className={styles.maintitle}>{t("Users Management")}</div>
        </Col>
        <ComponentGuard permission="add_users">
          <Col xs={12} md={8} lg={4} xl={3}>
            <Button
              block
              shape="round"
              type={"primary"}
              className={styles.addButton}
              onClick={() => {
                navigate(`./add`);
              }}
            >
              Add User
            </Button>
          </Col>
        </ComponentGuard>
        <Col
          className={styles.searchCol}
          xs={{
            span: 24,
            order: 10,
          }}
          md={{
            span: 8,
            order: 0,
          }}
          lg={6}
        >
          <SearchInput
            isLoading={false}
            setSearchTerm={setSearchTerm}
            defaultValue={search}
          />
        </Col>
      </Row>
      <div>
        <CustomTable
          bordered
          scroll={{ y: 470, x: 800 }}
          isLoading={isLoading}
          isRefetching={isRefetching}
          meta={[]}
          page={page}
          perPage={perPage}
          name="users"
          data={dataSource}
          columns={columns}
          SetPage={setPage}
          SetPerPage={setPerPage}
        />
      </div>
    </>
  );
};

export default AllUsersPage;
