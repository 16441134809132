import { Button, Form, Popover, Row } from "antd";
import { useForm } from "antd/es/form/Form";
import { ReactNode, memo, useState } from "react";
import styles from "./styles.module.scss";
import { FilterTypes } from "features/common/types/FilterTypes";
import { ReactComponent as FilterICon } from "assets/svgs/filter_icon.svg";

type filtersProps = {
  filters: FilterTypes | null | undefined;
  setFilters: any;
  children: ReactNode;
  selectionMode?: boolean;
};
/**
 * @description a Popover component that used to add filters params to existing api by wrrap
 *  a form items inside it so that
 * when we click submit we set the filter state which passed form params so that the
 * query api refetched with the filters that we set
 * @param {FilterTypes | null | undefined} filters - redux state from a slice
 * @param {any} setFilters - dispacher function
 * @param {ReactNode} children - the form items
 * @param {boolean} selectionMode - to disable the popover if it true
 */
const Filter = ({ filters, setFilters, children }: filtersProps) => {
  const [open, setOpen] = useState(false);
  const [form] = useForm();

  /**
   * function to reset the state and the form fields
   */
  const reset = () => {
    form.resetFields();
    setFilters(null);
    setOpen(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    let tempFilters = { ...filters };
    form.setFieldsValue(tempFilters);
    setOpen(newOpen);
  };
  const onFinish = (values: any) => {
    let filter: FilterTypes = values;
    setFilters(filter);
    setOpen(false);
  };
  /**
   * the passed children used here
   */
  let FilterContent = () => {
    return (
      <>
        <Form form={form} onFinish={onFinish}>
          {children}
            <Row>
              <Button type="default" htmlType="submit" onClick={reset} danger>
                Reset
              </Button>
              <Button
                htmlType="submit"
                type="primary"
                style={{ marginLeft: "auto" }}
              >
                OK
              </Button>
            </Row>
        </Form>
      </>
    );
  };
  return (
    <>
      <Popover
        title="Filters"
        content={<FilterContent />}
        trigger="click"
        open={open}
        onOpenChange={handleOpenChange}
        placement="bottomRight"
      >
        <FilterICon
          className={`${styles.filter_icon} ${
            (open || filters) && styles.active
          } `}
        />
      </Popover>
    </>
  );
};

export default memo(Filter);
