import { useAppSelector } from "features/common/hooks/useReduxHooks";
import { request } from "libs/axios";
import { useQuery } from "react-query";
import { GameResponse } from "../../common/interfaces/apis/responses/games/GameResponse.d";
import { gamesTypes } from "../types/gamesTypes";

export const useFetchAllGamesApi = (resource: gamesTypes) => {
  const {
    pagnation: { page, perPage },
    search,
    sorter,
    filters,
  } = useAppSelector((state) => state.games[resource].indexPage);
  const { currAppToken } = useAppSelector((state) => state.appsAuth);

  return useQuery(
    [`getAll${resource}Games`, page, perPage, filters, sorter, search],
    () => {
      return request<GameResponse[]>({
        url:
          resource === "scratch_ticket"
            ? "/scratch-tickets"
            : resource === "scratch_grid"
            ? "/scratch-grids"
            : resource === "number_guess"
            ? "/number-guesses"
            : "",
        method: "GET",
        headers: {
          Authorization: `Bearer ${currAppToken}`,
        },
        params: {
          filter: {
            ...filters,
            search: search,
          },
          sort: sorter.name ? sorter.type + sorter.name : null,
          items_per_page: perPage,
          page: page,
        },
      });
    },
    {
      select: (data) => {
        console.log(data?.data);

        return data?.data?.data;
      },
    }
  );
};
