import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { UserAuthSliceActions } from "features/auth/redux/slice/userAuthSlice";
import { AppsAuthSliceActions } from "features/auth/redux/slice/appsAuthSlice";
import { I_InitialStateGamesSlice } from "features/games/interfaces/I.InitialStateGamesSlice";
import {
  AddGameAddGiftAction,
  AddGameDeleteGiftAction,
  AddGameEditGiftAction,
  AddGameSetSessionAction,
  EditGameAddGiftAction,
  EditGameSetSessionAction,
  GamesFilterAction,
  GamesPageAction,
  GamesPerPageAction,
  GamesSearchAction,
  GamesSorterAction,
  StatisticsPageAction,
  StatisticsPerPageAction,
  StatisticsSearchAction,
  StatisticsTable,
  UserAttemptsPageAction,
  UserAttemptsPerPageAction,
} from "../actions/gamesActions";
import { gamesTypes } from "features/games/types/gamesTypes";
const initialStateForGamesSlice: I_InitialStateGamesSlice = {
  scratch_ticket: {
    indexPage: {
      pagnation: { page: 1, perPage: 10 },
      sorter: { type: "", name: null },
      search: "",
      filters: null,
    },
    addPage: {
      sessionID: null,
      addedGifts: [],
    },
    editPage: {
      sessionID: null,
      addedGifts: [],
    },
    statisticsPage: {
      table: "visitors",
      attempts: {
        pagnation: { page: 1, perPage: 10 },
        search: "",
      },
      visitors: {
        pagnation: { page: 1, perPage: 10 },
        search: "",
        userAttempts: {
          pagnation: { page: 1, perPage: 10 },
        },
      },
    },
  },
  number_guess: {
    indexPage: {
      pagnation: { page: 1, perPage: 10 },
      sorter: { type: "", name: null },
      search: "",
      filters: null,
    },
    addPage: {
      sessionID: null,
      addedGifts: [],
    },
    editPage: {
      sessionID: null,
      addedGifts: [],
    },
    statisticsPage: {
      table: "visitors",
      attempts: {
        pagnation: { page: 1, perPage: 10 },
        search: "",
      },
      visitors: {
        pagnation: { page: 1, perPage: 10 },
        search: "",
        userAttempts: {
          pagnation: { page: 1, perPage: 10 },
        },
      },
    },
  },
  scratch_grid: {
    indexPage: {
      pagnation: { page: 1, perPage: 10 },
      sorter: { type: "", name: null },
      search: "",
      filters: null,
    },
    addPage: {
      sessionID: null,
      addedGifts: [],
    },
    editPage: {
      sessionID: null,
      addedGifts: [],
    },
    statisticsPage: {
      table: "visitors",
      attempts: {
        pagnation: { page: 1, perPage: 10 },
        search: "",
      },
      visitors: {
        pagnation: { page: 1, perPage: 10 },
        search: "",
        userAttempts: {
          pagnation: { page: 1, perPage: 10 },
        },
      },
    },
  },
};

const gamesSlice = createSlice({
  name: "games",
  initialState: initialStateForGamesSlice,
  reducers: {
    Reset: () => ({ ...initialStateForGamesSlice }),
    ResetIndex: (state, action: PayloadAction<gamesTypes>) => {
      state[action.payload].indexPage = {
        ...initialStateForGamesSlice[action.payload].indexPage,
      };
    },
    ResetAdd: (state, action: PayloadAction<gamesTypes>) => {
      state[action.payload].addPage = {
        ...initialStateForGamesSlice[action.payload].addPage,
      };
    },
    ResetEdit: (state, action: PayloadAction<gamesTypes>) => {
      state[action.payload].editPage = {
        ...initialStateForGamesSlice[action.payload].editPage,
      };
    },
    SetPageAction: GamesPageAction,
    SetPerPageAction: GamesPerPageAction,
    SetStatisticsPageAction: StatisticsPageAction,
    SetStatisticsPerPageAction: StatisticsPerPageAction,
    SetUserAttemptsPageAction: UserAttemptsPageAction,
    SetUserAttemptsPerPageAction: UserAttemptsPerPageAction,
    SetSorterAction: GamesSorterAction,
    SetFilterAciton: GamesFilterAction,
    SetSearchActoin: GamesSearchAction,
    SetStatisticsSearchActoin: StatisticsSearchAction,
    ChangeStatisticsTable: StatisticsTable,
    AddPageSetSessionIdAction: AddGameSetSessionAction,
    AddPageAddGiftAction: AddGameAddGiftAction,
    EditPageSetSessionIdAction: EditGameSetSessionAction,
    EditPageAddGiftAction: EditGameAddGiftAction,
    DeleteGiftAction: AddGameDeleteGiftAction,
    EditGiftAction: AddGameEditGiftAction,
  },
  extraReducers(builder) {
    builder.addCase(UserAuthSliceActions.Logout, () => ({
      ...initialStateForGamesSlice,
    }));
    builder.addCase(AppsAuthSliceActions.SetCurrentAppAction, () => ({
      ...initialStateForGamesSlice,
    }));
  },
});

export const GamesSliceReducer = gamesSlice.reducer;
export const GamesSliceActions = gamesSlice.actions;
