import { message } from "antd";
import {
  useAppDispatch,
  useAppSelector,
} from "features/common/hooks/useReduxHooks";
import { request } from "libs/axios";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { UsersSliceActions } from "../redux/slices/usersSlice";

export interface mutationInterface {
  data: FormData;
  id: string | number;
  token: string | null;
}
const mutationFunction = async ({ id, data, token }: mutationInterface) => {
  data.append("_method", "PUT");
  return request({
    url: `/auth/console-users/${id}`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const useUpdateConsoleUserApi = (id: string | number) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { currAppToken } = useAppSelector((state) => state.appsAuth);

  return useMutation(
    "ConsoleUser/Update",
    (data: FormData) => {
      return mutationFunction({ id, data, token: currAppToken });
    },
    {
      onSuccess: async (response) => {
        message.success(response.data.message);
        dispatch(UsersSliceActions.ResetEdit());
        navigate("/user-management");
      },
    }
  );
};
