import { useAppSelector } from "features/common/hooks/useReduxHooks";
import { AllUsersResponse } from "features/common/interfaces/apis/responses/users/AllUsersResponse.d";
import { request } from "libs/axios";
import { useQuery } from "react-query";

export const useFetchAllUsersApi = () => {
  const {
    search,
    pagnation: { page, perPage },
  } = useAppSelector((state) => state.users);
  const { currAppToken } = useAppSelector((state) => state.appsAuth);

  return useQuery(
    [`getAllUsers`, page, perPage, search],
    () => {
      return request<AllUsersResponse[]>({
        url: "/auth/users",
        method: "GET",
        headers: {
          Authorization: `Bearer ${currAppToken}`,
        },
        params: {
          filters: {
            search: search,
          },
          items_per_page: perPage,
          page: page,
        },
      });
    },
    {
      select: (data) => {
        return data?.data.data;
      },
    }
  );
};
