/**
 * @description Errors Types
 */

export const ERR = {
  NOT_FOUND: 404,
  UNAUTHORIZED: 403,
  UNAUTHENTICATED: 401,
  SERVER_ERROR: 500,
  UNPROCESSABLE_CONTENT: 422,
  NETWORK: "ERR_NETWORK",
};
