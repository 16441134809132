import styles from "./styles.module.scss";
import { Layout } from "antd";
import AppsPopover from "features/auth/components/AppsPopover/AppsPopover";
import LogoutConfirm from "features/auth/components/LogoutConfirm/LogoutConfirm";
import { ReactComponent as Logo } from "assets/svgs/popsicle_icon.svg";
const { Header } = Layout;

const CustomHeader = () => {
  return (
    <>
      <Header className={styles.header}>
        <div className={styles.logo}>
          <Logo style={{ height: "80%" }} />
          Popsicle <span className={styles.console}>Console</span>
        </div>
        <AppsPopover />
        <LogoutConfirm />
      </Header>
    </>
  );
};

export default CustomHeader;
