import { useAppSelector } from "features/common/hooks/useReduxHooks";
import { PermissionResponse } from "features/common/interfaces/apis/responses/users/PremissionResponse.d";
import { request } from "libs/axios";
import { useQuery } from "react-query";

export const useFetchConsolePermissionsApi = () => {
  const { currAppToken } = useAppSelector((state) => state.appsAuth);

  return useQuery(
    [`getConsolePermissions`],
    () => {
      return request<any>({
        url: "/auth/console-users/permissions",
        method: "GET",
        headers: {
          Authorization: `Bearer ${currAppToken}`,
        },
      });
    },
    {
      select: (data) => {
        return data?.data.data;
      },
    }
  );
};
