import { useDecryptUserData } from "features/auth/hooks/useDecryptData";
import { useGetAccessWithoutLogin } from "features/auth/hooks/useGetAccessWithoutLogin";

import { useLayoutEffect } from "react";

const AuthProvider = ({ children }: any) => {
  const { isValid, isChecked, decryptedInfo } = useDecryptUserData();
  const mutate = useGetAccessWithoutLogin();

  useLayoutEffect(() => {
    if (isChecked && isValid) {
      mutate(decryptedInfo);
    }
  }, [isChecked]);

  return <>{children}</>;
};

export default AuthProvider;
