import { Button, Checkbox, Form, Input } from "antd";
import styles from "./styels.module.scss";
import { useTranslation } from "react-i18next";
import { useLogin } from "features/auth/apis/useLogin";
import { useAppDispatch } from "features/common/hooks/useReduxHooks";
import { UserAuthSliceActions } from "features/auth/redux/slice/userAuthSlice";
import { useNavigate } from "react-router-dom";
import EmailInput from "features/common/components/Forms/formItems/stringInputs/EmailInput";
import PasswordInput from "features/common/components/Forms/formItems/stringInputs/PasswordInput";

/**
 *
 * The login form with the validation for each field
 */
function LoginForm() {
  const { mutate, isLoading } = useLogin();

  const onFinish = (values: any) => {
    const formData = new FormData();
    console.log("Success:", values);
    formData.append("email", values.email);
    formData.append("password", values.password);
    mutate({ data: formData, rememberMe: values.remember });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <>
      <div className={styles.formContainer}>
        <div className={styles.formtitle}>Enter your credentials</div>
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <EmailInput name={"email"} />
          <PasswordInput name={"password"} />
          <Form.Item name="remember" valuePropName="checked">
            <Checkbox>remember me</Checkbox>
          </Form.Item>
          <Form.Item>
            <Button block type="primary" htmlType="submit" loading={isLoading}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
}

export default LoginForm;
