import { Button, Popconfirm } from "antd";
import styles from "./styles.module.scss";
import { useAppDispatch } from "features/common/hooks/useReduxHooks";
import { UserAuthSliceActions } from "features/auth/redux/slice/userAuthSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { useCookies } from "react-cookie";
import { useLogout } from "features/auth/apis/useLogout";
import { useState } from "react";

/**
 *
 * A popconfirm with the logout logic ( dispatch and api call)
 */
function LogoutConfirm() {
  const text = "Are you sure you want to logout?";
  const description = "click yes to confirm";

  const { mutateAsync, isLoading } = useLogout();
  const [isOpen, setIsOpen] = useState(false);
  const confirm = () => {
    mutateAsync().then(() => {
      setIsOpen(false);
    });
  };
  return (
    <>
      <Popconfirm
        open={isOpen}
        icon={<></>}
        placement="bottomRight"
        title={text}
        description={description}
        onConfirm={confirm}
        okButtonProps={{
          loading: isLoading,
        }}
        okText="Yes"
        cancelText="No"
        onCancel={() => {
          setIsOpen(false);
        }}
      >
        <Button
          className={styles.logout}
          size="large"
          type="primary"
          // shape="round"
          icon={
            <FontAwesomeIcon
              className={styles.logoutICon}
              icon={faRightFromBracket}
            />
          }
          onClick={() => {
            setIsOpen(true);
          }}
        />
      </Popconfirm>
    </>
  );
}

export default LogoutConfirm;
