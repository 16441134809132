import { Col, Row } from "antd";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import LoginContent from "features/auth/components/LoginContent/LoginContent";
import LoginForm from "features/auth/components/LoginForm/LoginForm";
import { useAppSelector } from "features/common/hooks/useReduxHooks";
function LoginPage() {
  const { t } = useTranslation();
  const { primaryColor } = useAppSelector((state) => state.ui);
  return (
    <>
      <Row
        className={styles.container}
        style={{
          background: primaryColor ?? "#6c9bcf",
        }}
      >
        <Col span={18} className={styles.login}>
          <Row>
            <Col xs={24} md={12} lg={16} className={styles.content}>
              <LoginContent />
            </Col>
            <Col xs={24} md={12} lg={8}>
              <LoginForm />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default LoginPage;
