import { lazy } from "react";
import { Route } from "react-router-dom";
import { AppAuthorizedRoutes } from "router/middlewares/AppAuthorizationRoutes";
import GameStatistics from "../pages/GameStatistics/GameStatistics";
import ScratchTicketIndexPage from "../pages/ScratchTicket/index/ScratchTicketIndexPage";
import ScratchTicketAddPage from "../pages/ScratchTicket/add/ScratchTicketAddPage";
import ScratchTicketEditPage from "../pages/ScratchTicket/edit/ScratchTicketEditPage";
import NumberGuessIndexPage from "../pages/NumberGuess/index/NumberGuessIndexPage";
import NumberGuessAddPage from "../pages/NumberGuess/add/NumberGuessAddPage";
import NumberGuessEditPage from "../pages/NumberGuess/edit/NumberGuessEditPage";
import ScratchGridIndexPage from "../pages/ScratchGrid/index/ScratchGridIndexPage";
import ScratchGridAddPage from "../pages/ScratchGrid/add/ScratchGridAddPage";
import ScratchGridEditPage from "../pages/ScratchGrid/edit/ScratchGridEditPage";
import GamesDashboardPage from "../pages/GamesDashboard/GamesDashboardPage";

// const ScratchTicketIndexPage = lazy(
//   () =>
//     import("features/games/pages/ScratchTicket/index/ScratchTicketIndexPage")
// );
// const ScratchTicketAddPage = lazy(
//   () => import("features/games/pages/ScratchTicket/add/ScratchTicketAddPage")
// );
// const ScratchTicketEditPage = lazy(
//   () => import("features/games/pages/ScratchTicket/edit/ScratchTicketEditPage")
// );
// const ScratchTicketStatistics = lazy(
//   () =>
//     import(
//       "features/games/pages/ScratchTicket/statistics/ScratchTicketStatistics"
//     )
// );
// const ScratchGridIndexPage = lazy(
//   () => import("features/games/pages/ScratchGrid/index/ScratchGridIndexPage")
// );
// const ScratchGridAddPage = lazy(
//   () => import("features/games/pages/ScratchGrid/add/ScratchGridAddPage")
// );
// const ScratchGridEditPage = lazy(
//   () => import("features/games/pages/ScratchGrid/edit/ScratchGridEditPage")
// );
// const ScratchGridStatistics = lazy(
//   () =>
//     import("features/games/pages/ScratchGrid/statistics/ScratchGridStatistics")
// );
// const NumberGuessIndexPage = lazy(
//   () => import("features/games/pages/NumberGuess/index/NumberGuessIndexPage")
// );
// const NumberGuessAddPage = lazy(
//   () => import("features/games/pages/NumberGuess/add/NumberGuessAddPage")
// );
// const NumberGuessEditPage = lazy(
//   () => import("features/games/pages/NumberGuess/edit/NumberGuessEditPage")
// );
// const NumberGuessStatistics = lazy(
//   () =>
//     import("features/games/pages/NumberGuess/statistics/NumberGuessStatistics")
// );

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  <Route path="games">
    <Route
      element={
        <AppAuthorizedRoutes
          features={["SCRATCH_TICKET", "SCRATCH_GRID", "NUMBER_GUESS"]}
          permissions={[
            "view_scratch_tickets",
            "view_scratch_grids",
            "view_number_guesses",
          ]}
          method="OR"
        />
      }
    >
      <Route path="dashboard" element={<GamesDashboardPage />} />
    </Route>
    <Route
      element={
        <AppAuthorizedRoutes
          features={["SCRATCH_TICKET"]}
          permissions={["view_scratch_tickets"]}
        />
      }
    >
      <Route path="scratch-ticket">
        <Route index element={<ScratchTicketIndexPage />} />
        <Route
          element={
            <AppAuthorizedRoutes
              features={["SCRATCH_TICKET"]}
              permissions={["add_scratch_tickets"]}
            />
          }
        >
          <Route path="add" element={<ScratchTicketAddPage />} />
        </Route>
        <Route
          element={
            <AppAuthorizedRoutes
              features={["SCRATCH_TICKET"]}
              permissions={["edit_scratch_tickets"]}
            />
          }
        >
          <Route path=":id" element={<ScratchTicketEditPage />} />
        </Route>
        <Route
          path=":id/statistics"
          element={<GameStatistics resource="scratch_ticket" />}
        />
      </Route>
    </Route>
    <Route
      element={
        <AppAuthorizedRoutes
          features={["SCRATCH_GRID"]}
          permissions={["view_scratch_grids"]}
        />
      }
    >
      <Route path="scratch-grid">
        <Route index element={<ScratchGridIndexPage />} />
        <Route
          element={
            <AppAuthorizedRoutes
              features={["SCRATCH_GRID"]}
              permissions={["add_scratch_grids"]}
            />
          }
        >
          <Route path="add" element={<ScratchGridAddPage />} />
        </Route>
        <Route
          element={
            <AppAuthorizedRoutes
              features={["SCRATCH_GRID"]}
              permissions={["edit_scratch_grids"]}
            />
          }
        >
          <Route path=":id" element={<ScratchGridEditPage />} />
        </Route>
        <Route
          path=":id/statistics"
          element={<GameStatistics resource="scratch_grid" />}
        />
      </Route>
    </Route>
    <Route
      element={
        <AppAuthorizedRoutes
          features={["NUMBER_GUESS"]}
          permissions={["view_number_guesses"]}
        />
      }
    >
      <Route path="number-guess">
        <Route index element={<NumberGuessIndexPage />} />
        <Route
          element={
            <AppAuthorizedRoutes
              features={["NUMBER_GUESS"]}
              permissions={["add_number_guesses"]}
            />
          }
        >
          <Route path="add" element={<NumberGuessAddPage />} />
        </Route>
        <Route
          element={
            <AppAuthorizedRoutes
              features={["NUMBER_GUESS"]}
              permissions={["edit_number_guesses"]}
            />
          }
        >
          <Route path=":id" element={<NumberGuessEditPage />} />
        </Route>
        <Route
          path=":id/statistics"
          element={<GameStatistics resource="number_guess" />}
        />
      </Route>
    </Route>
  </Route>,
];
