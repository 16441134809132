import { Button, message, Upload } from "antd";
import ImgCrop from "antd-img-crop";
import { RcFile, UploadFile, UploadProps } from "antd/lib/upload/interface";
import { useState } from "react";
import styles from "./styles.module.scss";
// import { ReactComponent as ImageLogo } from "assets/svg/generalSvgs/image_icon.svg";
import { baseURL } from "config/baseURL";
import { useTranslation } from "react-i18next";
import { ImagesUploderProps } from "features/common/interfaces/ImagesUploaderPropsInterface.d";

/**
 * @description a component that used to upload images to server session
 * using the ant design Upload.Dragger and image crop components and
 * session and setSession state to keep the session id that we uploaded on
 * @param {ImagesUploderProps} params -
 */
const ImagesUploder = ({
  maxCount = Infinity,
  seesionId,
  sessionKey,
  setSessionId,
  imagesUploaded,
  setImagesUploaded,
  multiple = false,
  setIsUploading,
  height = "300px",
  width = "100%",
  withDescription = true,
  disabled = false,
  onSuccess = () => {},
  preview = true,
  aspectSlider = false,
}: ImagesUploderProps) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const { t } = useTranslation();
  // const { token } = useAppSelector((state) => state.auth);
  //   const [imagesUploaded, setImagesUploaded] = useState<string[]>([]);

  const authHeader = {
    Authorization: `Bearer ${"token"}`,
  };

  const beforeUpload = (file: File) => {
    console.log(file.type);
    const isValidIImage =
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/jpeg" ||
      file.type === "image/jfif" ||
      file.type === "image/svg+xml" ||
      file.type === "image/webp";
    if (!isValidIImage) {
      message.error(`${file.name} is not a valid image file`);
    }
    return isValidIImage || Upload.LIST_IGNORE;
  };
  const onChange: UploadProps["onChange"] = async (info) => {
    const { status } = info.file;
    setIsUploading?.(status);
    setFileList(info.fileList);
    if (status === "done") {
      // console.log(info.fileList);
      message.success(`${info.file.response.message}`);
      onSuccess?.(info);
      let src = info.file.url as string;
      if (!src) {
        src = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(info.file.originFileObj as RcFile);
          reader.onload = () => resolve(reader.result as string);
        });
      }
      setImagesUploaded?.(src);
      setSessionId(info.file.response.other.session_id);
    } else if (status === "error") {
      console.log(`${info.file.name} file upload failed.`);
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };
  return (
    <ImgCrop
      rotationSlider
      showReset
      aspectSlider={aspectSlider}
      fillColor="transparent"
    >
      <Upload.Dragger
        accept="image/*"
        disabled={disabled}
        name="image"
        // multiple={multiple}
        maxCount={maxCount}
        action={`${baseURL}/upload-${multiple ? "images" : "image"}`}
        data={
          sessionKey
            ? {
                session_id: seesionId,
                key: sessionKey,
              }
            : {
                session_id: seesionId,
              }
        }
        headers={authHeader}
        listType="picture-card"
        fileList={fileList}
        showUploadList={{
          removeIcon: false,
          showRemoveIcon: false,
        }}
        onChange={onChange}
        onRemove={(info) => {
          console.log(info);
        }}
        onPreview={onPreview}
        beforeUpload={beforeUpload}
        className={styles.uploadContanier}
      >
        <div className={styles.upload} style={{ width, height }}>
          {imagesUploaded && preview ? (
            imagesUploaded.map((image) => {
              return (
                <>
                  <img src={image} className={styles.image} alt="..." />
                  <Button
                    className={styles.uploadButton}
                    disabled={fileList.length >= maxCount}
                    type="primary"
                  >
                    {t("Edit")}
                  </Button>
                </>
              );
            })
          ) : (
            <>
              {withDescription && (
                <>
                  <div className={styles.uploadText}>Drag and drop image</div>
                  <div className={styles.uploadText}>{t("or")}</div>
                </>
              )}
              <Button
                disabled={disabled}
                className={styles.uploadButton}
                // disabled={fileList.length >= maxCount}
                type="primary"
              >
                {t("browse")}
              </Button>
            </>
          )}
        </div>
      </Upload.Dragger>
    </ImgCrop>
  );
};

export default ImagesUploder;
