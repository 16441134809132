import { configureStore } from "@reduxjs/toolkit";
import { appsAuthReducer } from "features/auth/redux/slice/appsAuthSlice";
import { userAuthReducer } from "features/auth/redux/slice/userAuthSlice";
import { uiSliceReducer } from "features/common/redux/slices/uiSlice";
import { GamesSliceReducer } from "features/games/redux/sllice/gamesSlice";
import { ShopsSliceReducer } from "features/shops/redux/slices/shopsSlice";
import { UsersSliceReducer } from "features/users/redux/slices/usersSlice";

/**
 * @description the redux store
 * @namespace store
 */
const store = configureStore({
  reducer: {
    ui: uiSliceReducer,
    userAuth: userAuthReducer,
    appsAuth: appsAuthReducer,
    shops: ShopsSliceReducer,
    games: GamesSliceReducer,
    users: UsersSliceReducer,
  },
});
export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
