import FourOThreePage from "features/common/errors/403/403";
import FourOFourPage from "features/common/errors/404/404";
import FiveOOPage from "features/common/errors/500/500";
import { useAppSelector } from "features/common/hooks/useReduxHooks";
import { Outlet } from "react-router-dom";

/**
 * @description Error handeler middleware
 */

export const ErrorRoutes = () => {
  const { errors } = useAppSelector((state) => state.ui);
  if (errors && errors.navigate) {
    switch (errors.code) {
      case 404:
        return <FourOFourPage />;
      case 403:
        return <FourOThreePage />;
      default:
        return <FiveOOPage />;
    }
  } else {
    return <Outlet />;
  }
};
