import AntDesignConfigProvider from "providers/AntDesignConfigProvider";
import Router from "./router/Router";
import NetworkProvider from "providers/NetworkProvider";
import ReactQueryProvider from "providers/ReactQueryProvider";
import ReduxProvider from "providers/ReduxProvider";
import ControlThemeProvider from "providers/ControlThemeProvider";
import { CookiesProvider } from "react-cookie";
import AuthProvider from "providers/AuthProvider";
// import FirebaseProvider from "providers/FirebasePovider";

/**
 * Main Component Contains all app providers

 */
function Main() {
  return (
    <>
      <ReduxProvider>
        <ReactQueryProvider>
          <CookiesProvider>
            <AntDesignConfigProvider>
              {/* <FirebaseProvider> */}
              <NetworkProvider>
                <AuthProvider>
                  <ControlThemeProvider>
                    <Router />
                  </ControlThemeProvider>
                </AuthProvider>
              </NetworkProvider>
              {/* </FirebaseProvider> */}
            </AntDesignConfigProvider>
          </CookiesProvider>
        </ReactQueryProvider>
      </ReduxProvider>
    </>
  );
}

export default Main;
