import { Route } from "react-router-dom";
import LoginPage from "../pages/LoginPage/LoginPage";
import AppsPage from "../pages/AppsPage/AppsPage";
import { UserUnAuthenticatedRoutes } from "router/middlewares/UserUnAuthenticatedRoutes";
import { UserAuthenticatedRoutes } from "router/middlewares/UserAuthenticatedRoutes";
import { AppUnAuthenticatedRoutes } from "router/middlewares/AppUnAuthenticatedRoutes";
import FourOFourPage from "features/common/errors/404/404";

/**
 * the auth feature routes
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default [
  <Route key={"auth"} path="auth">
    <Route index element={<FourOFourPage />}></Route>
    {/* only the login page we want to prevent user from accees to if have user token */}
    <Route element={<UserUnAuthenticatedRoutes />}>
      <Route key={"auth/login"} path="login" element={<LoginPage />} />
    </Route>
    {/* only the apps page we want to prevent user from accees to if have user token and does not have app token */}
    <Route element={<UserAuthenticatedRoutes />}>
      <Route element={<AppUnAuthenticatedRoutes />}>
        <Route key={"auth/apps"} path="apps" element={<AppsPage />} />
      </Route>
    </Route>
  </Route>,
];
