import { PayloadAction } from "@reduxjs/toolkit";
import { InitialStateUsersSliceInterface } from "features/users/interfaces/InitialStateUsersSliceInterface";
import { WritableDraft } from "immer/dist/internal";

export const setPermissionsAction = (
  state: WritableDraft<InitialStateUsersSliceInterface>,
  action: PayloadAction<{
    0: "add" | "edit";
    1: "console" | "api";
    2: Array<string>;
  }>
) => {
  state[action.payload[0]][action.payload[1]].permissions = action.payload[2];
};
