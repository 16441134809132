import { Badge, Col, Image, Row } from "antd";
import styles from "./styles.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useAppSelector } from "features/common/hooks/useReduxHooks";
import { I_Gift } from "features/games/interfaces/I.Gift";
import DeletePopConfirm from "features/common/components/PopConfirms/DeletePopConfirm/DeletePopConfirm";
import EditGiftModalLocal from "../EditGiftsModals/EditGiftModalLocal";
import { useState } from "react";

interface GiftCardProps {
  isEditable: boolean;
  gift: I_Gift;
  DeleteGiftFunction?: any;
  EditGiftFunction?: any;
  sessionId?: string | null;
  setSessionId?: any;
}
function GiftCard({
  gift,
  isEditable,
  DeleteGiftFunction,
  EditGiftFunction,
  sessionId,
  setSessionId,
}: GiftCardProps) {
  const { primaryColor } = useAppSelector((state) => state.ui);
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <EditGiftModalLocal
        gift={gift}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        EditGiftFunction={EditGiftFunction}
        sessionId={sessionId ?? null}
        setSessionId={setSessionId}
      />
      <div className={styles.card}>
        {isEditable && (
          <>
            <FontAwesomeIcon
              icon={faEdit}
              className={styles.editICon}
              onClick={() => setIsOpen(true)}
            />
            <DeletePopConfirm onSubmit={() => DeleteGiftFunction(gift.id)}>
              <FontAwesomeIcon icon={faTrash} className={styles.deleteICon} />
            </DeletePopConfirm>
          </>
        )}
        <Badge.Ribbon text={gift.type} placement="start">
          <div className={styles.img_box}>
            <div className={styles.giftTitle}>{gift.title_en}</div>
            <div
              className={styles.img_overlay}
              data-count={`+${gift.img.length * 2 - 1}`}
            />
            <img
              className={styles.img}
              src={gift.img[0].en! ?? gift.img[0].ar!}
              alt="..."
            />
          </div>
        </Badge.Ribbon>
        <div
          className={styles.content}
          style={{ background: primaryColor ?? "" }}
        >
          <Row className={styles.txtRow}>
            <Col span={12} className={styles.txt}>
              quantity
            </Col>
            <Col span={12} className={styles.txt}>
              {gift.quantity}
            </Col>
          </Row>
          <Row className={styles.txtRow}>
            <Col span={12} className={styles.txt}>
              remains
            </Col>
            <Col span={12} className={styles.txt}>
              {gift.remains}
            </Col>
          </Row>
          <Row className={styles.txtRow}>
            <Col span={12} className={styles.txt}>
              chance
            </Col>
            <Col span={12} className={styles.txt}>
              {gift.chance}%
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default GiftCard;
