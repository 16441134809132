import { message } from "antd";
import { useAppSelector } from "features/common/hooks/useReduxHooks";
import { request } from "libs/axios";
import { useMutation, useQueryClient } from "react-query";
import { gamesTypes } from "../types/gamesTypes";

export interface RejectVideoInterface {
  token: string | null;
  id: number | string;
  resource: gamesTypes;
}

const mutateFunction = async ({
  id,
  token,
  resource,
}: RejectVideoInterface) => {
  return request({
    url:
      resource === "scratch_ticket"
        ? `/scratch-tickets/${id}`
        : resource === "scratch_grid"
        ? `/scratch-grids/${id}`
        : resource === "number_guess"
        ? `/number-guesses/${id}`
        : "",
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const useDeleteGameApi = (resource: gamesTypes) => {
  const { currAppToken } = useAppSelector((state) => state.appsAuth);
  const queryClient = useQueryClient();

  return useMutation(
    `games/${resource}/delete`,
    (id: string) => {
      return mutateFunction({ id, token: currAppToken, resource });
    },
    {
      onSuccess: async (response) => {
        message.success(response.data.message);
        queryClient.invalidateQueries([`getAll${resource}Games`]);
      },
    }
  );
};
