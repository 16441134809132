import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  pageAction,
  perPageAction,
  searchAction,
} from "features/common/redux/actions/globalActions";
import { UserAuthSliceActions } from "features/auth/redux/slice/userAuthSlice";
import { AppsAuthSliceActions } from "features/auth/redux/slice/appsAuthSlice";
import { InitialStateUsersSliceInterface } from "features/users/interfaces/InitialStateUsersSliceInterface";
import { setPermissionsAction } from "../actions/usersAction";

const initialStateForUsersSlice: InitialStateUsersSliceInterface = {
  pagnation: { page: 1, perPage: 10 },
  search: "",
  add: {
    console: { permissions: [] },
    api: { permissions: [] },
  },
  edit: {
    console: { permissions: [] },
    api: { permissions: [] },
  },
};

const usersSlice = createSlice({
  name: "users",
  initialState: initialStateForUsersSlice,
  reducers: {
    Reset: () => ({ ...initialStateForUsersSlice }),
    ResetIndex: (state) => {
      state.pagnation = { ...initialStateForUsersSlice.pagnation };
      state.search = initialStateForUsersSlice.search;
    },
    ResetAdd: (state) => {
      state.add = { ...initialStateForUsersSlice.add };
    },
    ResetEdit: (state) => {
      state.edit = { ...initialStateForUsersSlice.edit };
    },
    PageAction: pageAction,
    PerPageAction: perPageAction,
    SearchAction: searchAction,
    SetPermissionsAction: setPermissionsAction,
  },
  extraReducers(builder) {
    builder.addCase(UserAuthSliceActions.Logout, () => ({
      ...initialStateForUsersSlice,
    }));
    builder.addCase(AppsAuthSliceActions.SetCurrentAppAction, () => ({
      ...initialStateForUsersSlice,
    }));
  },
});

export const UsersSliceReducer = usersSlice.reducer;
export const UsersSliceActions = usersSlice.actions;
