import { Button, Col, Form, Input, Row, Spin, Upload } from "antd";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./styles.module.scss";
import ImagesUploder from "features/common/components/Upload/UploadWithSession/ImagesUploader/ImagesUplader";
import { useForm } from "antd/es/form/Form";
import Loading from "features/common/components/Loading/PageLoading/Loading";
import HeaderLoading from "features/common/components/Loading/HeaderLoading/Loading";
import { useAppSelector } from "features/common/hooks/useReduxHooks";
import { useErrorHandler } from "features/common/hooks/useErrorHandler";
import { useShowShopApi } from "features/shops/apis/useShowShopApi";
import { useUpdateShopApi } from "features/shops/apis/useUpdateShopApi";
import EnglishInput from "features/common/components/Forms/formItems/stringInputs/EnglishInput";
import ArabicInput from "features/common/components/Forms/formItems/stringInputs/ArabicInput";
import LocationInput from "features/common/components/Forms/formItems/LocationInput/LocationInput";
import ContactsFormList from "features/common/components/Forms/formLists/ContactsFormList/ContactsFormList";
import PasswordInput from "features/common/components/Forms/formItems/stringInputs/PasswordInput";
import EmailInput from "features/common/components/Forms/formItems/stringInputs/EmailInput";
import { ShopFormInterface } from "features/shops/interfaces/forms/ShopForm.d";

const UpdateShopPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [sessionId, setSessionId] = useState<string | null>(null);
  const { id } = useParams();
  const {
    data,
    isFetching: showLoading,
    isRefetching,
  } = useShowShopApi(id ? +id : 0);
  const { mutate, isLoading: updateLoading } = useUpdateShopApi(id ? +id : 0);

  const onFinish = (values: ShopFormInterface) => {
    console.log(values);

    const formData = new FormData();
    formData.append("session_id", sessionId || "");
    // formData.append("user_name", values.OWNname);
    if (values.password) formData.append("user_password", values.password);
    // formData.append("user_email", values.email);
    formData.append("name[en]", values.ENname);
    formData.append("name[ar]", values.ARname);
    formData.append("address[en]", values.AddENname);
    formData.append("address[ar]", values.AddARname);
    formData.append("longitude", `${values.lon ?? null}`);
    formData.append("latitude", `${values.lat ?? null}`);
    formData.append("contacts", JSON.stringify(values.contacts));
    mutate(formData);
  };

  const [form] = useForm();
  useEffect(() => {
    form.resetFields();
  }, [data]);

  return (
    <>
      {showLoading ? (
        <Spin spinning={showLoading} className={"spin"} />
      ) : (
        <>
          <HeaderLoading top={60} loading={isRefetching} />
          <Spin spinning={showLoading || updateLoading}>
            <Row className={styles.pageHeader} gutter={[8, 8]}>
              <Col span={24}>
                <div className={styles.maintitle}>
                  {data?.shop?.name} - Shop
                </div>
              </Col>
              <Col xs={12} sm={8} md={5} lg={3}>
                <Button
                  block
                  shape="round"
                  type={"default"}
                  onClick={() => {
                    navigate("/shop-management");
                  }}
                >
                  Shops
                </Button>
              </Col>
              <Col xs={24} sm={8} md={5} lg={3} style={{ marginLeft: "auto" }}>
                <Button
                  block
                  shape="round"
                  type={"primary"}
                  onClick={() => {
                    form.submit();
                  }}
                >
                  {t("save")}
                </Button>
              </Col>
            </Row>
            <Form
              id={"addItem"}
              autoComplete="off"
              form={form}
              onFinish={onFinish}
              labelCol={{ span: 24 }}
              initialValues={{
                OWNname: data?.user?.name,
                email: data?.user?.email,
                // password: data?.user?.user_password,
                ENname: data?.shop?.en?.name,
                ARname: data?.shop?.ar?.name,
                AddENname: data?.shop?.en?.address,
                AddARname: data?.shop?.ar?.address,
                contacts: data?.shop?.contacts,
              }}
            >
              <Row className={styles.form} justify={"center"} gutter={16}>
                <Col xs={8}>
                  <ImagesUploder
                    maxCount={1}
                    seesionId={sessionId}
                    setSessionId={setSessionId}
                    imagesUploaded={data?.shop?.logo ? [data?.shop?.logo] : []}
                    aspectSlider={true}
                    // height="100px"
                  />
                </Col>
                <Col xs={14} className={styles.formFields}>
                  <Row gutter={16}>
                    <Col span={9}>
                      <EnglishInput
                        label={<div className="label">Owner Name</div>}
                        name="OWNname"
                        disabled
                        rules={[]}
                      />
                    </Col>
                    <Col span={9}>
                      <EmailInput
                        name={"email"}
                        label={<div className="label">Email</div>}
                        disabled
                        rules={[]}
                      />
                    </Col>
                    <Col span={6}>
                      <PasswordInput
                        name={"password"}
                        label={<div className="label">Password</div>}
                        rules={[
                          {
                            whitespace: true,
                            message: "No whitespaces in the password",
                          },
                          {
                            min: 8,
                            max: 100,
                            message: "password must be at least 8 chars ",
                          },
                        ]}
                      />
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <EnglishInput
                        label={<div className="label">Name - En</div>}
                        name="ENname"
                      />
                    </Col>
                    <Col span={12}>
                      <ArabicInput
                        name="ARname"
                        label={<div className="label">Name - Ar</div>}
                      />
                    </Col>
                    <Col span={12}>
                      <EnglishInput
                        label={<div className="label">Address - En</div>}
                        name="AddENname"
                      />
                    </Col>
                    <Col span={12}>
                      <ArabicInput
                        name="AddARname"
                        label={<div className="label">Address - Ar</div>}
                      />
                    </Col>
                    <Col span={24}>
                      <LocationInput
                        label={<div className="label">{t("location")}</div>}
                        labelCol={{ span: 24 }}
                        initialValue={
                          data?.shop?.latitude !== undefined &&
                          data?.shop?.longitude !== undefined
                            ? {
                                lat: data?.shop?.latitude,
                                lng: data?.shop?.longitude,
                              }
                            : undefined
                        }
                        form={form}
                        editable
                      />
                    </Col>
                    <Col span={24}>
                      <ContactsFormList name={"contacts"} />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Spin>
        </>
      )}
    </>
  );
};

export default UpdateShopPage;
