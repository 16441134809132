import { Checkbox, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import formatKey from "features/common/helpers/formatKey";
import { IFeature } from "features/users/interfaces/I.Feature";
interface PermissionSectionProps {
  permissionsData: IFeature[];
  onChange: (value: Array<string>) => void;
  selectedPermissions: Array<string>;
}
const PermissionSection = ({
  permissionsData,
  onChange,
  selectedPermissions,
}: PermissionSectionProps) => {
  const column: ColumnsType<IFeature> = [
    {
      title: "Features",
      dataIndex: "feat",
      render(value) {
        return formatKey(value);
      },
    },
    {
      title: "Permissions",
      dataIndex: "permissions",
      width: 350,
      render(value, record) {
        return record.permissions.map((permission) => (
          <Checkbox
            value={permission?.id}
            checked={selectedPermissions.includes(permission?.id)}
            onChange={(e) => {
              if (selectedPermissions.includes(permission?.id)) {
                onChange([
                  ...selectedPermissions.filter((i) => i !== permission?.id),
                ]);
              } else {
                onChange([...selectedPermissions, permission?.id]);
              }
            }}
          >
            {formatKey(permission?.key?.split("_")?.[0])}
          </Checkbox>
        ));
      },
    },
  ];

  return (
    <Table columns={column} dataSource={permissionsData} pagination={false} />
  );
};

export default PermissionSection;
