import { ReactNode } from "react";
import { Provider } from "react-redux";
import store from "store/store";

/**
 * @namespace ReduxProvider
 */
/**
 * init redux provider for the all app
 * @param {ReactNode} children - wrapped components
 */
interface ReduxProviderProps {
  children: ReactNode;
}
const ReduxProvider = ({ children }: ReduxProviderProps) => {
  return (
    <>
      <Provider store={store}>{children}</Provider>
    </>
  );
};
export default ReduxProvider;
