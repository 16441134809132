import { message } from "antd";
import { useAppSelector } from "features/common/hooks/useReduxHooks";
import { request } from "libs/axios";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";

export interface mutationInterface {
  data: FormData;
  id: string | number;
  token: string | null;
}
const mutationFunction = async ({ id, data, token }: mutationInterface) => {
  data.append("_method", "PUT");
  return request({
    url: `/shops/${id}`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const useUpdateShopApi = (id: string | number) => {
  const navigate = useNavigate();
  const { currAppToken } = useAppSelector((state) => state.appsAuth);

  return useMutation(
    "Shop/Update",
    (data: FormData) => {
      return mutationFunction({ id, data, token: currAppToken });
    },
    {
      onSuccess: async (response) => {
        message.success(response.data.message);
        navigate("/shop-management");
      },
    }
  );
};
