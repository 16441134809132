import React, { useCallback, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { Button, Col, Radio, Row } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLeftLong } from "@fortawesome/free-solid-svg-icons";
import {
  useAppDispatch,
  useAppSelector,
} from "features/common/hooks/useReduxHooks";
import { useNavigate } from "react-router-dom";
import { UsersSliceActions } from "features/users/redux/slices/usersSlice";
import AddConsoleUserSection from "features/users/components/Sections/AddConsoleUser/AddConsoleUserSection";
import AddApiUserSection from "features/users/components/Sections/AddApiUser/AddApiUserSection";
import { IUser } from "features/users/interfaces/I.User";

function UserAddPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [type, setType] = useState<IUser["type"]>("Console User");
  const { ResetAdd } = UsersSliceActions;
  const { primaryColor } = useAppSelector((state) => state.ui);

  useEffect(() => {
    return () => {
      dispatch(ResetAdd());
    };
  }, []);

  return (
    <>
      <Row>
        <Col span={24}>
          <Row>
            <Col span={1} className={styles.col}>
              <Button
                onClick={() => {
                  navigate("/user-management");
                }}
                type="text"
                icon={
                  <FontAwesomeIcon
                    icon={faLeftLong}
                    style={{ color: primaryColor ?? "", fontSize: 25 }}
                  />
                }
              />
            </Col>
            <Col span={19}>
              <div className={styles.title}>
                Users Management - <span className={styles.txt}>Add</span>
              </div>
            </Col>
            <Col span={24}>
              <Radio.Group
                optionType="button"
                buttonStyle="solid"
                style={{ display: "flex", justifyContent: "center" }}
                value={type}
                onChange={(e) => {
                  setType(e.target.value);
                }}
              >
                <Radio
                  value={"Console User"}
                  style={{ padding: "10px 20px", height: "auto" }}
                >
                  Console User
                </Radio>
                <Radio
                  value={"API User"}
                  style={{ padding: "10px 20px", height: "auto" }}
                >
                  Api User
                </Radio>
              </Radio.Group>
            </Col>
          </Row>
        </Col>
        {type === "Console User" ? (
          <AddConsoleUserSection />
        ) : type === "API User" ? (
          <AddApiUserSection />
        ) : null}
      </Row>
    </>
  );
}

export default UserAddPage;
