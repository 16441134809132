import { message } from "antd";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "./useReduxHooks";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "./useReduxHooks";
import { ArgsProps } from "antd/es/message";
import { UiSliceActions } from "../redux/slices/uiSlice";
import { ERR } from "data/Errors";
import { UserAuthSliceActions } from "features/auth/redux/slice/userAuthSlice";

/**
 *
 * @description a hook that used to handle all the app errors and store it in redux slice if it is needed
 * with redirects options or just a messages errors handle
 */
export const useErrorHandler = () => {
  const navigate = useNavigate();
  const [cookies, _, removeCookie] = useCookies(["user"]);
  const { errors } = useAppSelector((state) => state.ui);
  const dispatch = useAppDispatch();

  const location = useLocation();
  useEffect(() => {
    dispatch(UiSliceActions.SetError(null));
  }, [location]);

  useEffect(() => {
    if (errors) {
      console.log("error handler \n", errors);
      if (!errors.navigate) {
        let config: ArgsProps = {
          type: "error",
          content: "",
          duration: 3,
        };
        switch (errors.code) {
          case ERR.NOT_FOUND:
            config.content = errors.message;
            break;
          case ERR.UNAUTHORIZED:
            config.content = errors.message;
            break;
          case ERR.UNAUTHENTICATED:
            config.content = errors.message;
            sessionStorage.removeItem("user");
            if (cookies.user) {
              removeCookie("user", { path: "/" });
            }
            dispatch(UserAuthSliceActions.Logout());
            navigate("/auth/login");
            break;
          case ERR.SERVER_ERROR:
            config.content = errors.message;
            break;
          case ERR.UNPROCESSABLE_CONTENT:
            config.content = errors.message;
            break;
          default:
            config.content = errors.message;
            break;
        }
        message.open(config);
      }
    }
    return;
  }, [errors]);
};
