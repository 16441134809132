import { Input } from "antd";
import { memo } from "react";

interface SearchInputProps {
  setSearchTerm: any;
  isLoading: boolean;
  selectionMode?: boolean;
  defaultValue?: string;
}
/**
 * @description an input seach component that takes params and have a use of onChange and onSearch event logic
 * @param {Function} setSearchTerm - function to set fire on search
 * @param {boolean} isLoading - if the search is online
 * @param {boolean} selectionMode - to disabel the input if true
 * @param {string}  defaultValue - if have a prev or default value
 */
const SearchInput = ({
  selectionMode,
  setSearchTerm,
  isLoading,
  defaultValue,
}: SearchInputProps) => {
  return (
    <>
      <Input.Search
        disabled={selectionMode}
        size="large"
        placeholder="Search"
        loading={isLoading}
        onSearch={(value: string) => {
          setSearchTerm(value);
        }}
        onChange={(value: any) => {
          setSearchTerm(value.target.value);
        }}
        allowClear={true}
        defaultValue={defaultValue}
      />
    </>
  );
};

export default memo(SearchInput);
