import { SorterInterface } from "features/common/interfaces/SorterInterface.d";
import { FilterTypes } from "features/common/types/FilterTypes";
import { GamesPayloadAction } from "features/games/interfaces/I.GamesPayloadAction";
import { I_Gift } from "features/games/interfaces/I.Gift";
import { I_InitialStateGamesSlice } from "features/games/interfaces/I.InitialStateGamesSlice";
import { WritableDraft } from "immer/dist/internal";

export const GamesResetIndex = (
  state: WritableDraft<I_InitialStateGamesSlice>,
  action: GamesPayloadAction<number>
) => {
  state[action.payload.resource].indexPage.pagnation.page =
    action.payload.value;
};
export const GamesPageAction = (
  state: WritableDraft<I_InitialStateGamesSlice>,
  action: GamesPayloadAction<number>
) => {
  state[action.payload.resource].indexPage.pagnation.page =
    action.payload.value;
};
export const GamesPerPageAction = (
  state: WritableDraft<I_InitialStateGamesSlice>,
  action: GamesPayloadAction<number>
) => {
  state[action.payload.resource].indexPage.pagnation.perPage =
    action.payload.value;
};
export const UserAttemptsPageAction = (
  state: WritableDraft<I_InitialStateGamesSlice>,
  action: GamesPayloadAction<number>
) => {
  state[
    action.payload.resource
  ].statisticsPage.visitors.userAttempts.pagnation.page = action.payload.value;
};
export const UserAttemptsPerPageAction = (
  state: WritableDraft<I_InitialStateGamesSlice>,
  action: GamesPayloadAction<number>
) => {
  state[
    action.payload.resource
  ].statisticsPage.visitors.userAttempts.pagnation.perPage =
    action.payload.value;
};
export const StatisticsPageAction = (
  state: WritableDraft<I_InitialStateGamesSlice>,
  action: GamesPayloadAction<{ table: "attempts" | "visitors"; value: number }>
) => {
  state[action.payload.resource].statisticsPage[
    action.payload.value.table
  ].pagnation.page = action.payload.value.value;
};
export const StatisticsPerPageAction = (
  state: WritableDraft<I_InitialStateGamesSlice>,
  action: GamesPayloadAction<{ table: "attempts" | "visitors"; value: number }>
) => {
  state[action.payload.resource].statisticsPage[
    action.payload.value.table
  ].pagnation.perPage = action.payload.value.value;
};
export const StatisticsTable = (
  state: WritableDraft<I_InitialStateGamesSlice>,
  action: GamesPayloadAction<"attempts" | "visitors">
) => {
  state[action.payload.resource].statisticsPage.table = action.payload.value;
};
export const GamesSorterAction = (
  state: WritableDraft<I_InitialStateGamesSlice>,
  action: GamesPayloadAction<SorterInterface>
) => {
  state[action.payload.resource].indexPage.sorter = action.payload.value;
};

export const GamesSearchAction = (
  state: WritableDraft<I_InitialStateGamesSlice>,
  action: GamesPayloadAction<string>
) => {
  state[action.payload.resource].indexPage.search = action.payload.value;
};
export const StatisticsSearchAction = (
  state: WritableDraft<I_InitialStateGamesSlice>,
  action: GamesPayloadAction<{ table: "attempts" | "visitors"; value: string }>
) => {
  state[action.payload.resource].statisticsPage[
    action.payload.value.table
  ].search = action.payload.value.value;
};
export const GamesFilterAction = (
  state: WritableDraft<I_InitialStateGamesSlice>,
  action: GamesPayloadAction<FilterTypes>
) => {
  state[action.payload.resource].indexPage.filters = action.payload.value;
};
export const AddGameSetSessionAction = (
  state: WritableDraft<I_InitialStateGamesSlice>,
  action: GamesPayloadAction<string | null>
) => {
  state[action.payload.resource].addPage.sessionID = action.payload.value;
};
export const AddGameAddGiftAction = (
  state: WritableDraft<I_InitialStateGamesSlice>,
  action: GamesPayloadAction<I_Gift>
) => {
  state[action.payload.resource].addPage.addedGifts.push(action.payload.value);
};
export const EditGameSetSessionAction = (
  state: WritableDraft<I_InitialStateGamesSlice>,
  action: GamesPayloadAction<string | null>
) => {
  state[action.payload.resource].editPage.sessionID = action.payload.value;
};
export const EditGameAddGiftAction = (
  state: WritableDraft<I_InitialStateGamesSlice>,
  action: GamesPayloadAction<I_Gift[]>
) => {
  state[action.payload.resource].editPage.addedGifts = [
    ...action.payload.value,
  ];
};
export const AddGameDeleteGiftAction = (
  state: WritableDraft<I_InitialStateGamesSlice>,
  action: GamesPayloadAction<string>
) => {
  let newGiftsArr = state[action.payload.resource].addPage.addedGifts.filter(
    (gift) => {
      return gift.id !== action.payload.value;
    }
  );
  state[action.payload.resource].addPage.addedGifts = [...newGiftsArr];
};
export const AddGameEditGiftAction = (
  state: WritableDraft<I_InitialStateGamesSlice>,
  action: GamesPayloadAction<I_Gift>
) => {
  let index = state[action.payload.resource].addPage.addedGifts.findIndex(
    (gift) => {
      return gift.id === action.payload.value.id;
    }
  );
  state[action.payload.resource].addPage.addedGifts[index] = {
    ...action.payload.value,
  };
};

// export const GamessetSessionId = (
//   state: WritableDraft<I_InitialStateGamesSlice>,
//   action: GamesPayloadAction<null | string>
// ) => {
//   state[action.payload.resource].sessionId = action.payload.value;
// };
