import { AES, enc } from "crypto-js";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { IAppsAuthSliceInitState } from "../interfaces/I.AppsAuthSliceIinitState";
import { IUserAuthSliceInitState } from "../interfaces/I.UserAuthSliceIinitState";

export const useDecryptUserData = () => {
  const [isValid, setIsValid] = useState(false);
  const [isChecked, setISChecked] = useState(false);
  const [decryptedInfo, setDecryptedInfo] = useState<{
    user: IUserAuthSliceInitState;
    app: (IAppsAuthSliceInitState & { color: string }) | null;
  } | null>(null);
  const [userCookies, _user, removeUserCookie] = useCookies(["user"]);
  const [appCookies, _app, removeAppCookie] = useCookies(["app"]);
  const userCipherText = sessionStorage.getItem("user") ?? userCookies.user;
  const appCipherText = sessionStorage.getItem("app") ?? appCookies.app;
  useEffect(() => {
    if (userCipherText) {
      try {
        let appObject: (IAppsAuthSliceInitState & { color: string }) | null =
          null;
        let userBytes = AES.decrypt(
          userCipherText,
          process.env.REACT_APP_SECRET_CODE as string
        );
        setIsValid(true);
        setISChecked(true);
        try {
          let appBytes = AES.decrypt(
            appCipherText,
            process.env.REACT_APP_SECRET_CODE as string
          );
          appObject = JSON.parse(
            appBytes.toString(enc.Utf8)
          ) as IAppsAuthSliceInitState & { color: string };
        } catch (err) {
          sessionStorage.removeItem("app");
          removeAppCookie("app");
        }
        setDecryptedInfo({
          user: JSON.parse(
            userBytes.toString(enc.Utf8)
          ) as IUserAuthSliceInitState,
          app: appObject,
        });
      } catch (err) {
        sessionStorage.removeItem("user");
        sessionStorage.removeItem("app");
        removeUserCookie("user");
        removeAppCookie("app");
        setIsValid(false);
        setISChecked(true);
        setDecryptedInfo(null);
      }
    } else {
      setIsValid(false);
      setISChecked(true);
      setDecryptedInfo(null);
    }
  }, []);
  return { isValid, isChecked, decryptedInfo };
};
