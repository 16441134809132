import { useAppSelector } from "features/common/hooks/useReduxHooks";
import { Navigate, Outlet, useLocation } from "react-router-dom";

/**
 * @description to prevent a user from navigate to login pages if already loged in
 */
export const UserUnAuthenticatedRoutes = () => {
  const { authToken } = useAppSelector((state) => state.userAuth);
  const to = "/";
  const location = useLocation();
  return authToken == null || authToken === undefined ? (
    <Outlet />
  ) : (
    <Navigate to={to} state={{ from: location }} />
  );
};
