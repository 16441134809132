import { Button, Popover } from "antd";
import styles from "./styles.module.scss";
import AppButton from "features/common/components/Buttons/AppButton/AppButton";
import {
  useAppDispatch,
  useAppSelector,
} from "features/common/hooks/useReduxHooks";
import { useEffect, useState } from "react";
import { useSwitchCurrentApp } from "features/auth/apis/useSwitchCurrentApp";
import { AppsAuthSliceActions } from "features/auth/redux/slice/appsAuthSlice";
import { UiSliceActions } from "features/common/redux/slices/uiSlice";
import { IAppInfo } from "features/auth/interfaces/I.AppInfo";
import { useNavigate } from "react-router-dom";
import { useEncrypData } from "features/auth/hooks/useEncryptData";
import { useCookies } from "react-cookie";

/**
 *
 * component that used to swich between the apps without log out
 */
function AppsPopover() {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const { apps, rememberMe } = useAppSelector((state) => state.userAuth);
  const [items, setItems] = useState<any>();
  const { currAppIcon, currAppId, currAppName } = useAppSelector(
    (state) => state.appsAuth
  );
  const { mutateAsync } = useSwitchCurrentApp();
  const navigate = useNavigate();
  const setEncryptAppData = useEncrypData();
  const [cookie, setCookie, removeCookie] = useCookies();

  const appsMenu = <div className={styles.appsMenu}>{items}</div>;
  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };
  const handleSwitchApp = (app: IAppInfo) => {
    dispatch(UiSliceActions.SetLoading(true));
    mutateAsync({ id: app.id })
      .then((response) => {
        const currApp = {
          currAppId: app.id,
          currAppIcon: app.logo,
          currAppName: app.name,
          currAppToken: response.data?.token,
          currAppFeatures: app.sub_features,
        };
        const dataEncrypted = setEncryptAppData({
          ...currApp,
          color: app.color,
        });
        if (dataEncrypted) {
          sessionStorage.setItem("app", dataEncrypted);
          if (rememberMe) {
            setCookie("app", dataEncrypted, {
              path: "/",
              maxAge: 1000 * 60 * 60 * 24 * 30, // One Month
            });
          }
        }
        dispatch(
          AppsAuthSliceActions.SetCurrentAppAction({
            ...currApp,
          })
        );
        dispatch(UiSliceActions.SetPrimaryColor(app.color));
        setOpen(false);
        navigate("/");
        dispatch(UiSliceActions.SetLoading(false));
      })
      .catch(() => {
        setOpen(false);
        navigate("/");
        dispatch(UiSliceActions.SetLoading(false));
      });
  };
  useEffect(() => {
    setItems(() =>
      apps
        .filter((app) => app.id !== currAppId)
        .map((app) => {
          return (
            <AppButton
              block
              type="ghost"
              id={app.id as string}
              icon={app.logo}
              name={app.name}
              primaryColor={app.color}
              onClick={() => handleSwitchApp(app)}
            />
          );
        })
    );
  }, [currAppId]);
  return (
    <>
      <div className={styles.appsDropDown}>
        <Popover
          content={appsMenu}
          title={<div className={styles.popoverTitle}>Switch To</div>}
          trigger="click"
          arrow={false}
          open={open && items.length !== 0}
          onOpenChange={handleOpenChange}
          placement="bottomLeft"
        >
          <Button
            className={styles.dropDownButton}
            icon={
              <img
                className={styles.appIcon}
                src={currAppIcon ?? ""}
                alt="logo"
              />
            }
            // size="large"
            type="default"
          >
            {currAppName}
          </Button>
        </Popover>
      </div>
    </>
  );
}

export default AppsPopover;
