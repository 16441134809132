import { IAppFeatures } from "../interfaces/I.AppFeatures";
import { featuresTypes } from "../types/featureTypes.d";
/**
 * a helper fuction use to get the feautre's permissions keys in array of strings
 * @param {IAppFeatures[]} features
 * @param {featuresTypes} feature
 * @returns {string[]}
 */
export const getPermissionsKeys = (
  features: IAppFeatures[],
  feature: featuresTypes
) => {
  return features
    .filter((feat) => feat.key === feature)
    ?.map((currentFeat) => currentFeat.permissions)[0]
    ?.map((permission) => permission.key);
};
