import { PayloadAction } from "@reduxjs/toolkit";
import { IAppsAuthSliceInitState } from "features/auth/interfaces/I.AppsAuthSliceIinitState";
import { WritableDraft } from "immer/dist/internal";

/**
 * @description action to swith the current app state
 * @param {IAppsAuthSliceInitState} state
 * @param {IAppsAuthSliceInitState} action
 */
export const setCurrentAppAction = (
  state: WritableDraft<IAppsAuthSliceInitState>,
  action: PayloadAction<IAppsAuthSliceInitState>
) => {
  state.currAppId = action.payload.currAppId;
  state.currAppIcon = action.payload.currAppIcon;
  state.currAppName = action.payload.currAppName;
  state.currAppToken = action.payload.currAppToken;
  state.currAppFeatures = action.payload.currAppFeatures;
};
