import { createSlice } from "@reduxjs/toolkit";
import { IUserAuthSliceInitState } from "features/auth/interfaces/I.UserAuthSliceIinitState";
import { loginAction } from "../actions/userAuthActions";

const initialState: IUserAuthSliceInitState = {
  rememberMe: false,
  authToken: null,
  avatar: "",
  email: "",
  id: "0",
  name: "",
  apps: [],
};
/**
 * @description the user auth slice control the authentication
 *  system in the app and it has the authtoken and the apps that this user have access to
 */
const userAuthSlice = createSlice({
  name: "userAuth",
  initialState,
  reducers: {
    Login: loginAction,
    Logout: () => ({ ...initialState }),
  },
});

export const userAuthReducer = userAuthSlice.reducer;
export const UserAuthSliceActions = userAuthSlice.actions;
