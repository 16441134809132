import { Button, Col, Form, Input, Row, Spin, message } from "antd";
import styles from "./styles.module.scss";
import EnglishInput from "features/common/components/Forms/formItems/stringInputs/EnglishInput";
import PermissionSection from "../Permissions/PermissionsSection";
import { UsersSliceActions } from "features/users/redux/slices/usersSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "features/common/hooks/useReduxHooks";
import { useEffect, useState } from "react";
import { useShowApiUserApi } from "features/users/apis/useShowApiUserApi";
import { useParams } from "react-router-dom";
import { useUpdateApiUserApi } from "features/users/apis/useUpdateApiUserApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { IFeature } from "features/users/interfaces/I.Feature";

const EditApiUserSection = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { permissions } = useAppSelector((state) => state.users.edit.api);
  const [permissionsData, setPermissionsData] = useState<IFeature[]>([]);
  const { isLoading, mutateAsync } = useUpdateApiUserApi(id ? +id : 0);
  const {
    data,
    isLoading: Loading,
    isRefetching,
  } = useShowApiUserApi(id ? +id : 0);
  const onFinish = (values: any) => {
    const formData = new FormData();
    formData.append("name", values.name);
    permissions.forEach((permission, index) => {
      formData.append(`permissions[${index}]`, `${permission}`);
    });
    mutateAsync(formData);
  };
  useEffect(() => {
    if (data) {
      form.resetFields();
      const temp: string[] = [];
      setPermissionsData(
        data?.sub_features?.map((feat) => {
          feat?.permissions?.forEach((permission) => {
            if (permission.is_checked) {
              temp.push(permission?.id);
            }
          });
          return {
            feat: feat?.key,
            permissions: feat?.permissions,
          };
        })
      );
      dispatch(UsersSliceActions.SetPermissionsAction(["edit", "api", temp]));
    }
  }, [data]);
  return (
    <>
      <Spin
        spinning={isLoading || Loading || isRefetching}
        wrapperClassName={styles.formContainer}
      >
        <Col span={24} className={styles.formContainer}>
          <Form
            form={form}
            onFinish={onFinish}
            labelCol={{ span: 24 }}
            className={styles.form}
            initialValues={{
              name: data?.name,
              api_key: data?.api_key,
            }}
          >
            <Row gutter={32}>
              <Col className={styles.col} xs={12}>
                <EnglishInput
                  name={"name"}
                  label={<div className="label">User Name</div>}
                />
              </Col>
              <Col className={styles.col} xs={12}>
                <Form.Item
                  name={"api_key"}
                  label={<div className="label">API Key</div>}
                >
                  <Input
                    readOnly
                    addonAfter={
                      <Button
                        disabled={!data?.api_key}
                        style={{ padding: 0, height: "auto" }}
                        type="text"
                        icon={<FontAwesomeIcon icon={faCopy} />}
                        onClick={() => {
                          if (data?.api_key)
                            navigator.clipboard
                              .writeText(data?.api_key)
                              .then(() => {
                                message.info("API key copied to clipboard");
                              });
                        }}
                      />
                    }
                  />
                </Form.Item>
              </Col>
              <Col className={styles.col} span={24}>
                <PermissionSection
                  permissionsData={permissionsData}
                  selectedPermissions={permissions}
                  onChange={(value) => {
                    dispatch(
                      UsersSliceActions.SetPermissionsAction([
                        "edit",
                        "api",
                        value,
                      ])
                    );
                  }}
                />
              </Col>
            </Row>
          </Form>
        </Col>
        <Col
          span={4}
          className={styles.col}
          style={{ marginInlineStart: "auto" }}
        >
          <Button
            size="large"
            type="primary"
            shape="round"
            block
            onClick={() => form.submit()}
            loading={isLoading}
          >
            Save
          </Button>
        </Col>
      </Spin>
    </>
  );
};

export default EditApiUserSection;
