import { PayloadAction } from "@reduxjs/toolkit";
import { IUserAuthSliceInitState } from "features/auth/interfaces/I.UserAuthSliceIinitState";
import { WritableDraft } from "immer/dist/internal";

/**
 * @description action to set the user auth info into the state
 * @param {IUserAuthSliceInitState} state
 * @param {IUserAuthSliceInitState} action
 */
export const loginAction = (
  state: WritableDraft<IUserAuthSliceInitState>,
  action: PayloadAction<IUserAuthSliceInitState>
) => {
  state.authToken = action.payload.authToken;
  state.avatar = action.payload.avatar;
  state.email = action.payload.email;
  state.id = action.payload.id;
  state.name = action.payload.name;
  state.rememberMe = action.payload.rememberMe;
  state.apps = action.payload.apps;
};
