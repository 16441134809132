import { useAppSelector } from "features/common/hooks/useReduxHooks";
import { request } from "libs/axios";
import { useQuery } from "react-query";
import { GamesDashboardResponse } from "features/common/interfaces/apis/responses/games/DashboardResponse.d";

export const useGamesDashboardApi = () => {
  const { currAppToken } = useAppSelector((state) => state.appsAuth);

  return useQuery(
    [`games-dashboard`],
    () => {
      return request<GamesDashboardResponse>({
        url: `/statistics/games`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${currAppToken}`,
        },
      });
    },
    {
      select: (data) => {
        return data.data;
      },
    }
  );
};
