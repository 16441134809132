import { Button, message } from "antd";
import styles from "./styles.module.scss";
import { ReactNode } from "react";
import { request } from "libs/axios";
import { baseURL } from "config/baseURL";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { useAppSelector } from "features/common/hooks/useReduxHooks";

interface DownloadButtonProps {
  url: string;
  icon?: ReactNode;
  children?: any;
  type?: "link" | "text" | "ghost" | "default" | "primary" | "dashed";
}
/**
 * @description a button for downlaod a givenn url
 */
const DownloadButton = ({
  url,
  icon = <FontAwesomeIcon icon={faDownload} />,
  children,
  type = "primary",
}: DownloadButtonProps) => {
  const { currAppToken } = useAppSelector((state) => state.appsAuth);
  return (
    <Button
      className={styles.downloadButton}
      type={type}
      icon={icon}
      onClick={() => {
        return request<any, any, BlobPart>({
          url: `${baseURL}${url}`,
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${currAppToken}`,
          },
          method: "GET",
        })
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            link.setAttribute("download", "file.xlsx");
            link.href = url;
            link.click();
          })
          .catch((error: any) => {
            message.error(error.message);
          });
      }}
      children={children}
    />
  );
};

export default DownloadButton;
