import { useAppSelector } from "features/common/hooks/useReduxHooks";
import { request } from "libs/axios";
import { useQuery } from "react-query";
import { gamesTypes } from "../types/gamesTypes";
import { AttemptResponse } from "features/common/interfaces/apis/responses/games/StatisticsResponse.d";

export const useVisitorAttemptsApi = (
  id: number | null,
  record: number | null,
  resource: gamesTypes
) => {
  const { currAppToken } = useAppSelector((state) => state.appsAuth);
  const {
    pagnation: { page, perPage },
  } = useAppSelector(
    (state) => state.games[resource].statisticsPage.visitors.userAttempts
  );

  return useQuery(
    [`VisitorAttempts-${resource}`, id, { page, perPage }],
    () => {
      return request<AttemptResponse[]>({
        url:
          resource === "scratch_ticket"
            ? `/scratch-tickets/${id}/visitor-attempts/${record}`
            : resource === "scratch_grid"
            ? `/scratch-grids/${id}/visitor-attempts/${record}`
            : resource === "number_guess"
            ? `/number-guesses/${id}/visitor-attempts/${record}`
            : "",
        method: "GET",
        headers: {
          Authorization: `Bearer ${currAppToken}`,
        },
        params: {
          items_per_page: perPage,
          page: page,
        },
      });
    },
    {
      select: (data) => {
        return data.data;
      },
    }
  );
};
