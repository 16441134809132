import { Avatar, Button, Col, Image, Modal, Row, Tag } from "antd";
import { ColumnsType } from "antd/es/table";
import Filter from "features/common/components/Filter/filter";

import {
  useAppDispatch,
  useAppSelector,
} from "features/common/hooks/useReduxHooks";
import { useSearchDelay } from "features/common/hooks/useSearchDelay";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";
import { ShopsSliceActions } from "features/shops/redux/slices/shopsSlice";
import { SorterInterface } from "features/common/interfaces/SorterInterface.d";
import { FilterTypes } from "features/common/types/FilterTypes";
import SearchInput from "features/common/components/Inputs/SearchInput/SearchInput";
import Sorter from "features/common/components/Sorter/Sorter";
import CustomTable from "features/common/components/Tables/CustomTable";
import styles from "./styles.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import DeletePopConfirm from "features/common/components/PopConfirms/DeletePopConfirm/DeletePopConfirm";
import MapWrapper from "features/common/components/MapWrapper/MapWrapper";
import LocationModal from "features/shops/components/LocationModal/LocationModal";
import { useDeleteShopApi } from "features/shops/apis/useDeleteShopApi";
import { IShop } from "features/shops/interfaces/I.Shop";
import AddShopModal from "features/shops/components/AddShopModal/AddShopModal";
import { useFetchAllShopsApi } from "features/shops/apis/useFetchAllShopsApi";
import ComponentGuard from "features/common/components/ComponentGuard/ComponentGuard";

const AllShopsPage = () => {
  const {
    FiltersAction,
    PageAction,
    PerPageAction,
    SearchAction,
    SorterAction,
  } = ShopsSliceActions;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchContent, setSearchTerm] = useSearchDelay();
  const [dataSource, setDataSource] = useState<any>();
  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);
  const {
    filters,
    sorter,
    search,
    pagnation: { page, perPage },
  } = useAppSelector((state) => state.shops);
  const { data, isLoading, isRefetching, refetch } = useFetchAllShopsApi();
  const { mutateAsync } = useDeleteShopApi();
  useEffect(() => {
    if (data) {
      setDataSource(
        data?.map((object) => {
          return {
            id: object.shop.id,
            name: object.shop.name,
            address: object.shop.address,
            location: {
              lat: object.shop.latitude,
              lon: object.shop.longitude,
            },
          };
        })
      );
    }
  }, [data]);
  useEffect(() => {
    if (searchContent !== undefined) {
      console.log("searchContent", searchContent?.length);
      dispatch(SearchAction(searchContent));
      dispatch(PageAction(1));
    }
  }, [searchContent]);

  const setSorter = useCallback(
    (payload: SorterInterface) => {
      dispatch(SorterAction(payload));
    },
    [dispatch]
  );
  const setFilters = useCallback(
    (payload: FilterTypes | null) => {
      dispatch(FiltersAction(payload));
      dispatch(PageAction(1));
    },
    [dispatch]
  );
  const setPerPage = useCallback(
    (payload: number) => dispatch(PerPageAction(payload)),
    [dispatch]
  );
  const setPage = useCallback(
    (payload: number) => dispatch(PageAction(payload)),
    [dispatch]
  );
  const columns: ColumnsType<IShop> = [
    {
      title: "Name",
      dataIndex: "name",
      align: "center",
      // width: 130,
    },
    {
      title: "Adress",
      dataIndex: "address",
      align: "center",
    },
    {
      title: "Location",
      align: "center",
      render(value, record, index) {
        return record.location.lat !== null && record.location.lon !== null ? (
          <LocationModal lat={record.location.lat} lon={record.location.lon} />
        ) : (
          "No location set"
        );
      },
    },
    {
      title: "Actions",
      align: "center",
      width: 100,
      render(value, record, index) {
        return (
          <>
            <ComponentGuard permission="edit_shops">
              <Button
                onClick={() => {
                  navigate(`./${record.id}`);
                }}
                type="text"
                icon={<FontAwesomeIcon icon={faEdit} />}
              />
            </ComponentGuard>
            <ComponentGuard permission="delete_shops">
              <DeletePopConfirm
                onSubmit={() => {
                  return mutateAsync(record.id);
                }}
              >
                <Button
                  type="text"
                  danger
                  icon={<FontAwesomeIcon icon={faTrash} />}
                />
              </DeletePopConfirm>
            </ComponentGuard>
          </>
        );
      },
      // onHeaderCell: () => ({
      //   style: { background: "red", color: "white" },
      // }),
    },
  ];
  return (
    <>
      <ComponentGuard permission="add_shops">
        <AddShopModal
          isOpen={isAddModalOpen}
          setIsOpen={setIsAddModalOpen}
          onAddSucces={refetch}
        />
      </ComponentGuard>
      <Row className={styles.row} gutter={[8, 8]}>
        <Col span={24}>
          <div className={styles.maintitle}>{t("Shops")}</div>
        </Col>
        <ComponentGuard permission="add_shops">
          <Col xs={12} md={8} lg={4} xl={3}>
            <Button
              block
              shape="round"
              type={"primary"}
              className={styles.addButton}
              onClick={() => {
                setIsAddModalOpen(true);
              }}
            >
              Add Shop
            </Button>
          </Col>
        </ComponentGuard>
        <Col
          className={styles.searchCol}
          xs={{
            span: 24,
            order: 10,
          }}
          md={{
            span: 8,
            order: 0,
          }}
          lg={6}
        >
          <SearchInput
            isLoading={false}
            setSearchTerm={setSearchTerm}
            defaultValue={search}
          />
        </Col>
        <Col className={styles.col}>
          <Sorter
            sortersName={["name"]}
            sorter={sorter}
            setSorter={setSorter}
          />
        </Col>
        <Col style={{ paddingLeft: "5px" }}>
          <Filter filters={filters} setFilters={setFilters}>
            <></>
          </Filter>
        </Col>
      </Row>
      <div>
        <CustomTable
          bordered
          scroll={{ y: 470, x: 800 }}
          isLoading={isLoading}
          isRefetching={isRefetching}
          meta={[]}
          page={page}
          perPage={perPage}
          name="shops"
          data={dataSource}
          columns={columns}
          SetPage={setPage}
          SetPerPage={setPerPage}
        />
      </div>
    </>
  );
};

export default AllShopsPage;
