import { message } from "antd";
import { useAppSelector } from "features/common/hooks/useReduxHooks";
import { request } from "libs/axios";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { gamesTypes } from "../types/gamesTypes";

export interface mutationInterface {
  data: FormData;
  id: null | number;
  token: string | null;
  resource: gamesTypes;
}
const mutationFunction = async ({
  id,
  data,
  token,
  resource,
}: mutationInterface) => {
  data.append("_method", "PUT");
  return request({
    url:
    resource === "scratch_ticket"
    ? `/scratch-tickets/${id}`
    : resource === "scratch_grid"
    ? `/scratch-grids/${id}`
    : resource === "number_guess"
    ? `/number-guesses/${id}`
    : "",
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const useUpdateGameApi = (id: number | null, resource: gamesTypes) => {
  const { currAppToken } = useAppSelector((state) => state.appsAuth);

  return useMutation(
    "Shop/Update",
    (data: FormData) => {
      return mutationFunction({ id, data, token: currAppToken, resource });
    },
    {
      onSuccess: async (response) => {
        message.success(response.data.message);
      },
    }
  );
};
