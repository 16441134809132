import { Form, FormItemProps, Input } from "antd";
import { NamePath } from "antd/es/form/interface";

interface PasswordInputProps extends FormItemProps {
  name: NamePath;
  initialValue?: string;
  placeholder?: string;
  disabled?: boolean;
}
/**
 * @description a form item with password input 
 * @param {string}  name
 * @param {string}  initialValue
 * @param {string}  placeholder
 * @param {boolean}  disabled
 * @param {FormItemProps} restProps  - the rest form item interface props
 */
const PasswordInput = ({
  name,
  initialValue,
  placeholder,
  disabled = false,
  ...restProps
}: PasswordInputProps) => {
  return (
    <>
      <Form.Item
        initialValue={initialValue}
        name={name}
        rules={[
          {
            required: true,
            message: "Enter password please",
            type: "string",
          },
          { whitespace: true, message: "No whitespaces in the password" },
          { min: 8, max: 100, message: "password must be at least 8 chars " },
        ]}
        {...restProps}
      >
        <Input.Password placeholder={placeholder} disabled={disabled} />
      </Form.Item>
    </>
  );
};

export default PasswordInput;
