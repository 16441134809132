import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSliders,
  faUsers,
  faGamepad,
  faCartShopping,
} from "@fortawesome/free-solid-svg-icons";
import { getFeaturesKeys } from "features/auth/helpers/getFeaturesKeys";
import type { MenuProps } from "antd";
import { Layout, Menu, theme } from "antd";
import styles from "./styles.module.scss";
import { useAppSelector } from "features/common/hooks/useReduxHooks";
import { useLocation, useNavigate } from "react-router-dom";
import { usePermissionGuard } from "features/common/components/ComponentGuard/ComponentGuard";
const { Sider } = Layout;

type MenuItem = Required<MenuProps>["items"][number];

const CustomSider: React.FC = () => {
  const [collapsed, setCollapsed] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedKeys, setSelectedKeys] = useState<Array<string>>([]);
  const {
    token: { colorBgContainer, colorPrimary },
  } = theme.useToken();
  const [dashboardItems, setDashItems] = useState<MenuItem[]>([]);
  const [shopsItems, setShopsItems] = useState<MenuItem[]>([]);
  const [gamesitems, setGamesItems] = useState<MenuItem[]>([]);
  const [usersManagmentItems, setUsersManagmentItems] = useState<MenuItem[]>(
    []
  );
  const [allItems, setALlItems] = useState<MenuItem[]>();
  const { currAppFeatures } = useAppSelector((state) => state.appsAuth);
  const { permissionGuard } = usePermissionGuard();

  useEffect(() => {
    const featuresKeys = getFeaturesKeys(currAppFeatures);
    setDashItems(() => {
      return featuresKeys.includes("dashboard")
        ? [
            {
              label: "Dashbord",
              key: "dashboard",
              icon: <FontAwesomeIcon icon={faSliders} />,
            },
          ]
        : [];
    });
    setShopsItems(() => {
      return featuresKeys.includes("SHOP_MANAGEMENT") &&
        permissionGuard("view_shops")
        ? [
            {
              label: "Shops",
              key: "shop-management",
              icon: <FontAwesomeIcon icon={faCartShopping} />,
            },
          ]
        : [];
    });
    setGamesItems(() => {
      const subFeaturesKeys = featuresKeys.filter(
        (key) =>
          key === "NUMBER_GUESS" ||
          key === "SCRATCH_TICKET" ||
          key === "SCRATCH_GRID"
      );
      const subFeatures: MenuItem[] = [];
      subFeaturesKeys.forEach((key) => {
        if (key === "NUMBER_GUESS" && permissionGuard("view_number_guesses")) {
          subFeatures.push({
            label: "Number Guess",
            key: "number-guess",
          });
        } else if (
          key === "SCRATCH_TICKET" &&
          permissionGuard("view_scratch_tickets")
        ) {
          subFeatures.push({
            label: "Scratch Ticket",
            key: "scratch-ticket",
          });
        } else if (
          key === "SCRATCH_GRID" &&
          permissionGuard("view_scratch_grids")
        ) {
          subFeatures.push({
            label: "Scratch Grid",
            key: "scratch-grid",
          });
        }
      });
      if (subFeatures.length > 0) {
        return [
          {
            key: "games",
            label: "Games",
            icon: <FontAwesomeIcon icon={faGamepad} />,
            children: [
              { label: "Games Dashboard", key: "dashboard" },
              ...subFeatures,
            ],
          },
        ];
      } else return [];
    });
    setUsersManagmentItems(() => {
      //FIXME - change permission key
      return featuresKeys.includes("USER_MANAGEMENT") &&
        permissionGuard("view_users")
        ? [
            {
              key: "user-management",
              label: "User Management",
              icon: <FontAwesomeIcon icon={faUsers} />,
            },
          ]
        : [];
    });
  }, [currAppFeatures]);
  useEffect(() => {
    setALlItems([
      ...dashboardItems,
      ...usersManagmentItems,
      ...shopsItems,
      ...gamesitems,
    ]);
  }, [dashboardItems, gamesitems, usersManagmentItems, shopsItems]);

  useEffect(() => {
    setSelectedKeys([...location.pathname.split("/")]);
  }, [location, allItems]);

  return (
    <Sider
      className={styles.sidebar}
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
      // trigger={<></>}
      theme="light"
      style={
        {
          // background: colorPrimary,
        }
      }
    >
      <div className="demo-logo-vertical" />
      <Menu
        className={styles.menu}
        defaultSelectedKeys={selectedKeys}
        selectedKeys={selectedKeys}
        onSelect={(e) => {
          if (location.pathname !== [...e.keyPath].reverse().join("/"))
            navigate(e.keyPath.reverse().join("/"));
        }}
        mode="inline"
        items={allItems}
        style={
          {
            // background: colorPrimary,
          }
        }
      />
    </Sider>
  );
};

export default CustomSider;
