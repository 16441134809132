import { ConfigProvider } from "antd";
import {
  useAppDispatch,
  useAppSelector,
} from "features/common/hooks/useReduxHooks";
import arEG from "antd/lib/locale/ar_EG";
import enUS from "antd/lib/locale/en_US";
// import { defaultTheme } from "styles/theme/defaultTheme";
import { ReactNode } from "react";

/**
 * @namespace AntDesignConfigProvider
 */

/**
 * @description init dirctions ,locale , theme ,and empty render component
 *   @param {any} children - wrapped components
 */
interface AntDesignConfigProviderProps {
  children: ReactNode;
}
const AntDesignConfigProvider = ({
  children,
}: AntDesignConfigProviderProps) => {
  const { direction, language, primaryColor } = useAppSelector(
    (state) => state.ui
  );
  const dispatch = useAppDispatch();

  return (
    <ConfigProvider
      direction={direction}
      locale={language === "en" ? enUS : arEG}
      theme={{
        token: {
          colorPrimary: primaryColor ? primaryColor : "#6c9bcf",
          // colorError: "#B46060",
          colorBgBase: "white",
          colorBgLayout: "white",
          // colorBgContainer: primaryColor ?? "#6c9bcf",
        },
        components: {
          Menu: {
            colorItemTextSelected: primaryColor ?? "#6c9bcf",
            colorItemBgSelected: "white",
            colorItemText: "white",
            colorIcon: "white",
            colorBgContainer: primaryColor ?? "#6c9bcf",
            colorText: "white",
          },
        },
      }}
      // renderEmpty={() => <></>}
    >
      {children}
    </ConfigProvider>
  );
};
export default AntDesignConfigProvider;
