import { Button, message, Upload } from "antd";
import { UploadFile, UploadProps } from "antd/lib/upload/interface";
import {  useEffect, useState } from "react";
import styles from "./styles.module.scss";
// import { ReactComponent as UploadIcon } from "assets/svg/generalSvgs/upload_icon.svg";
import { baseURL } from "config/baseURL";
import { useAppSelector } from "features/common/hooks/useReduxHooks";
import { useTranslation } from "react-i18next";
import { UploadFileStatus } from "antd/es/upload/interface";
import { FilesUploderProps } from "features/common/interfaces/FileUploderProps.d";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCsv, faUpload } from "@fortawesome/free-solid-svg-icons";
/**
 * @description a component that used to upload files (pdf and can change it) to server session
 * using the ant design upload component and
 * session and setSession state to keep the session id that we uploaded on
 * @param {FilesUploderProps} params -
 */
const FilesUploder = ({
  maxCount,
  seesionId,
  setSessionId,
  filesUploaded,
  multiple = false,
  editable = false,
  setDeletedFiles,
  deletedFiles,
  url,
  setIsUploading,
  label,
  disabeld = false,
  accept = ".pdf",
  onSuccess,
  onDelete,
  sessionKey,
}: FilesUploderProps) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const { primaryColor } = useAppSelector((state) => state.ui);
  const { authToken } = useAppSelector((state) => state.userAuth);

  const authHeader = {
    Authorization: `Bearer ${authToken}`,
  };

  useEffect(() => {
    if (filesUploaded) {
      let arr: UploadFile[] = [];
      filesUploaded.forEach((file: string, index) => {
        arr.push({
          uid: index.toString(),
          name: file.split("/")[file.split("/").length - 1],
          url: file,
        });
      });
      setFileList(arr);
    }
  }, [filesUploaded]);

  const beforeUpload = (file: File) => {
    let isValid = false;
    if (accept === ".pdf") {
      isValid = file.type === "application/pdf";
    }
    if (accept === ".csv") {
      isValid = file.type === "text/csv";
    }
    if (!isValid) {
      message.error(`${file.name} is not a valid file`);
    }
    return true || Upload.LIST_IGNORE;
  };
  const onChange: UploadProps["onChange"] = (info) => {
    const { status } = info.file;
    setIsUploading?.(status);
    setFileList(info.fileList);
    if (status === "done") {
      console.log(info.fileList);
      onSuccess?.(info.file.originFileObj as File);
      message.success(`${info.file.response.message}`);
      setSessionId(info.file.response.other.session_id);
    } else if (status === "error") {
      console.log(`${info.file.name} file upload failed.`);
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const onRemove: UploadProps["onRemove"] = (info) => {
    console.log(info);
    onDelete?.();

    if (deletedFiles && setDeletedFiles) {
      let delArr = [...deletedFiles];
      delArr.push(info.url!);
      setDeletedFiles(delArr);
      console.log(delArr.length);
      if (delArr.length === 0) {
      }
    }
  };

  return (
    <div className={styles.fileUploader}>
      <div className={styles.upload}>
        <div className={styles.uploadContent}>
          <FontAwesomeIcon
            icon={faFileCsv}
            style={{ fontSize: 30, color: primaryColor ?? "", margin: 5 }}
          />
          {fileList.length === 0 ? (
            <div className={styles.uploadText}>{label ?? "Upload file"}</div>
          ) : null}
        </div>
        <Upload
          disabled={disabeld}
          name="file"
          multiple={multiple}
          maxCount={maxCount}
          action={`${baseURL}${url}`}
          data={
            sessionKey
              ? {
                  session_id: seesionId,
                  key: sessionKey,
                }
              : {
                  session_id: seesionId,
                }
          }
          accept={accept}
          headers={authHeader}
          listType={"picture"}
          fileList={fileList}
          showUploadList={
            !editable
              ? {
                  removeIcon: false,
                  showRemoveIcon: false,
                }
              : undefined
          }
          onChange={onChange}
          onRemove={onRemove}
          beforeUpload={beforeUpload}
          className={styles.uploadContanier}
        >
          <Button
            disabled={disabeld}
            className={styles.uploadButton}
            type="primary"
            icon={
              <FontAwesomeIcon
                icon={faUpload}
                style={{ fontSize: 20, margin: 20 }}
              />
            }
          />
        </Upload>
      </div>
    </div>
  );
};

export default FilesUploder;
