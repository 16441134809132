import { Descriptions, Modal, message } from "antd";
import Link from "antd/es/typography/Link";
interface UserInfoModalProps {
  userRecord: any;
  setUserRecord: any;
}
const UserInfoModal = ({ userRecord, setUserRecord }: UserInfoModalProps) => {
  return (
    <Modal
      footer={null}
      open={userRecord !== null}
      centered
      width={900}
      style={{ maxWidth: "95%" }}
      onCancel={() => setUserRecord(null)}
    >
      <Descriptions title="User Info" layout="vertical" bordered column={4}>
        <Descriptions.Item label="Name">
          {userRecord?.name ?? "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Phone Number">
          {userRecord?.phone_number ?? "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Birth Date">
          {userRecord?.birth_date ?? "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Facebook account">
          {userRecord?.facebook_link ? (
            <div>
              <Link href={userRecord.facebook_link}>Open</Link> OR{" "}
              <Link
                onClick={() => {
                  navigator.clipboard
                    .writeText(userRecord.facebook_link)
                    .then(() => {
                      message.info("link copied to clipboard");
                    });
                }}
              >
                Copy
              </Link>
            </div>
          ) : (
            "-"
          )}
        </Descriptions.Item>
      </Descriptions>
    </Modal>
  );
};
export default UserInfoModal;
