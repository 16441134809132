import { useAppSelector } from "features/common/hooks/useReduxHooks";
import { Navigate, Outlet, useLocation } from "react-router-dom";

/**
 * @description a middleware for check if the user have app token
 */

export const AppAuthenticatedRoutes = () => {
  const { currAppToken } = useAppSelector((state) => state.appsAuth);
  const location = useLocation();
  return currAppToken ? (
    <Outlet />
  ) : (
    <Navigate to="auth/apps" state={{ from: location }} replace />
  );
};
