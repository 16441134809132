import { useAppDispatch } from "features/common/hooks/useReduxHooks";
import { UiSliceActions } from "features/common/redux/slices/uiSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { IAppsAuthSliceInitState } from "../interfaces/I.AppsAuthSliceIinitState";
import { IUserAuthSliceInitState } from "../interfaces/I.UserAuthSliceIinitState";
import { AppsAuthSliceActions } from "../redux/slice/appsAuthSlice";
import { UserAuthSliceActions } from "../redux/slice/userAuthSlice";
type mutateType = {
  user: IUserAuthSliceInitState;
  app: (IAppsAuthSliceInitState & { color: string }) | null;
};
export const useGetAccessWithoutLogin = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const state = location.state as any;
  const from = state?.from?.pathname || "/";
  const mutate = (data: mutateType | null) => {
    if (data) {
      dispatch(UserAuthSliceActions.Login(data.user));
      if (data.app) {
        const { color, ...app } = data.app;
        dispatch(AppsAuthSliceActions.SetCurrentAppAction(app));
        dispatch(UiSliceActions.SetPrimaryColor(color));
      }
    }
    navigate(from);
  };
  return mutate;
};
