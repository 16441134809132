import { useErrorHandler } from "features/common/hooks/useErrorHandler";
import {
  useAppDispatch,
  useAppSelector,
} from "features/common/hooks/useReduxHooks";
import { request } from "libs/axios";
import { useCookies } from "react-cookie";
import { useMutation } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { UserAuthSliceActions } from "../redux/slice/userAuthSlice";
import { UserModel } from "features/common/interfaces/Models/User.d";
interface loginMutationFunctionProps {
  id: string;
}
const loginMutationFunction = async ({ id }: loginMutationFunctionProps) => {
  return request<UserModel>({
    url: `/auth/login/apps/${id}`,
    method: "GET",
  });
};

export const useSwitchCurrentApp = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [_, setCookie] = useCookies();
  const location = useLocation();
  const state = location.state as any;
  const from = state?.from?.pathname || "/";
  const { FCMtoken } = useAppSelector((state) => state.ui);
  const { t } = useTranslation();

  return useMutation("auth/switchApp", loginMutationFunction, {
    onSuccess: (response) => {},
    onError: (err) => {
      console.log(err);
    },
  });
};
